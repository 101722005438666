import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

//UTILIDADES
import { Alerta } from '../../utilidades/alerta.component';
import { MycurrencyPipe } from '../../../pipe/mycurrency.pipe';
import { Loading } from '../../utilidades/loading.component';
import { Modal } from '../../utilidades/modal.component';
import { Paginador } from '../../utilidades/paginador.component';

//MODELS
import { DevolucionModel } from '../../../models/devolucion.model';

//SERVICES
import { VentasServices } from '../../../services/ventas.services';
import { VentasDetalleServices } from '../../../services/ventasdetalle.services';
import { DevolucionesServices } from '../../../services/devoluciones.services';

@Component({
	selector: 'app-ventadetalle',
	templateUrl: './ventadetalle.component.html',
	styleUrls: ['./ventadetalle.component.scss'],
	providers: [VentasServices, VentasDetalleServices, DevolucionesServices]
})
export class VentadetalleComponent implements OnInit {
	//VARIABLES
	public modalDevolucionesDetalle: any = '';
	public alertDetDevolucion: Alerta;
	public alertaDevolucion: Alerta;
	public paginadorGproductos: any = '';
	public loadingDet: any;
	public idCompra: number = 0;
	public devoluciones: any;
	public modalDevolucion: any = '';
	public id: number = 0;
	public idProducto: number = 0;
	public codigo: string = '';
	public nombre: string = '';
	public cantidad: number = 0;
	public cantidadDevolver: number = 0;
	public observacion: string = '';
	public isChecked: boolean = false;
	public detalleCliente: boolean = false;
	public modalChildVisible = false;

	@Input() _idDevolucion: number;
	@Input('_idDevolucion') set changeIdCliente(id: number) {
		this.loadingDet.setLoading(true);
		this.idCompra = id;
		if (id) {
			this.onEdit();
			this.loadDetalleCompra(id);
		}
	}
  	@Output() updateData = new EventEmitter();
	constructor(
		private _ventasService: VentasServices,
		private _ventasDetalleService: VentasDetalleServices,
		private _devolucionesService: DevolucionesServices,
		private formatcurrencypipe: MycurrencyPipe
	) {
		this.alertDetDevolucion = new Alerta('alert_dev');
		this.alertaDevolucion = new Alerta('alert_devpro');
		this.modalDevolucionesDetalle = new Modal();
		this.modalDevolucion = new Modal(false, 'modal_devoluciones');
		this.loadingDet = new Loading();
	}

	private alertOut($ele, timer = 2000) {
		setTimeout(function () {
			$ele.setActive(false);
		}, timer);
	}

	ngOnInit() {
		this.devoluciones = new DevolucionModel(0, '', '', '', '', 0, 0, 0, 0, 0);
	}

	openModalEditCompraDetalle() {
		this.modalDevolucionesDetalle.openModal();
	}

	onEdit() {
		try {
			this.detalleCliente = false;
			this._ventasService.getClienteCompraId(this.idCompra, 1, 10).subscribe(
				response => {
					let data = response.data[0];
					this.devoluciones = new DevolucionModel(
						data.iddevolucion,
						data.codigo,
						data.fecha,
						data.nombres,
						data.apellidos,
						data.abono,
						parseInt(data.cantidad),
						data.subtotal,
						data.total,
						data.descuento
					);
					this.loadingDet.setLoading(false);
					this.detalleCliente = true;
				},
				err => {
					this.alertDetDevolucion.setActive(true, err.error.code);
					this.alertOut(this.alertDetDevolucion);
				}
			);
		} catch (err) {
			this.alertDetDevolucion.setActive(true, 'danger');
			this.alertOut(this.alertDetDevolucion);
		}
	}

	loadDetalleCompra(id) {
		try {
			let param = 'idventa=' + id;
			this.paginadorGproductos = new Paginador(this._ventasDetalleService, this.alertDetDevolucion, '', '', param);
		} catch (err) {
			this.alertDetDevolucion.setActive(true, 'danger');
			this.alertOut(this.alertDetDevolucion);
		}
	}

	openFormDevolucion(id, idProducto, codigo, nombre, cantidad) {
		this.id = id;
		this.idProducto = idProducto;
		this.codigo = codigo;
		this.nombre = nombre;

		this.modalDevolucion.statusModal = true;


		this.cantidad = cantidad;
		this.cantidadDevolver = cantidad;
	}

	createRange(number) {
		var items: number[] = [];
		for (var i = 1; i <= number; i++) {
			items.push(i);
		}
		return items;
	}

	aceptarDevolucion() {
		
		try {
			let param = {
				'cantidadDevolver': this.cantidadDevolver,
				'cantidadTotal': this.cantidad,
				'idproducto': this.idProducto,
				'observacion': this.observacion,
				'isChecked': this.isChecked
			} 
			this._devolucionesService.update(this.id, param).subscribe(
				response => {
					//this.closeModalDevolucion('modal_close');
					this.modalDevolucion.statusModal = false;
					this.alertDetDevolucion.setActive(true, 200);
					this.alertOut(this.alertDetDevolucion);
					this.clearForm();
					this.loadDetalleCompra(this.idCompra);
					this.updateData.emit({ 'reload': true });
					this.onEdit();
					this.loadDetalleCompra(this.idCompra);
				},
				err => {
					this.alertaDevolucion.setActive(true, err.error.code);
					this.alertOut(this.alertaDevolucion);
				}
			);
		} catch (err) {
			this.alertaDevolucion.setActive(true, 'danger');
			this.alertOut(this.alertaDevolucion);
		}
	}

	clearForm() {
		this.cantidad = 0;
		this.cantidadDevolver = 0;
		this.idProducto = 0;
		this.observacion = '';
		this.isChecked = false;
	}
}
