import { Component } from '@angular/core';
import { SlideInOutAnimation } from './animations';


@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
  `,
  styles: [],
  /*selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],*/

  animations: [SlideInOutAnimation],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class AppComponent {
  title = 'app';
  animationState = 'out';
  public winHeight: number;
  public swMnu: boolean = true;


  toggleShowDiv(divName: string) {
    if (divName === 'divA') {
      this.animationState = this.animationState === 'out' ? 'in' : 'out';
    }
  }

  onResize(event) {
    this.winHeight = event.target.innerHeight;
    if(event.target.innerWidth > 767 && this.animationState == 'out') {
        this.animationState = 'in';
    } else if(window.innerWidth <= 767 && this.animationState == 'in') {
        this.animationState = 'out';
    }    
  }
  ngOnInit() {
    this.winHeight = window.innerHeight;
    if(window.innerWidth > 767 && this.animationState == 'out') {
        this.animationState = 'in';
    } else if(window.innerWidth <= 767 && this.animationState == 'in') {
        this.animationState = 'out';
    }
  }

  cerrarMenus() {
    if(window.innerWidth <= 767) {
        this.animationState  = 'out'; 
    }    
  }  

  
}
