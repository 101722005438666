export class ProveedorModel {
    constructor(
        public idProveedor: number,
        public nit: string,
        public razon_social: string,
        public direccion: string,
        public email: string,
        public telefono: string,
        public celular: string,
        public sitio_web: string,
        public observacion: string,
        public coordenadas: string
    ) { }
}