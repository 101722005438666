import { Component, OnInit, ViewChild } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'; 

//UTILIDADES
import { Alerta } from '../utilidades/alerta.component';
import { Paginador } from '../utilidades/paginador.component';
import { Generales } from '../utilidades/generales.component';
import { MycurrencyPipe } from '../../pipe/mycurrency.pipe';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../app-material/format-datepicker';

//SERVICES
import { EgresosServices } from '../../services/egresos.services';

//COMPONENT
import { EgresosdetalleComponent } from './egresosdetalle/egresosdetalle.component';


@Component({
	selector: 'app-egresos',
	templateUrl: './egresos.component.html',
	styleUrls: ['./egresos.component.scss'],
	providers: [
		EgresosServices, MycurrencyPipe,
		{ provide: DateAdapter, useClass: AppDateAdapter },
		{ provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
		{ provide: MAT_DATE_LOCALE, useValue: 'es-ES' }
	]
})
export class EgresosComponent implements OnInit {
	public alerta: Alerta;
	public paginadorGrillaEgresos: any = '';
	public id: number = -1;
	public ordenarEgresos: string = 'mayor_saldo';
	public buscarEgresos: string = '';
	public toDate: string = '';
	public fromDate: string = '';
	public filtro: boolean = false;
	public utilidades: Generales;
	public filteToDate;
	public wait_export: boolean = false;
	public timeout = null;

	@ViewChild(EgresosdetalleComponent) egresoDetalle: EgresosdetalleComponent; //Componente hijo
	@ViewChild('fechaFin') fechaFin: any;

	constructor(
		private _egresosService: EgresosServices,
		private formatcurrencypipe: MycurrencyPipe
	) { 
		this.alerta = new Alerta('alertList');
		this.utilidades = new Generales();
	}

	private alertOut($ele, timer = 2000) {
		setTimeout(function () {
			$ele.setActive(false);
		}, timer);
	}

	private loadEgresos() {
		try {
			this.paginadorGrillaEgresos = new Paginador(this._egresosService, this.alerta);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	private zeroPadded(val) {
		if (val >= 10)
			return val;
		else
			return '0' + val.toString();
	}

	ngOnInit() {
		this.loadEgresos();
	}

	public onFiltro() {
		if(this.filtro) {
			this.filtro = false;
		} else {
			this.filtro = true;
		}		
	}

	addEgresoDetalle() {
		this.egresoDetalle.openModalDetalleEgresos(0);
	}

	editEgresoDetalle(id) {
		this.egresoDetalle.openModalDetalleEgresos(id);
	}

	onDelete(id) {
		try {
			this._egresosService.delete(id).subscribe(
				response => {
					let data = response.data;
					this.alerta.setActive(true, data.code);
					this.alertOut(this.alerta);
					let pagActiva = this.paginadorGrillaEgresos.activePage;
					if (this.paginadorGrillaEgresos.data.length == 1 && pagActiva > 1) this.paginadorGrillaEgresos.activePage--;
					this.paginadorGrillaEgresos.loadData(this.paginadorGrillaEgresos.activePage);
				},
				err => {
					this.alerta.setActive(true, err.error.code);
					this.alertOut(this.alerta);
				}
			);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	onSearch(event) {
		try {
			let $this = this;
			clearTimeout($this.timeout);
			
			$this.timeout = setTimeout(function () {
				$this.buscarEgresos = event.target.value;
				$this.paginadorGrillaEgresos.activePage = 1;
				let param = `fromDate=${$this.fromDate}&toDate=${$this.toDate}`;
				$this.paginadorGrillaEgresos.loadData($this.paginadorGrillaEgresos.activePage, $this.buscarEgresos, $this.ordenarEgresos, param);
			}, 500);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	onOrder() {
		try {
			let param = `fromDate=${this.fromDate}&toDate=${this.toDate}`;
			this.paginadorGrillaEgresos.loadData(this.paginadorGrillaEgresos.activePage, this.buscarEgresos, this.ordenarEgresos, param);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	onClearFilter() {
		try {
			this.fromDate = '';
			this.toDate = '';
			this.fechaFin.disabled = true;
			this.buscarEgresos = '';
			this.ordenarEgresos = 'mayor_saldo';
			this.paginadorGrillaEgresos.activePage = 1;
			this.paginadorGrillaEgresos.loadData(this.paginadorGrillaEgresos.activePage, this.buscarEgresos, this.ordenarEgresos);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	upDateDataGrid(event): void {
		this.loadEgresos();
	}

	onExport(type) {
		try {
			this.wait_export = true;
			this._egresosService.export(type, this.buscarEgresos, this.ordenarEgresos).subscribe(
				response => {
					var a = document.createElement("a");
					a.href = response.file;
					a.download = response.name;
					document.body.appendChild(a);
					a.click();
					a.remove();
					this.wait_export = false;
				},
				err => {
					this.wait_export = false;
					this.alerta.setActive(true, err.error.code);
					this.alertOut(this.alerta);
				}
			);
		} catch (err) {
			this.wait_export = false;
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	
	getDateInicio(fecha) {
		try {
			let date = new Date(fecha);
			this.fromDate = date.getFullYear() + "-" + this.zeroPadded(date.getMonth() + 1) + "-" + this.zeroPadded(date.getDate()) + "T" + this.zeroPadded(date.getHours()) + ":" + this.zeroPadded(date.getMinutes()) + ":" + this.zeroPadded(date.getSeconds());
			let param = `fromDate=${this.fromDate}&toDate=${this.toDate}`;
			this.paginadorGrillaEgresos.loadData(1, this.buscarEgresos, this.ordenarEgresos, param);
			if (this.fromDate != '') {
				this.fechaFin.disabled = false;
				this.filteToDate = (d: Date): boolean => {										
					const daySeletec = date;
					return daySeletec.getTime() <= d.getTime();					
				}
			}

		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	getDateFin(fecha) {
		try {
			let date = new Date(fecha);
			this.toDate = date.getFullYear() + "-" + this.zeroPadded(date.getMonth() + 1) + "-" + this.zeroPadded(date.getDate()) + "T" + this.zeroPadded(date.getHours()) + ":" + this.zeroPadded(date.getMinutes()) + ":" + this.zeroPadded(date.getSeconds());
			let param = `fromDate=${this.fromDate}&toDate=${this.toDate}`;
			this.paginadorGrillaEgresos.loadData(1, this.buscarEgresos, this.ordenarEgresos, param);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

}
