import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

//UTILIDADES
import { Alerta } from '../../utilidades/alerta.component';
import { MycurrencyPipe } from '../../../pipe/mycurrency.pipe';
import { Loading } from '../../utilidades/loading.component';
import { Modal } from '../../utilidades/modal.component';

//MODELS
import { PersonaModel } from '../../../models/persona.model';
import { ClienteModel } from '../../../models/cliente.model';

//SERVICES
import { ClientesServices } from '../../../services/clientes.services';

@Component({
	selector: 'app-clientedetalle',
	templateUrl: './clientedetalle.component.html',
	styleUrls: ['./clientedetalle.component.scss'],
	providers: [
		ClientesServices
	]
})
export class ClientedetalleComponent implements OnInit {
	
	@Output() updateData = new EventEmitter();
	@Input() _idCliente: number;
	@Input('_idCliente') set changeIdCliente(id: number) {
		this.loadingDet.setLoading(true);
		this.idCliente = id;
		if(id) {
			this.resetModels();
			this.onEdit();			
		} else {		
			this.resetModels();
			this.loadingDet.setLoading(false);
			let inputCodigo = this.formCliente.get('codigo');
			inputCodigo.enable();			
		}
	}

	//FORM
	public formCliente: FormGroup;
	
	//MODELS
	public persona: PersonaModel;

	//VARIABLES
	public alertDetClients: Alerta;
	public cliente: any = '';
	public idCliente: number = 0;
	public loadingDet: any;
	public modalCreditoDetalle: any = ''; //Modal Detalle;
	public modalClienteDetalle: any = '';
	public modalChildVisible = false; 
	public timeout = null;
	public disableBtnSave: boolean = false;
	public numeroDocumento = '';
	public email = '';
	
  	constructor(
		private _clienteService: ClientesServices,
		private fb: FormBuilder,
		private formatcurrencypipe: MycurrencyPipe
	  ) {	
		
		this.formCliente = this.fb.group({
			codigo: [''],
			iddocumento: ['', Validators.required],
			numerodocumento: ['', Validators.required],
			nombres: ['', Validators.required],
			apellidos: ['', Validators.required],
			telefono: [''],
			celular: [''],
			email:[''],
			direccion: ['']
		});
		
		this.alertDetClients = new Alerta('alert_det_client');	
		this.loadingDet = new Loading();
		this.resetModels();
		this.modalCreditoDetalle = new Modal();
		this.modalClienteDetalle =  new Modal();
	}

	ngOnInit() {}

	private alertOut($ele, timer = 2000) {
		setTimeout(function () {
			$ele.setActive(false);
		}, timer);
	}

	private formartPrecio(precio) {
		return this.formatcurrencypipe.transform(this.formartNum(precio));
	}

	private formartNum(num) {
		var result = num.toString().replace(/,/g, "");
		result = result.replace("$", "");
		return result;
	}

	private resetModels() {
		this.persona = new PersonaModel(null, '', '', '', '', null, '', '', '', true, '');
		this.cliente = new ClienteModel(null, this.formartPrecio(0), true);
	}

	onAdd() {
		this.idCliente = 0;
		this.resetModels();
		let inputCodigo = this.formCliente.get('codigo');
		inputCodigo.enable();
	}

	onEdit() {
		try {
			this._clienteService.getCliente(this.idCliente).subscribe(
				response => {
					var data = response.data[0];
					let saldoMora = data.totalCredito - data.pagos;					
					saldoMora = (saldoMora == null || !saldoMora) ? 0 : saldoMora;
					this.numeroDocumento = data.numerodocumento;
					this.email = data.email;
					this.persona = new PersonaModel(data.idpersona, data.codigo, data.nombres, data.apellidos, data.numerodocumento, data.iddocumento, data.telefono, data.email, data.celular, data.estado, data.direccion);
					this.cliente = new ClienteModel(data.idcliente, this.formartPrecio(saldoMora), data.estadocliente);
					this.loadingDet.setLoading(false);
					let inputCodigo = this.formCliente.get('codigo');
					inputCodigo.disable();
				},
				err => {
					this.alertDetClients.setActive(true, err.error.code);
					this.alertOut(this.alertDetClients);
				}
			);
		} catch(err) {
			this.alertDetClients.setActive(true, 'danger');
			this.alertOut(this.alertDetClients);
		}
	}

	onSave() {
		try {
			if (!this.idCliente) { //NUEVO
				this._clienteService.add(this.formCliente.value).subscribe(
					response => {
						this.alertDetClients.setActive(true, 'success');
						this.alertOut(this.alertDetClients);
						this.idCliente = response.data.idcliente;
						let inputCodigo = this.formCliente.get('codigo');
						inputCodigo.disable();
						this.updateData.emit({ 'reload': true, 'idCliente': this.idCliente });
					},
					err => {
						this.alertDetClients.setActive(true, err.error.code);
						this.alertOut(this.alertDetClients);
					}
				);
			} else {
				this._clienteService.update(this.persona.idpersona, this.persona, this.cliente).subscribe(
					response => {
						this.alertDetClients.setActive(true, 'success');
						this.alertOut(this.alertDetClients);
						var cel_1 = document.getElementsByClassName("cliente_" + this.cliente.idcliente).item(0).querySelector('.cel_1');
						var cel_2 = document.getElementsByClassName("cliente_" + this.cliente.idcliente).item(0).querySelector('.cel_2');
						var cel_3 = document.getElementsByClassName("cliente_" + this.cliente.idcliente).item(0).querySelector('.cel_3');
						var cel_4 = document.getElementsByClassName("cliente_" + this.cliente.idcliente).item(0).querySelector('.cel_4');
						
						cel_2.innerHTML = response.numerodocumento;
						cel_3.innerHTML = response.nombres + ' ' + response.apellidos;
						cel_4.innerHTML = response.saldomora;
						
					},
					err => {
						this.alertDetClients.setActive(true, err.error.code);
						this.alertOut(this.alertDetClients);
					}
				);
			}
		} catch (err) {
			this.alertDetClients.setActive(true, 'danger');
			this.alertOut(this.alertDetClients);
		}		
	}

	openModalAddClienteDetalle() {
		this.modalClienteDetalle.openModal();
	}

	openModalEditClienteDetalle() {
		this.modalClienteDetalle.openModal();
	}

	showModal(event): void {
		this.modalChildVisible = event;		
	}

	reloadData(event): void {
		if (event.reload) {
			this.cliente.saldomora = this.formartPrecio(event.totalCredito);
			let items = document.getElementsByClassName("cliente_" + this.cliente.idcliente).item(0);
			if(items !== null) {
				var cel_4 = items.querySelector('.cel_4');
				cel_4.innerHTML = this.cliente.saldomora;
			}
		}
	}

	isValidNumberDoc(event) {
		let $this = this;
		clearTimeout($this.timeout);
		$this.timeout = setTimeout(function () {
			let value = event.target.value;
			$this.validData(this.numeroDocumento, value, 'number_doc', 'El número de documento ya se encuentra registrado');
		}, 1000);
	}

	isValidEmail(event) {
		let $this = this;
		clearTimeout($this.timeout);
		$this.timeout = setTimeout(function () {
			let value = event.target.value;
			$this.validData(this.email, value, 'email', 'El Email ya se encuentra registrado');
		}, 1000);
	}

	validData(valueActual, nuevoValue, campo, msgError) {
		try {
			this.disableBtnSave = true;
			if (valueActual != nuevoValue && nuevoValue != '') {
				this._clienteService.search(nuevoValue, 'asc', campo).subscribe(response => {
					if (response.totalItems > 0) {
						this.alertDetClients.setActive(true, 'danger', msgError);
						this.alertOut(this.alertDetClients);
						this.disableBtnSave = true;
					} else {
						this.disableBtnSave = false;
					}
				},
					err => {
						this.alertDetClients.setActive(true, err.error.code);
						this.alertOut(this.alertDetClients);
					})
			} else {
				this.disableBtnSave = false;
			}
		} catch (err) {
			this.alertDetClients.setActive(true, 'danger');
			this.alertOut(this.alertDetClients);
		}
	}
}
