import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

//UTILIDADES
import { Alerta } from '../../utilidades/alerta.component';
import { Loading } from '../../utilidades/loading.component';
import { Modal } from '../../utilidades/modal.component';
import { validationInput } from '../../utilidades/validation.models';
import { MycurrencyPipe } from '../../../pipe/mycurrency.pipe';
import { Generales } from '../../utilidades/generales.component';

//MODELS
import { EgresoModel } from '../../../models/egreso.models';

//SERVICES
import { EgresosServices } from '../../../services/egresos.services';


@Component({
	selector: 'app-egresosdetalle',
	templateUrl: './egresosdetalle.component.html',
	styleUrls: ['./egresosdetalle.component.scss'],
	providers: [
		EgresosServices, MycurrencyPipe
	]
})
export class EgresosdetalleComponent implements OnInit {
	//MODELS
	public egreso: EgresoModel;

	//VARIABLES
	public alertDetEgreso: Alerta;
	public loadingDet: any;
	public modalEgresoDetalle: any = '';
	public idEgreso: number = -1; // -1 Significa que no es un usuario nuevo ni en modo edicion
	public modalChildVisible = false;
	public validationInputs: any = '';
	public utilidades: Generales;
	public wait: boolean = false;

	//FORM
	public formEgreso: FormGroup;

	@Output() upDateGridEgresos = new EventEmitter();

	constructor(
		private _egresoService: EgresosServices,
		private formatcurrencypipe: MycurrencyPipe
	) { 
		this.alertDetEgreso = new Alerta('alert_det_egresos');
		this.loadingDet = new Loading();
		this.modalEgresoDetalle = new Modal();
		this.validationInputs = new validationInput();
	
		this.formEgreso = new FormGroup({
			descripcion: new FormControl({ value: ''}, Validators.required),
			observacion: new FormControl({ value: '' }),
			fecha: new FormControl({ value: '', disabled: true }, Validators.required),
			prestamista: new FormControl({ value:''}, Validators.required),
			estado: new FormControl({ value: '' }, Validators.required),
			costo: new FormControl({ value: '' }, Validators.compose([
				Validators.required,
				Validators.pattern(this.validationInputs.soloPrecio.pattern)
			]))			
		});

		this.utilidades = new Generales();
	}

	private formartNum(num) {
		var result = num.toString().replace(/,/g, "");
		result = result.replace("$", "");
		return result;
	}

	private formartPrecio(precio) {
		return this.formatcurrencypipe.transform(this.formartNum(precio));
	}

	private alertOut($ele, timer = 2000) {
		setTimeout(function () {
			$ele.setActive(false);
		}, timer);
	}

	ngOnInit() {
	}

	openModalDetalleEgresos(id) {
		this.idEgreso = id;
		this.modalEgresoDetalle.openModal();
		this.modalChildVisible = false;
		this.resetModels();
		if (id > 0) { //EDITAR EGRESOS
			this.loadingDet.setLoading(true);
			this.onEdit();
		}
	}

	resetModels() {
		this.egreso = new EgresoModel(null, new Date(), '', this.formartPrecio(0), '', '', '');
	}

	onAdd() {		
		this.resetModels();
		this.idEgreso = 0;
	}

	onEdit() {
		try {
			this._egresoService.getEgresos(this.idEgreso).subscribe(
				response => {
					var data = response.data[0];
					let newfecha = new Date(data.fecha);
					let fecha = (newfecha.getMonth() + 1) + '/' + newfecha.getDate() + '/' + newfecha.getFullYear();
					this.egreso = new EgresoModel(data.id_egreso, new Date(fecha), data.descripcion, this.formartPrecio(data.costo), data.prestamista, data.estado, data.observacion);
					this.loadingDet.setLoading(false);
				},
				err => {
					this.alertDetEgreso.setActive(true, err.error.code);
					this.alertOut(this.alertDetEgreso);
				}
			);
		} catch (err) {
			this.alertDetEgreso.setActive(true, 'danger');
			this.alertOut(this.alertDetEgreso);
		}
	}

	onSave() {
		try {
			this.wait = true;
			if (!this.idEgreso) { //NUEVO
				this._egresoService.add(this.egreso).subscribe(
					response => {
						this.alertDetEgreso.setActive(true, 'success');
						this.alertOut(this.alertDetEgreso);
						this.idEgreso = response.data.id_egreso;
						this.upDateGridEgresos.emit({ 'id_egreso': this.idEgreso });
						this.wait = false;
					},
					err => {
						this.wait = false;
						this.alertDetEgreso.setActive(true, err.error.code);
						this.alertOut(this.alertDetEgreso);
					}
				);
			} else {
				this._egresoService.update(this.idEgreso, this.egreso).subscribe(
					response => {
						this.alertDetEgreso.setActive(true, 'update');
						this.alertOut(this.alertDetEgreso);
						var cel_1 = document.getElementsByClassName("egreso_" + this.egreso.idEgreso).item(0).querySelector('.cel_1');
						var cel_2 = document.getElementsByClassName("egreso_" + this.egreso.idEgreso).item(0).querySelector('.cel_2');
						var cel_3 = document.getElementsByClassName("egreso_" + this.egreso.idEgreso).item(0).querySelector('.cel_3');
						var cel_4 = document.getElementsByClassName("egreso_" + this.egreso.idEgreso).item(0).querySelector('.cel_4');
						var cel_5 = document.getElementsByClassName("egreso_" + this.egreso.idEgreso).item(0).querySelector('.cel_5');

						if (cel_1) cel_1.innerHTML = this.utilidades.limitarStrings(response.data.descripcion);
						if (cel_2) cel_2.innerHTML = this.formatcurrencypipe.transform(response.data.costo);
						if (cel_3) cel_3.innerHTML = response.data.estado;
						if (cel_4) cel_4.innerHTML = response.data.prestamista;
						if (cel_5) cel_5.innerHTML = this.utilidades.formartFecha(response.data.fecha);

						this.wait = false;
					},
					err => {
						this.wait = false;
						this.alertDetEgreso.setActive(true, err.error.code);
						this.alertOut(this.alertDetEgreso);
					}
				);
			}
		} catch (err) {
			this.wait = false;
			this.alertDetEgreso.setActive(true, 'danger');
			this.alertOut(this.alertDetEgreso);
		}
	}

	openEditEgresoDetalle() {
		this.modalEgresoDetalle.openModal();
	}

	showModal(event): void {
		this.modalChildVisible = event.modal;
	}

}
