import { Component, OnInit, ViewChild} from '@angular/core';

//UTILIDADES
import { Alerta } from '../utilidades/alerta.component';
import { Paginador } from '../utilidades/paginador.component';
import { MycurrencyPipe } from '../../pipe/mycurrency.pipe';

//SERVICES
import { DevolucionesServices } from '../../services/devoluciones.services';

//COMPONENT
import { DevolucionesdetalleComponent } from './devolucionesdetalle/devolucionesdetalle.component';

@Component({
	selector: 'app-devoluciones',
	templateUrl: './devoluciones.component.html',
	styleUrls: ['./devoluciones.component.scss'],
	providers:[
		DevolucionesServices, MycurrencyPipe
	]
})
export class DevolucionesComponent implements OnInit {

	public alerta: Alerta;
	public paginadorGrillaDev: any = '';
	public buscarCompras: string = '';
	public ordenarCompras: string = 'mas_reciente';
	public id: number = 0;
	public toDate: string = '';
	public fromDate: string = '';
	
	@ViewChild(DevolucionesdetalleComponent) devolucionesDetalle: DevolucionesdetalleComponent; //Componente hijo

  	constructor(
			private _devolucionService: DevolucionesServices,
			private formatcurrencypipe: MycurrencyPipe
	  ) {
		this.alerta = new Alerta('alertList');	
	}

	private alertOut($ele, timer = 2000) {
		setTimeout(function () {
			$ele.setActive(false);
		}, timer);
	}

	ngOnInit() {
		this.loadDevoluciones();
	}

	loadDevoluciones() {
		try {
			this.paginadorGrillaDev = new Paginador(this._devolucionService, this.alerta);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	onSearch(event) {
		try {
			this.buscarCompras = event.target.value;
			this.paginadorGrillaDev.activePage = 1;
			let param = `fromDate=${this.fromDate}&toDate=${this.toDate}`;
			this.paginadorGrillaDev.loadData(this.paginadorGrillaDev.activePage, this.buscarCompras, this.ordenarCompras, param);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	onOrder() {
		try {
			let param = `fromDate=${this.fromDate}&toDate=${this.toDate}`;
			this.paginadorGrillaDev.loadData(this.paginadorGrillaDev.activePage, this.buscarCompras, this.ordenarCompras, param);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	onClearFilter() {
		try {
			this.fromDate = '';
			this.toDate = '';
			this.buscarCompras = '';
			this.ordenarCompras = 'mas_reciente';
			this.paginadorGrillaDev.activePage = 1;
			this.paginadorGrillaDev.loadData(this.paginadorGrillaDev.activePage, this.buscarCompras, this.ordenarCompras);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	openFormEditClienteDetalle(data) {
		this.id = data.id;
		this.devolucionesDetalle.openModalDevolucionesDetalle(data);
	}

	private zeroPadded(val) {
		if (val >= 10)
			return val;
		else
			return '0' + val.toString();
	}

	getDateInicio(fecha) {				
		try {			
			let date = new Date(fecha);
			this.fromDate = date.getFullYear() + "-" + this.zeroPadded(date.getMonth() + 1) + "-" + this.zeroPadded(date.getDate()) + "T" + this.zeroPadded(date.getHours()) + ":" + this.zeroPadded(date.getMinutes()) + ":" + this.zeroPadded(date.getSeconds());
			let param = `fromDate=${this.fromDate}&toDate=${this.toDate}`;
			this.paginadorGrillaDev.loadData(1, this.buscarCompras, this.ordenarCompras, param);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	getDateFin(fecha) {
		try {
			let date = new Date(fecha);
			this.toDate = date.getFullYear() + "-" + this.zeroPadded(date.getMonth() + 1) + "-" + this.zeroPadded(date.getDate()) + "T" + this.zeroPadded(date.getHours()) + ":" + this.zeroPadded(date.getMinutes()) + ":" + this.zeroPadded(date.getSeconds());
			let param = `fromDate=${this.fromDate}&toDate=${this.toDate}`;
			this.paginadorGrillaDev.loadData(1, this.buscarCompras, this.ordenarCompras, param);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	reloadData(event): void {
		this.loadDevoluciones();
	}
}
