import { Component, OnInit, ViewChild } from '@angular/core';

//UTILIDADES
import { Alerta } from '../utilidades/alerta.component';
import { Paginador } from '../utilidades/paginador.component';
import { Generales } from '../utilidades/generales.component';

import { DepartamentosServices } from '../../services/departamentos.services';

//COMPONENT
import { DepartamentosdetalleComponent } from './departamentosdetalle/departamentosdetalle.component';

@Component({
    selector: 'app-departamentos',
    templateUrl: './departamentos.component.html',
    styleUrls: ['./departamentos.component.scss'],
    providers: [DepartamentosServices]
})

export class DepartamentosComponent implements OnInit {
    public alerta: Alerta;
    public paginadorGrillaDepartamentos: any = '';
    public id: number = -1;
    public ordenarDepartamento: string = 'A-Z';
    public buscarDepartamentos: string = '';
    public filtro: boolean = false;
    public utilidades: Generales;
    public wait_export: boolean = false;
    public timeout = null;
    public activePopup: boolean = false;
    public tituloPopup: string = '';
    public msgPopup: string = '';
    public idEliminar: number = 0;

    @ViewChild(DepartamentosdetalleComponent) departamentoDetalle: DepartamentosdetalleComponent; //Componente hijo

    constructor(
        private _departamentosService: DepartamentosServices
    ) {
        this.alerta = new Alerta('alertList');
        this.utilidades = new Generales();
    }

    private alertOut($ele, timer = 2000) {
        setTimeout(function () {
            $ele.setActive(false);
        }, timer);
    }

    private loadDepartamentos() {
        try {
            this.paginadorGrillaDepartamentos = new Paginador(this._departamentosService, this.alerta);
        } catch (err) {
            this.alerta.setActive(true, 'danger');
            this.alertOut(this.alerta);
        }
    }

    onFiltro() {
        if (this.filtro) {
            this.filtro = false;
        } else {
            this.filtro = true;
        }
    }

    ngOnInit() {
        this.loadDepartamentos();
    }

    addDepartamentoDetalle() {
        this.departamentoDetalle.openModalDetalleDepartamento(0);
    }

    editDepartamentoDetalle(id) {
        this.departamentoDetalle.openModalDetalleDepartamento(id);
    }

    onValidDelete(id, cantidadProd) {
        this.idEliminar = 0;
        if (cantidadProd > 0) {
            this.idEliminar = id;
            this.activePopup = true;
            this.tituloPopup = '!Advertencia!';
            this.msgPopup = 'El departamento que desea eliminar contiene productos asociados, si lo elimina los producto quedarán ';
            this.msgPopup += 'asociados a un departamento anónimo. ¿Realmente desea eliminar este departamento?';
        } else {
            this.onDelete(id);
        }
    }

    onDelete(id) {
        try {
            this._departamentosService.delete(id).subscribe(
                response => {
                    let data = response.data;
                    this.alerta.setActive(true, data.code);
                    this.alertOut(this.alerta);
                    let pagActiva = this.paginadorGrillaDepartamentos.activePage;
                    if (this.paginadorGrillaDepartamentos.data.length == 1 && pagActiva > 1) this.paginadorGrillaDepartamentos.activePage--;
                    this.paginadorGrillaDepartamentos.loadData(this.paginadorGrillaDepartamentos.activePage);
                },
                err => {
                    this.alerta.setActive(true, err.error.code);
                    this.alertOut(this.alerta);
                }
            );
        } catch (err) {
            this.alerta.setActive(true, 'danger');
            this.alertOut(this.alerta);
        }
    }

    onSearch(event) {
        try {
            let $this = this;
            clearTimeout($this.timeout);

            $this.timeout = setTimeout(function () {
                $this.buscarDepartamentos = event.target.value;
                $this.paginadorGrillaDepartamentos.activePage = 1;
                $this.paginadorGrillaDepartamentos.loadData($this.paginadorGrillaDepartamentos.activePage, $this.buscarDepartamentos, $this.ordenarDepartamento);
            }, 500);

        } catch (err) {
            this.alerta.setActive(true, 'danger');
            this.alertOut(this.alerta);
        }
    }

    onOrder() {
        try {
            this.paginadorGrillaDepartamentos.loadData(this.paginadorGrillaDepartamentos.activePage, this.buscarDepartamentos, this.ordenarDepartamento);
        } catch (err) {
            this.alerta.setActive(true, 'danger');
            this.alertOut(this.alerta);
        }
    }

    onClearFilter() {
        try {
            this.buscarDepartamentos = '';
            this.ordenarDepartamento = 'A-Z';
            this.paginadorGrillaDepartamentos.activePage = 1;
            this.paginadorGrillaDepartamentos.loadData(this.paginadorGrillaDepartamentos.activePage, this.buscarDepartamentos, this.ordenarDepartamento);
        } catch (err) {
            this.alerta.setActive(true, 'danger');
            this.alertOut(this.alerta);
        }
    }

    upDateDataGrid(event): void {
        this.loadDepartamentos();
    }

    onExport(type) {
        try {
            this.wait_export = true;
            this._departamentosService.export(type, this.buscarDepartamentos, this.ordenarDepartamento).subscribe(
                response => {
                    var a = document.createElement("a");
                    a.href = response.file;
                    a.download = response.name;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    this.wait_export = false;
                },
                err => {
                    this.wait_export = false;
                    this.alerta.setActive(true, err.error.code);
                    this.alertOut(this.alerta);
                }
            );
        } catch (err) {
            this.wait_export = false;
            this.alerta.setActive(true, 'danger');
            this.alertOut(this.alerta);
        }
    }

    cancelarEliminar() {
        this.activePopup = false;
    }

    aceptoEliminar() {
        this.activePopup = false;
        this.onDelete(this.idEliminar);
    }
}