import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

//MODELS
import { ConfiguracioncreditoModel } from '../../../models/configuracioncredito.model';

//SERVICES
import { ConfiguracionCreditoServices } from '../../../services/configuracioncredito.services';

//UTILIDADES
import { Alerta } from '../../utilidades/alerta.component';
import { MycurrencyPipe } from '../../../pipe/mycurrency.pipe';

@Component({
	selector: 'app-configuracion-credito',
	templateUrl: './configuracion-credito.component.html',
	styleUrls: ['./configuracion-credito.component.scss'],
	providers: [ConfiguracionCreditoServices, MycurrencyPipe]
})

export class ConfiguracionCreditoComponent implements OnInit {

	public alertaCredito: Alerta;
	public dataConfiguracion: any = {};

	//MODELS
	public config: ConfiguracioncreditoModel;

	//FORM
	public formConfiguracion: FormGroup;

	constructor(
		private _servicesConfiguration: ConfiguracionCreditoServices,
		private formartPrecio: MycurrencyPipe
	) { 
		this.formConfiguracion = new FormGroup({
			maximo_credito_general: new FormControl('$0', Validators.required),
			maximo_credito_cliente: new FormControl('$0', Validators.required),
			tasa_mora: new FormControl('0', Validators.required),
			dias_expiracion: new FormControl('0', Validators.required),
			valor_minimo_credito: new FormControl('$0', Validators.required)
		});

		this.alertaCredito = new Alerta('alertaCredito');
	}

	private alertOut($ele, timer = 2000) {
		setTimeout(function () {
			$ele.setActive(false);
		}, timer);
	}

	private getValueConfig(data, name) {
		return data.filter(data => data.nombre == name)[0].valor;
	}

	private setValueConfig(data) {
		this.config.maximo_credito_cliente = this.formartPrecio.transform(this.getValueConfig(data, 'max_debt_client'));
		this.config.maximo_credito_general = this.formartPrecio.transform(this.getValueConfig(data, 'max_debt_general'));
		this.config.tasa_mora = this.getValueConfig(data, 'percentage_per_debt');
		this.config.dias_expiracion = this.getValueConfig(data, 'expiration_amount_day');
		this.config.valor_minimo_credito = this.formartPrecio.transform(this.getValueConfig(data, 'value_min_debt'));
	}

	ngOnInit() {
		this.resetModels();
		this.getConfiguration();
	}

	resetModels() {
		this.config = new ConfiguracioncreditoModel('', '', '', 0, '');
	}

	getConfiguration() {
		try {
			this._servicesConfiguration.getConfiguration().subscribe(
				response => {
					let data = response.data;
					this.setValueConfig(data);
					this.dataConfiguracion = data;					
				},
				error => {
					this.alertaCredito.setActive(true, 'danger');
					this.alertOut(this.alertaCredito);
				}
			)
		} catch (err) {
			this.alertaCredito.setActive(true, 'danger');
			this.alertOut(this.alertaCredito);
		}
	}

	onCancel() {
		this.setValueConfig(this.dataConfiguracion);
	}

	onSave() {
		try {
			this._servicesConfiguration.update(this.formConfiguracion.value).subscribe(
				response => {
					this.dataConfiguracion = response.data;
					this.alertaCredito.setActive(true, 'success');
					this.alertOut(this.alertaCredito, 4000);
				},
				err => {
					this.alertaCredito.setActive(true, err.error.code);
					this.alertOut(this.alertaCredito);
				}
			);
		} catch (err) {
			this.alertaCredito.setActive(true, 'danger');
			this.alertOut(this.alertaCredito);
		}
	}

}
