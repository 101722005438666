import { Component, OnInit, ViewChild } from '@angular/core';

//UTILIDADES
import { Alerta } from '../utilidades/alerta.component';
import { Paginador } from '../utilidades/paginador.component';
import { Generales } from '../utilidades/generales.component';

//SERVICES
import { MarcasServices } from '../../services/marcas.services';

//COMPONENT
import { MarcasdetalleComponent } from './marcasdetalle/marcasdetalle.component';

@Component({
	selector: 'app-marcas',
	templateUrl: './marcas.component.html',
	styleUrls: ['./marcas.component.scss'],
	providers: [
		MarcasServices
	]
})
export class MarcasComponent implements OnInit {
	public alerta: Alerta;
	public paginadorGrillaMarcas: any = ''; 
	public id: number = 0;
	public buscarMarcas: string = '';
	public ordenarMarcas: string = 'A-Z';
	public filtro: boolean = false;
	public utilidades: Generales;
	public wait_export: boolean = false;
	public timeout = null;
	public activePopup: boolean = false;
	public tituloPopup: string = '';
	public msgPopup: string = '';
	public idEliminar: number = 0;

	@ViewChild(MarcasdetalleComponent) marcaDetalle: MarcasdetalleComponent; //Componente hijo

	constructor(
		private _marcasService: MarcasServices
	) { 
		this.alerta = new Alerta('alertList');
		this.utilidades = new Generales();
	}

	public onFiltro() {
		if (this.filtro) {
			this.filtro = false;
		} else {
			this.filtro = true;
		}
	}

	private alertOut($ele, timer = 2000) {
		setTimeout(function () {
			$ele.setActive(false);
		}, timer);
	}

	private loadMarcas() {
		try {
			this.paginadorGrillaMarcas = new Paginador(this._marcasService, this.alerta);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}
	
	ngOnInit() {
		this.loadMarcas();
	}

	reloadData(event): void {
		this.id = event.idMarca;
		this.loadMarcas();
	}

	openFormMarcaDetalle(id) {
		this.id = id;
		this.marcaDetalle.openModalEditMarcaDetalle();
	}
	
	openEditMarcaDetalle(id) {
		this.id = id;
		this.marcaDetalle.openModalEditMarcaDetalle();
	}

	onValidDelete(id, cantidadProd) {
		this.idEliminar = 0;
		if (cantidadProd > 0) {
			this.idEliminar = id;
			this.activePopup = true;
			this.tituloPopup = '!Advertencia!';
			this.msgPopup = 'La marca que desea eliminar contiene productos asociados, si la elimina los producto quedarán ';
			this.msgPopup += 'asociados a una marca anónima. ¿Realmente desea eliminar esta marca?';
		} else {
			this.onDelete(id);
		}
	}

	onDelete(id) {
		try {
			this._marcasService.delete(id).subscribe(
				response => {
					let data = response.data;
					this.alerta.setActive(true, data.code);
					this.alertOut(this.alerta);
					let pagActiva = this.paginadorGrillaMarcas.activePage;
					if (this.paginadorGrillaMarcas.data.length == 1 && pagActiva > 1) this.paginadorGrillaMarcas.activePage--;
					this.paginadorGrillaMarcas.loadData(this.paginadorGrillaMarcas.activePage);
				},
				err => {
					this.alerta.setActive(true, err.error.code);
					this.alertOut(this.alerta);
				}
			);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	onOrder() {
		try {
			this.paginadorGrillaMarcas.loadData(this.paginadorGrillaMarcas.activePage, this.buscarMarcas, this.ordenarMarcas);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	onSearch(event) {
		try {
			let $this = this;
			clearTimeout($this.timeout);

			$this.timeout = setTimeout(function () {
				$this.buscarMarcas = event.target.value;
				$this.paginadorGrillaMarcas.activePage = 1;
				$this.paginadorGrillaMarcas.loadData($this.paginadorGrillaMarcas.activePage, $this.buscarMarcas, $this.ordenarMarcas);
			}, 500);	

		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	onClearFilter() {
		try {
			this.buscarMarcas = '';
			this.ordenarMarcas = 'A-Z';
			this.paginadorGrillaMarcas.activePage = 1;
			this.paginadorGrillaMarcas.loadData(this.paginadorGrillaMarcas.activePage, this.buscarMarcas, this.ordenarMarcas);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	onExport(type) {
		try {
			this.wait_export = true;
			this._marcasService.export(type, this.buscarMarcas, this.ordenarMarcas).subscribe(
				response => {
					var a = document.createElement("a");
					a.href = response.file;
					a.download = response.name;
					document.body.appendChild(a);
					a.click();
					a.remove();
					this.wait_export = false;
				},
				err => {
					this.wait_export = false;
					this.alerta.setActive(true, err.error.code);
					this.alertOut(this.alerta);
				}
			);
		} catch (err) {
			this.wait_export = false;
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	cancelarEliminar() {
		this.activePopup = false;
	}

	aceptoEliminar() {
		this.activePopup = false;
		this.onDelete(this.idEliminar);
	}

}
