import {Alerta} from './alerta.component';

export class Alertapopup extends Alerta {

  public message = '';

  closeAlert() {
    this.active = false;
  }

  openAlert(codeError, msg = '') {
    this.message = this.http_code(codeError, msg);
    this.active = true;
  }
}
