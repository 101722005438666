import { Component, OnInit } from '@angular/core';
import { EstadisticasServices } from '../../services/estadisticas.services';



@Component({
  selector: 'app-estadisticas',
  templateUrl: './estadisticas.component.html',
  styleUrls: ['./estadisticas.component.scss'],
  providers: [
    EstadisticasServices
  ]
})
export class EstadisticasComponent implements OnInit {
  public totalSale = 0;
  public totalProfitSale = 0;
  public totalPurchase = 0;
  public totalInvestmentGeneral = 0;
  public totalSaleGeneral = 0;
  public totalProfitSaleGeneral = 0;
  public profitPerMonth = [];
  public salePerMonth = [];
  public investmentPerMonth = [];
  public averageInvestmentMonth = 0;
  public averageProfitMonth = 0;
  public averageSaleMonth = 0;
  public years = [];
  public currentYear;
  public initYear = 2021;
  constructor(
    private _statisticsService: EstadisticasServices,
  ) { }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
    this.getStatistic(this.currentYear);
    this.setYear();
  }
  getStatistic(year) {
    this._statisticsService.getStatistics(year).subscribe(
      response => {

        this.totalSale = response.total_sale;
        this.totalPurchase = response.total_purchase;
        this.totalProfitSale = response.total_profit_sale;
        this.totalSaleGeneral = response.total_sale_general;
        this.totalInvestmentGeneral = response.total_investment_general;
        this.totalProfitSaleGeneral = response.total_profit_sale_general;
        this.profitPerMonth = response.profit_per_month;
        this.salePerMonth = response.sale_per_month;
        this.investmentPerMonth = response.investment_per_month;

        this.averageInvestmentMonth = response.average_investment_month;
        this.averageProfitMonth = response.average_profit_month;
        this.averageSaleMonth = response.average_sale_month;
      },
      err => {
        console.log(err);
      }
    );
  }

  setYear() {
    for (let i = this.initYear; i <= this.currentYear; i++) {
      this.years.push(i);
    }
  }

  selectYear() {
    this.getStatistic(this.currentYear);
  }

}
