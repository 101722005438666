export class PersonaModel {
    constructor(
        public idpersona: number,
        public codigo: string,
        public nombres: string,
        public apellidos: string,        
        public numerodocumento: string,
        public iddocumento: number,
        public telefono: string,
        public email: string,
        public celular: string,
        public estado: boolean,
        public direccion: string = ''
    ) { }
}