import { Component, OnInit, ViewChild } from '@angular/core';

//UTILIDADES
import { Alerta } from '../utilidades/alerta.component';
import { Paginador } from '../utilidades/paginador.component';
import { Generales } from '../utilidades/generales.component';

//SERVICES
import { ProveedoresServices } from '../../services/proveedores.services';

//COMPONENT
import { ProveedoresdetalleComponent } from './proveedoresdetalle/proveedoresdetalle.component';

@Component({
	selector: 'app-proveedores',
	templateUrl: './proveedores.component.html',
	styleUrls: ['./proveedores.component.scss'],
	providers: [
		ProveedoresServices
	]
})
export class ProveedoresComponent implements OnInit {
	public alerta: Alerta;
	public paginadorGrillaProveedores: any = ''; 
	public id: number = -1;
	public ordenarProveedores: string = 'A-Z';
	public buscarProveedor: string = '';
	public filtro: boolean = false;
	public utilidades: Generales;
	public wait_export: boolean = false;
	public timeout = null;

	@ViewChild(ProveedoresdetalleComponent) proveedorDetalle: ProveedoresdetalleComponent; //Componente hijo

	constructor(
		private _proveedoresService: ProveedoresServices
	) { 
		this.alerta = new Alerta('alertList');
		this.utilidades = new Generales();
	}

	private alertOut($ele, timer = 2000) {
		setTimeout(function () {
			$ele.setActive(false);
		}, timer);
	}

	private loadProveedor() {
		try {
			this.paginadorGrillaProveedores = new Paginador(this._proveedoresService, this.alerta);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	ngOnInit() {
		this.loadProveedor();
	}

	public onFiltro() {
		if (this.filtro) {
			this.filtro = false;
		} else {
			this.filtro = true;
		}
	}

	addProveedorDetalle() {
		this.proveedorDetalle.openModalDetalleProveedor(0);
	}

	editProveedorDetalle(id) {
		this.proveedorDetalle.openModalDetalleProveedor(id);
	}

	onDelete(id) {
		try {
			this._proveedoresService.delete(id).subscribe(
				response => {
					let data = response.data;
					this.alerta.setActive(true, data.code);
					this.alertOut(this.alerta);
					let pagActiva = this.paginadorGrillaProveedores.activePage;
					if (this.paginadorGrillaProveedores.data.length == 1 && pagActiva > 1) this.paginadorGrillaProveedores.activePage--;
					this.paginadorGrillaProveedores.loadData(this.paginadorGrillaProveedores.activePage);
				},
				err => {
					this.alerta.setActive(true, err.error.code);
					this.alertOut(this.alerta);
				}
			);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	onSearch(event) {
		try {
			let $this = this;
			clearTimeout($this.timeout);

			$this.timeout = setTimeout(function () {
				$this.buscarProveedor = event.target.value;
				$this.paginadorGrillaProveedores.activePage = 1;
				$this.paginadorGrillaProveedores.loadData($this.paginadorGrillaProveedores.activePage, $this.buscarProveedor, $this.ordenarProveedores);
			}, 500);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	onOrder() {
		try {
			this.paginadorGrillaProveedores.loadData(this.paginadorGrillaProveedores.activePage, this.buscarProveedor, this.ordenarProveedores);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	onClearFilter() {
		try {
			this.buscarProveedor = '';
			this.ordenarProveedores = 'A-Z';
			this.paginadorGrillaProveedores.activePage = 1;
			this.paginadorGrillaProveedores.loadData(this.paginadorGrillaProveedores.activePage, this.buscarProveedor, this.ordenarProveedores);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	upDateDataGrid(event): void {
		this.loadProveedor();
	}

	onExport(type) {
		try {
			this.wait_export = true;
			this._proveedoresService.export(type, this.buscarProveedor, this.ordenarProveedores).subscribe(
				response => {
					var a = document.createElement("a");
					a.href = response.file;
					a.download = response.name;
					document.body.appendChild(a);
					a.click();
					a.remove();
					this.wait_export = false;
				},
				err => {
					this.wait_export = false;
					this.alerta.setActive(true, err.error.code);
					this.alertOut(this.alerta);
				}
			);
		} catch (err) {
			this.wait_export = false;
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}
}
