import { Component, OnInit, Input, ViewChild} from '@angular/core';

//UTILIDADES
import { Alerta } from '../utilidades/alerta.component';
import { Paginador } from '../utilidades/paginador.component';
import { Modal } from '../utilidades/modal.component';
import { MycurrencyPipe } from '../../pipe/mycurrency.pipe';

//MODELS
import { ClienteModel } from '../../models/cliente.model';

//SERVICES
import { ClientesServices } from '../../services/clientes.services';

//COMPONENT
import { ClientedetalleComponent } from './clientedetalle/clientedetalle.component';


@Component({
  	selector: 'app-clientes',
  	templateUrl: './clientes.component.html',
	styleUrls: ['./clientes.component.scss'],
	providers: [
		ClientesServices, MycurrencyPipe
	]
})
export class ClientesComponent implements OnInit {
	
	public alerta: Alerta;
	//public divLoad
	public paginadorGrillaClientes: any = ''; //Variable que carga el paginador de la grilla de clientes
	//public modalClienteDetalle: any = '' //Formulario detalle cliente;

	//MODELS
	public dataClients: ClienteModel;

	//VARIABLES
	public alertsListado: any = ''; //Mesaje de alerta en la grilla
	public cantidadClientes: number = 0; //Cantidad de clientes
	public ordenarClientes: string = 'A-Z';
	public buscarClientes: string = '';
	public id: number = 0;
	public wait_export: boolean = false;
	public timeout = null;

	@ViewChild(ClientedetalleComponent) clienteDetalle: ClientedetalleComponent; //Componente hijo

	constructor(
		private _clientsService: ClientesServices,		
		private formatcurrencypipe: MycurrencyPipe
	) { 
		this.alerta = new Alerta('alertList');	
		//this.modalClienteDetalle = new Modal();
	}

	ngOnInit() {
		this.loadClients();
	}

	openFormAddClienteDetalle(id) {
		this.id = id;
		this.clienteDetalle.openModalAddClienteDetalle();
	}

	openFormEditClienteDetalle(id) {
		this.id = id;
		this.clienteDetalle.openModalEditClienteDetalle();
	}

	private alertOut($ele, timer = 2000) {
		setTimeout(function () {
			$ele.setActive(false);
		}, timer);
	}

	private loadClients() {
		try { 
			this.paginadorGrillaClientes = new Paginador(this._clientsService, this.alerta);				
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);	
		}
	}

	onDelete(id) {
		try { 
			this._clientsService.delete(id).subscribe(
				response => {					
					let data = response.data;
					this.alerta.setActive(true, data.code);
					this.alertOut(this.alerta);					
					let pagActiva = this.paginadorGrillaClientes.activePage;					
					if (this.paginadorGrillaClientes.data.length == 1 && pagActiva > 1) this.paginadorGrillaClientes.activePage --;
					this.paginadorGrillaClientes.loadData(this.paginadorGrillaClientes.activePage);
				},
				err => {					
					this.alerta.setActive(true, err.error.code);
					this.alertOut(this.alerta);	
				}
			);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);	
		}
	}

	onExport(type) {	
		try {
			this.wait_export = true;
			this._clientsService.export(type, this.buscarClientes, this.ordenarClientes).subscribe(
				response => {
					var a = document.createElement("a");
					a.href = response.file;
					a.download = response.name;
					document.body.appendChild(a);
					a.click();
					a.remove();
					this.wait_export = false;
				},
				err => {			
					this.wait_export = false;
					this.alerta.setActive(true, err.error.code);
					this.alertOut(this.alerta);	
				}
			);	
		} catch (err) {
			this.wait_export = false;
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);	
		}
	}

	onSearch(event) {		
		try { 
			
			let $this = this;
			clearTimeout($this.timeout);

			$this.timeout = setTimeout(function () {
				$this.buscarClientes = event.target.value;
				$this.paginadorGrillaClientes.activePage = 1;
				$this.paginadorGrillaClientes.loadData($this.paginadorGrillaClientes.activePage, $this.buscarClientes, $this.ordenarClientes);			
			}, 500);

		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);	
		}
	}

	onOrder() {
		try {			
			this.paginadorGrillaClientes.loadData(this.paginadorGrillaClientes.activePage, this.buscarClientes, this.ordenarClientes);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);	
		}
	}

	onClearFilter() {
		try {	
			this.buscarClientes = '';
			this.ordenarClientes = 'A-Z';
			this.paginadorGrillaClientes.activePage = 1;
			this.paginadorGrillaClientes.loadData(this.paginadorGrillaClientes.activePage, this.buscarClientes, this.ordenarClientes);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);	
		}
	}

	reloadData(event): void {
		this.id = event.idCliente;
		this.loadClients();
	}

}
