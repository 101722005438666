export class DevolucionModel {
    constructor(
        public iddevolucion: number = 0,
        public codigo: string = '',
        public fecha: string = '',
        public nombres: string  = '',
        public apellidos: string = '',        
        public abono: number = 0,        
        public cantidad: number = 0,
        public subtotal: number = 0,
        public total: number = 0,
        public descuento: number = 0
    ) { }
}