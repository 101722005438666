import {Component, OnInit, ViewChild} from '@angular/core';

import {Alerta} from '../utilidades/alerta.component';
import {Paginator} from '../utilidades/paginator.component';
import {Generales} from '../utilidades/generales.component';
import {MycurrencyPipe} from '../../pipe/mycurrency.pipe';
import {ProductosServices} from '../../services/productos.services';
import {ProductosdetalleComponent} from './productosdetalle/productosdetalle.component';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.scss'],
  providers: [
    ProductosServices, MycurrencyPipe
  ]
})

export class ProductosComponent implements OnInit {

  public alerta: Alerta;
  public paginadorGrillaProductos: any = '';
  public id = -1;
  public ordenarProductos = '-date_purchase';
  public buscarProductos = '';
  public toDate = '';
  public fromDate = '';
  public filtro = false;
  public utilidades: Generales;
  public timeout = null;
  public wait_export = false;
  public activePopup = false;
  public tituloPopup = '';
  public msgPopup = '';
  public idEliminar = 0;
  public fields = 'fields=date_purchase';
  public activePage = 1;

  @ViewChild(ProductosdetalleComponent) productoDetalle: ProductosdetalleComponent;

  constructor(
    private _productosService: ProductosServices,
  ) {
    this.alerta = new Alerta('alertList');
    this.utilidades = new Generales();
  }

  private alertOut($ele, timer = 2000) {
    setTimeout(function () {
      $ele.setActive(false);
    }, timer);
  }

  private loadProductos() {
    try {

      if (!this.getFilter()) {
        this.paginadorGrillaProductos = new Paginator(this._productosService, this.alerta, '', this.ordenarProductos, this.fields);
      } else {
        this.paginadorGrillaProductos = new Paginator(this._productosService, this.alerta, this.buscarProductos,
          this.ordenarProductos, this.fields, undefined, this.activePage);
      }
      this.paginadorGrillaProductos.getPageActive$().subscribe(pageActive => {
        this.activePage = pageActive;
        this.setFilter();
      });

    } catch (err) {
      this.alerta.setActive(true, 'danger');
      this.alertOut(this.alerta);
    }
  }

  private zeroPadded(val) {
    if (val >= 10) {
      return val;
    } else {
      return '0' + val.toString();
    }
  }

  ngOnInit() {
    this.loadProductos();
  }

  onFiltro() {
    if (this.filtro) {
      this.filtro = false;
    } else {
      this.filtro = true;
    }
  }

  onValidDelete(id) {
    this.idEliminar = id;
    this.activePopup = true;
    this.tituloPopup = '!Advertencia!';
    this.msgPopup = '¿Realmente desea eleminar este producto?';
  }

  cancelarEliminar() {
    this.activePopup = false;
  }

  aceptoEliminar() {
    this.activePopup = false;
    this.onDelete(this.idEliminar);
  }

  onDelete(id) {
    try {
      this.paginadorGrillaProductos.activeLoadingPag = true;
      this._productosService.delete(id).subscribe(
        response => {
          if (response.success) {
            this.alerta.setActive(true, response.message.code);
            this.alertOut(this.alerta);
            const pagActiva = this.paginadorGrillaProductos.activePage;
            if (this.paginadorGrillaProductos.data.length === 1 && pagActiva > 1) {
              this.paginadorGrillaProductos.activePage--;
            }
            this.upDateDataGrid();
          }
        },
        err => {
          this.paginadorGrillaProductos.activeLoadingPag = false;
          this.alerta.setActive(true, err.error.code);
          this.alertOut(this.alerta);
        }
      );
    } catch (err) {
      this.paginadorGrillaProductos.activeLoadingPag = false;
      this.alerta.setActive(true, 'danger');
      this.alertOut(this.alerta);
    }
  }

  onSearch() {
    try {
      this.paginadorGrillaProductos.activePage = 1;
      this.activePage = 1;
      this.paginadorGrillaProductos.loadData(this.paginadorGrillaProductos.activePage, this.buscarProductos,
        this.ordenarProductos, this.fields);
      this.setFilter();
    } catch (err) {
      this.alerta.setActive(true, 'danger');
      this.alertOut(this.alerta);
    }
  }

  onOrder() {
    try {
      this.paginadorGrillaProductos.loadData(this.paginadorGrillaProductos.activePage, this.buscarProductos,
        this.ordenarProductos, this.fields);
      this.setFilter();
    } catch (err) {
      this.alerta.setActive(true, 'danger');
      this.alertOut(this.alerta);
    }
  }

  onClearFilter() {
    try {
      this.fromDate = '';
      this.toDate = '';
      this.buscarProductos = '';
      this.ordenarProductos = '-date_purchase';
      this.paginadorGrillaProductos.activePage = 1;
      this.paginadorGrillaProductos.loadData(this.paginadorGrillaProductos.activePage, this.buscarProductos,
        this.ordenarProductos, this.fields);
      this.setFilter();
    } catch (err) {
      this.alerta.setActive(true, 'danger');
      this.alertOut(this.alerta);
    }
  }

  upDateDataGrid() {
    this.paginadorGrillaProductos.loadData(this.paginadorGrillaProductos.activePage, this.buscarProductos,
      this.ordenarProductos, this.fields);
    this.setFilter();
  }

  onExport(type) {

    try {
      this.wait_export = true;
      this._productosService.export(type, this.buscarProductos, this.ordenarProductos, 1,
        this.paginadorGrillaProductos.cantidad).subscribe(
        response => {
          const a = document.createElement('a');
          a.href = response.file;
          a.download = response.name;
          document.body.appendChild(a);
          a.click();
          a.remove();
          this.wait_export = false;
        },
        err => {
          this.wait_export = false;
          this.alerta.setActive(true, err.error.code);
          this.alertOut(this.alerta);
        }
      );
    } catch (err) {
      this.wait_export = false;
      this.alerta.setActive(true, 'danger');
      this.alertOut(this.alerta);
    }
  }

  setFilter() {
    const filter = {
      'page_active': this.activePage,
      'search': this.buscarProductos,
      'order': this.ordenarProductos,
      'fields': this.fields
    };
    localStorage.setItem('filter_products', JSON.stringify(filter));
  }

  getFilter() {
    const filter = JSON.parse(localStorage.getItem('filter_products'));
    if (typeof filter !== 'undefined' && filter) {
      this.activePage = filter.page_active;
      this.buscarProductos = filter.search;
      this.ordenarProductos = filter.order;
      this.fields = filter.fields;
      return true;
    } else {
      return false;
    }
  }
}
