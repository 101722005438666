import { Alerta } from '../utilidades/alerta.component';
import { Loading } from '../utilidades/loading.component';

export class Paginador {
    public alerta: any;
    public loading: Loading;
    public itemsPerPage = 1;
    public numberOfVisiblePaginators = 2;
    public numberOfPaginators: number;
    public paginators: Array<any> = [];
    public inicial: number = 1;
    public final: number = 1;

    public firstVisibleIndex = 1;
    public lastVisibleIndex: number = this.itemsPerPage;
    public firstVisiblePaginator = 0;
    public lastVisiblePaginator = this.numberOfVisiblePaginators;

    public numInitialPaginator = 1;
    public numPaginatorVisible = 5; //Cantidad de numeros visibles en el paginador
    public itemVisbleByPage = 10; //Cantidad de item visible en la tabla o pagina
    public activePage = 1;
    public cantItem = 0;
    public paginatorVisible = true;
    public cantidad:number;
    public service: any;
    public data: any = [];
    public search: string = '';
    public order: string = '';
    public alertsListado: any = ''; //Mesaje de alerta en la grilla
    public activeLoadingPag: boolean = false;    
    public paramExtras: string = '';

    constructor(service, alerta: any = '', search = '', order = '', param = '', cantItems = 10) {        
        this.service = service;
        this.alerta = alerta;
        this.itemVisbleByPage = cantItems;      
        this.loadData(1, search, order, param);     
        this.loading = new Loading();   
    }

    private alertOut($ele, timer = 2000) {
        setTimeout(function () {
            $ele.setActive(false);
        }, timer);
    }
    
    loadData(page = 1, search = '', order = '', param = '') {
        try {
            this.search = search;
            this.order = order;
            this.paramExtras = param;
            this.activeLoadingPaginador(true);
            this.service.getByPage(page, this.itemVisbleByPage, this.search, this.order, this.paramExtras).subscribe(
                response => {
                    this.activeLoadingPaginador(false);
                    this.data = response.data;
                    this.cantidad = response.totalItems;
                    this.loadPaginator();
                },
                err => {     
                    if(this.alerta != '') {
                        this.activeLoadingPaginador(false);
                        this.alerta.setActive(true, err.error.code);
                        this.alertOut(this.alerta);
                    }
                    
                }
            );
        } catch(e) {
            this.activeLoadingPaginador(false);
            this.alerta.setActive(true, 'danger');
            this.alertOut(this.alerta);
        }
    }

    loadPaginator() {
        this.paginators = [];        
        this.cantItem = Math.ceil(this.cantidad / this.itemVisbleByPage);
        this.numberOfPaginators = this.cantItem;
        this.inicial = (this.activePage - this.numPaginatorVisible) + 2;
        this.inicial = this.inicial <= 0 ? 1 : this.inicial;
        this.final = (this.inicial + this.numPaginatorVisible - 1);         
        if (this.cantItem <= 1) {
            this.paginatorVisible = false;            
        } else {            
            this.paginatorVisible = true;
            if (this.numPaginatorVisible > this.cantItem) this.numPaginatorVisible = this.cantItem;
            if (this.final > this.cantItem) {
                this.final = this.cantItem;
                if (this.inicial - 1 <= 0) this.inicial = 1;
                else this.inicial--; 
            }
            this.paginators = [];
            for (let i = this.inicial; i <= this.final; i++) {
                this.paginators.push(i);
            }
        }        
    }

    changePage(event) {
        let activePage = parseInt(event.target.text);    
        if (activePage <= this.cantItem && activePage != this.activePage) {
            this.activePage = +activePage;
            let inicial = (this.activePage - this.numPaginatorVisible) + 2;
            inicial = inicial <= 0 ? 1 : inicial;
            let final = (inicial + this.numPaginatorVisible - 1);            

            this.loadData(this.activePage, this.search, this.order, this.paramExtras);
        }
    }

    firstPage() {
        if (this.activePage != 1) {
            this.activePage = 1;
            this.loadData(this.activePage, this.search, this.order, this.paramExtras);
        }        
    }

    lastPage() {        
        if (this.activePage != this.numberOfPaginators) {
            this.activePage = this.numberOfPaginators;
            this.loadData(this.activePage, this.search, this.order, this.paramExtras);
        }        
    }

    nextPage() {        
        if (this.activePage != this.activePage + 1 && this.activePage + 1 <= this.numberOfPaginators) {
            this.activePage++;
            this.loadData(this.activePage, this.search, this.order, this.paramExtras);
        }
    }

    previousPage() {
        if (this.activePage != this.activePage - 1 && this.activePage - 1 >= 1) {
            this.activePage--;
            this.loadData(this.activePage, this.search, this.order, this.paramExtras);
        }        
    }

    loadingPaginador() {
        return this.loading.getLoading();
    }

    activeLoadingPaginador(active) {
        this.activeLoadingPag = active;
        return this.activeLoadingPag;
    }
}