import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';

//UTILIDADES
import { Alerta } from '../utilidades/alerta.component';
import { Paginador } from '../utilidades/paginador.component';

//SERVICES
import { ProveedorescontactoServices } from '../../services/Proveedorescontacto.services';

//COMPONENT
import { ProveedorcontactodetalleComponent } from './proveedorcontactodetalle/proveedorcontactodetalle.component';
import { ProveedoresdetalleComponent } from "../proveedores/proveedoresdetalle/proveedoresdetalle.component";

@Component({
	selector: 'app-proveedorcontactos',
	templateUrl: './proveedorcontactos.component.html',
	styleUrls: ['./proveedorcontactos.component.scss'],
	providers: [
		ProveedorescontactoServices
	]
})
export class ProveedorcontactosComponent implements OnInit {
	public alerta: Alerta;
	public paginadorProveedorContactos: any = '';
	public id: number = -1;
	public ordenarProveedoresContacto: string = 'A-Z';
	public buscarProveedorContacto: string = '';
	public idProveedor: number = 0;
	public idProv: number = 0;
	public filtro: boolean = false;
	public disableControl: boolean = true;
	public wait_export: boolean = false;
	public timeout = null;
	@Output() modalChild = new EventEmitter();

	@ViewChild(ProveedorcontactodetalleComponent) proveedorContactoDetalle: ProveedorcontactodetalleComponent; //Componente hijo
	//@ViewChild(ProveedoresdetalleComponent) proveedorDetalle: ProveedoresdetalleComponent; //Componente 	
	@Input('_idProveedor') set changeIdProveedor(id: number) {
		if (id > 0) {
			this.idProveedor = id;
			this.loadProveedorContacto(id);			
		}
		this.getCantProveedores();
	}

	constructor(
		private _proveedoresContactoService: ProveedorescontactoServices
	) {
		this.alerta = new Alerta('alertList');
	}

	private alertOut($ele, timer = 2000) {
		setTimeout(function () {
			$ele.setActive(false);
		}, timer);
	}

	private loadProveedorContacto(id) {
		try {
			let param = 'id_proveedor=' + id;
			this.paginadorProveedorContactos = new Paginador(this._proveedoresContactoService, this.alerta, '', '', param);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	private getCantProveedores() {
		try {
			this._proveedoresContactoService.getByPage(1, 10, '', 'asc', 'id_proveedor=' + this.idProveedor).subscribe(response => {				
				if (response.totalItems > 0) {
					this.disableControl = false;
				} else {
					this.disableControl = true;
				}
			},
				err => {
					this.alerta.setActive(true, err.error.code);
					this.alertOut(this.alerta);
				})
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	ngOnInit() { }

	onFiltro() {
		if (this.filtro) {
			this.filtro = false;
		} else {
			this.filtro = true;
		}
	}

	openAddProveedorContactoDetalle(idProveedor) {
		this.idProv = idProveedor;
		this.proveedorContactoDetalle.addContacto();
		this.modalChild.emit({ 'modal': true });
	}

	openEditProveedorContactoDetalle(id) {
		this.proveedorContactoDetalle.onEdit(id);
		this.modalChild.emit({ 'modal': true });
	}

	closeFormChildren(event) {
		this.modalChild.emit({ 'modal': event.modal });
	}

	onDelete(id) {
		try {
			this._proveedoresContactoService.delete(id).subscribe(
				response => {
					let data = response.data;
					this.alerta.setActive(true, data.code);
					this.alertOut(this.alerta);
					let pagActiva = this.paginadorProveedorContactos.activePage;
					if (this.paginadorProveedorContactos.data.length == 1 && pagActiva > 1) this.paginadorProveedorContactos.activePage--;
					let param = 'id_proveedor=' + this.idProveedor;
					this.paginadorProveedorContactos.loadData(this.paginadorProveedorContactos.activePage, this.buscarProveedorContacto, this.ordenarProveedoresContacto, param);
					//this.paginadorProveedorContactos.loadData(this.paginadorProveedorContactos.activePage);
				},
				err => {
					this.alerta.setActive(true, err.error.code);
					this.alertOut(this.alerta);
				}
			);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	onSearch(event) {
		try {
			let $this = this;
			clearTimeout($this.timeout);
			$this.timeout = setTimeout(function () {
				$this.buscarProveedorContacto = event.target.value;
				$this.paginadorProveedorContactos.activePage = 1;
				let param = 'id_proveedor=' + $this.idProveedor;
				$this.paginadorProveedorContactos.loadData($this.paginadorProveedorContactos.activePage, $this.buscarProveedorContacto, $this.ordenarProveedoresContacto, param);
			}, 1000);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	onOrder() {
		try {
			let param = 'id_proveedor=' + this.idProveedor;
			this.paginadorProveedorContactos.loadData(this.paginadorProveedorContactos.activePage, this.buscarProveedorContacto, this.ordenarProveedoresContacto, param);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	onClearFilter() {
		try {
			this.buscarProveedorContacto = '';
			this.ordenarProveedoresContacto = 'A-Z';
			this.paginadorProveedorContactos.activePage = 1;
			let param = 'id_proveedor=' + this.idProveedor;
			this.paginadorProveedorContactos.loadData(this.paginadorProveedorContactos.activePage, this.buscarProveedorContacto, this.ordenarProveedoresContacto, param);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	reloadData(event): void {
		if (event.reload) {
			this.loadProveedorContacto(this.idProveedor);
		}
	}

	onExport(type) {
		try {
			this.wait_export = true;
			this._proveedoresContactoService.export(type, this.buscarProveedorContacto, this.ordenarProveedoresContacto).subscribe(
				response => {
					var a = document.createElement("a");
					a.href = response.file;
					a.download = response.name;
					document.body.appendChild(a);
					a.click();
					a.remove();
					this.wait_export = false;
				},
				err => {
					this.wait_export = false;
					this.alerta.setActive(true, err.error.code);
					this.alertOut(this.alerta);
				}
			);
		} catch (err) {
			this.wait_export = false;
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}
}
