export class Alerta {


    public warning: boolean;
    public success: boolean;
    public danger: boolean;
    public info: boolean;
    public active: boolean;
    public id: string;
    public code: any;
    public message: string = '';
    public faildValidation: any = [];

    constructor(id = null, warning = false, success = false, danger = false, info = true, active = false) {
        this.warning = warning;
        this.success = success;
        this.danger = danger;
        this.info = info;
        this.active = active;
        this.id = id;
    }

    public http_code(code, msg, faildValidation = []) {

        if ('undefined' !== typeof msg.message && msg.message == 'Server Error') {
            this.warning = false;
            this.success = false;
            this.danger = true;
            this.info = false;
            return msg != '' ? msg : 'Se produjo un error inesperado, ingrese nuevamente o comuniquese con el adminsitrador.';
        }
        switch (code) {
            case 'danger':
            case 406:
                this.warning = false;
                this.success = false;
                this.danger = true;
                this.info = false;
                return msg != '' ? msg : 'Se produjo un error inesperado.';
            case 'success':
            case 200:
                this.warning = false;
                this.success = true;
                this.danger = false;
                this.info = false;
                return msg != '' ? msg : 'El proceso se realizó con éxito.';
            case 400:
                if (JSON.stringify(faildValidation) != '{}') {
                    this.warning = false;
                    this.success = false;
                    this.danger = true;
                    this.info = false;
                    Object.keys(faildValidation).map((key) => {
                        msg += faildValidation[key] + '<br />';
                    });
                    return msg;
                }
            case 201:
                this.warning = false;
                this.success = true;
                this.danger = false;
                this.info = false;
                return msg != '' ? msg : 'El registro fue creado con éxito.';
            case 403:
                this.warning = false;
                this.success = false;
                this.danger = true;
                this.info = false;
                return msg != '' ? msg : 'Por favor verifique los datos del formulario o comuníquese con el administrador.';
            case 404:
                this.warning = false;
                this.success = false;
                this.danger = true;
                this.info = false;
                return msg != '' ? msg : 'Registro no encontrado';
            case 401:
                this.warning = false;
                this.success = false;
                this.danger = true;
                this.info = false;
                return msg != '' ? msg : 'No autorizado';
            case 500:
                this.warning = false;
                this.success = false;
                this.danger = true;
                this.info = false;
                return msg != '' ? msg : 'Ha ocurrido un problema al acceder la información.';
            case 'warning':
                this.warning = true;
                this.success = false;
                this.danger = false;
                this.info = false;
                return msg != '' ? msg : 'Advertencia.';
            case 'info':
                this.warning = false;
                this.success = false;
                this.danger = false;
                this.info = true;
                return msg != '' ? msg : 'información Importante';
            case 202:
                this.warning = false;
                this.success = true;
                this.danger = false;
                this.info = false;
                return msg != '' ? msg : 'El registro se elimino con éxito.';
            case 'export':
                this.warning = false;
                this.success = false;
                this.danger = true;
                this.info = false;
                return msg != '' ? msg : 'Tipo de exportación no valida.';
            case 'update':
                this.warning = false;
                this.success = true;
                this.danger = false;
                this.info = false;
                return msg != '' ? msg : 'El registro fue actualizado con éxito';
            case 'error_quantity':
                this.warning = false;
                this.success = false;
                this.danger = true;
                this.info = false;
                return msg != '' ? msg : 'La cantidad solicitada no se encuentra disponible';
            case 'pay':
                this.warning = false;
                this.success = true;
                this.danger = false;
                this.info = false;
                return msg != '' ? msg : 'El pago se ha realizado con éxito';
            case 'complete_form_cliente':
                this.warning = true;
                this.success = false;
                this.danger = false;
                this.info = false;
                return msg != '' ? msg : 'No a agregado el valor a pagar en el campo "efectivo", para productos a creditos debe llenar el formulario de datos del cliente.';
            case 'general_minor':
                this.warning = true;
                this.success = false;
                this.danger = false;
                this.info = false;
                return msg != '' ? msg : 'El valor del crédito general no puede ser menor igual al crédito del cliente';
            case 'price_not_valid':
                this.warning = true;
                this.success = false;
                this.danger = false;
                this.info = false;
                return msg != '' ? msg : 'El precio no puede ser $0';
            case 'nit_exists':
                this.warning = false;
                this.success = false;
                this.danger = true;
                this.info = false;
                return msg != '' ? msg : 'No se pudo guardar por que el nit ya existe';
            case 'code_exists':
                this.warning = false;
                this.success = false;
                this.danger = true;
                this.info = false;
                return msg != '' ? msg : 'No se pudo guardar por que el código ya existe';

            default:
                return msg != '' ? msg : 'Ocurrió un problema inesperado, por favor comuniquese con el adminstrador';
        }
    }

    public getAlert(message = '') {
        let m = message != '' ? message : this.message;
        let msg = this.http_code(this.code, m, this.faildValidation);
        let warning = this.warning ? ' alert-warning' : '';
        let success = this.success ? ' alert-success' : '';
        let danger = this.danger ? ' alert-danger' : '';
        let info = this.info ? ' alert-info' : '';
        let html = `<div class="${this.id}${warning}${success}${danger}${info}">${msg}</div>`;
        return html;
    }

    public animateAlertOut($el, timer = 2000) {
        setTimeout(function () {
            $el.active = false;
        }, timer);
    }

    public setActive(active, code: any = '', message = '', faildValidation = []) {
        this.active =  active;
        this.code = code;
        this.message = message;
        this.faildValidation = faildValidation;
    }

}
