export class ProductoscreditoModel {
    constructor(
        public id_producto: number,
        public id_costo: number,   
        public idconcepto:number,
        public idcompra: number,        
        public codigo: string,
        public concepto: string,
        public cantidad: number,
        public precio: any,
        public descuento: string
    ) { }
}