import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

@Component({
	selector: 'app-configuraciones',
	templateUrl: './configuraciones.component.html',
	styleUrls: ['./configuraciones.component.scss']
})
export class ConfiguracionesComponent implements OnInit {	

	public routeLinks: any[];
	public activeLinkIndex = -1;

	constructor(
		private router: Router
	){
		this.routeLinks = [
			{
				label: 'Crédito',
				link: './credito',
				index: 0
			}/*,
			{
				label: 'Alertas',
				link: './alertas',
				index: 1
			}*/
		];
	}

	ngOnInit() {
	}

}
