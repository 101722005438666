import { Component, OnInit, ViewChild} from '@angular/core';

//UTILIDADES
import { Alerta } from '../utilidades/alerta.component';
import { Paginador } from '../utilidades/paginador.component';
import { Generales } from '../utilidades/generales.component';


//SERVICES
import { SubcategoriasServices } from '../../services/subcategorias.services';

//COMPONENT
import { SubcategoriasdetalleComponent } from './subcategoriasdetalle/subcategoriasdetalle.component';

@Component({
	selector: 'app-subcategorias',
	templateUrl: './subcategorias.component.html',
	styleUrls: ['./subcategorias.component.scss'],
	providers: [
		SubcategoriasServices
	]
})

export class SubcategoriasComponent implements OnInit {
	public alerta: Alerta;
	public paginadorGrillaSubcategorias: any = '';
	public id: number = -1;
	public ordenarSubcategoria: string = 'A-Z';
	public buscarSubcategorias: string = '';
	public filtro: boolean = false;
	public utilidades: Generales;
	public wait_export: boolean = false;
	public timeout = null;
	public activePopup: boolean = false;
	public tituloPopup: string = '';
	public msgPopup: string = '';
	public idEliminar: number = 0;
	
	@ViewChild(SubcategoriasdetalleComponent) SubcategoriaDetalle: SubcategoriasdetalleComponent; //Componente hijo

	constructor(
		private _SubcategoriasService: SubcategoriasServices
	) { 
		this.alerta = new Alerta('alertList');
		this.utilidades = new Generales();
	}

	private alertOut($ele, timer = 2000) {
		setTimeout(function () {
			$ele.setActive(false);
		}, timer);
	}

	private loadSubcategorias() {
		try {
			this.paginadorGrillaSubcategorias = new Paginador(this._SubcategoriasService, this.alerta);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	private zeroPadded(val) {
		if (val >= 10)
			return val;
		else
			return '0' + val.toString();
	}

	onFiltro() {
		if (this.filtro) {
			this.filtro = false;
		} else {
			this.filtro = true;
		}
	}

	ngOnInit() {		
		this.loadSubcategorias();
	}

	addSubcategoriaDetalle() {
		this.SubcategoriaDetalle.openModalDetalleSubcategorias(0);
	}

	editSubcategoriaDetalle(id) {
		this.SubcategoriaDetalle.openModalDetalleSubcategorias(id);
	}

	onValidDelete(id, cantidadProd) {
		this.idEliminar = 0;
		if (cantidadProd > 0) {
			this.idEliminar = id;
			this.activePopup = true;
			this.tituloPopup = '!Advertencia!';
			this.msgPopup = 'La subcategoría que desea eliminar contiene productos asociados, si la elimina los producto quedarán ';
			this.msgPopup += 'asociados a una subcategoría anónima. ¿Realmente desea eliminar esta subcategoría?';
		} else {
			this.onDelete(id);
		}
	}

	onDelete(id) {
		try {
			this._SubcategoriasService.delete(id).subscribe(
				response => {
					let data = response.data;
					this.alerta.setActive(true, data.code);
					this.alertOut(this.alerta);
					let pagActiva = this.paginadorGrillaSubcategorias.activePage;
					if (this.paginadorGrillaSubcategorias.data.length == 1 && pagActiva > 1) this.paginadorGrillaSubcategorias.activePage--;
					this.paginadorGrillaSubcategorias.loadData(this.paginadorGrillaSubcategorias.activePage);
				},
				err => {
					this.alerta.setActive(true, err.error.code);
					this.alertOut(this.alerta);
				}
			);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	onSearch(event) {
		try {
			let $this = this;
			clearTimeout($this.timeout);
			$this.timeout = setTimeout(function () {
				$this.buscarSubcategorias = event.target.value;
				$this.paginadorGrillaSubcategorias.activePage = 1;			
				$this.paginadorGrillaSubcategorias.loadData($this.paginadorGrillaSubcategorias.activePage, $this.buscarSubcategorias, $this.ordenarSubcategoria);
			}, 500);	
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	onOrder() {
		try {			
			this.paginadorGrillaSubcategorias.loadData(this.paginadorGrillaSubcategorias.activePage, this.buscarSubcategorias, this.ordenarSubcategoria);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	onClearFilter() {
		try {
			this.buscarSubcategorias = '';
			this.ordenarSubcategoria = 'A-Z';
			this.paginadorGrillaSubcategorias.activePage = 1;
			this.paginadorGrillaSubcategorias.loadData(this.paginadorGrillaSubcategorias.activePage, this.buscarSubcategorias, this.ordenarSubcategoria);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	upDateDataGrid(event): void {
		this.loadSubcategorias();
	}

	onExport(type) {
		try {
			this.wait_export = true;
			this._SubcategoriasService.export(type, this.buscarSubcategorias, this.ordenarSubcategoria).subscribe(
				response => {
					var a = document.createElement("a");
					a.href = response.file;
					a.download = response.name;
					document.body.appendChild(a);
					a.click();
					a.remove();
					this.wait_export = false;
				},
				err => {
					this.wait_export = false;
					this.alerta.setActive(true, err.error.code);
					this.alertOut(this.alerta);
				}
			);
		} catch (err) {
			this.wait_export = false;
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	cancelarEliminar() {
		this.activePopup = false;
	}

	aceptoEliminar() {
		this.activePopup = false;
		this.onDelete(this.idEliminar);
	}

}