import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { User } from './user';
import { UserServices } from '../../services/user.services';
@Injectable()

export class AuthService {
    private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public loadLogin: boolean = false;
    //public show: boolean = false;
    get isLoggedIn() {
        return this.loggedIn.asObservable();
    }

    constructor(
        private router: Router,
        private userservices: UserServices
    ) { }
    
    setLocalStorage(nombre, data) {
        localStorage.setItem(nombre, JSON.stringify(data));
    }

    login(user: User) {        
        if (user.name !== '' && user.password !== '') {
            this.loadLogin = true;
            this.userservices.login(user).subscribe(
                    response => {                        
                        if (response.access_token) {
                            this.setLocalStorage("sessionuser", response);
                            this.loggedIn.next(true);
                            this.router.navigate(['/']);
                        }                        
                    },
                    error => {
                        var error = <any>error;
                        if(error.status == 401) {                            
                            //this.show = true;
                            alert("USUARIO NO ENCONTRADO");
                            this.loadLogin = false;
                        }
                    }
            ); 
        }
    }

    logout() {
        this.userservices.logout().subscribe(
            response => {
                localStorage.removeItem('sessionuser');
                this.loggedIn.next(false);
                this.router.navigate(['/login']);
            },
            error => {
            }
        );
    }
}