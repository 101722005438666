import { Component, OnInit, ViewChild} from '@angular/core';

//UTILIDADES
import { Alerta } from '../utilidades/alerta.component';
import { Paginador } from '../utilidades/paginador.component';
import { Generales } from '../utilidades/generales.component';

import { CategoriasServices } from '../../services/categorias.services';

//COMPONENT
import { CategoriasdetalleComponent } from './categoriasdetalle/categoriasdetalle.component';

@Component({
	selector: 'app-categorias',
	templateUrl: './categorias.component.html',
	styleUrls: ['./categorias.component.scss'],
	providers: [
		CategoriasServices
	]
})

export class CategoriasComponent implements OnInit {
	public alerta: Alerta;
	public paginadorGrillaCategorias: any = '';
	public id: number = -1;
	public ordenarCategoria: string = 'A-Z';
	public buscarCategorias: string = '';
	public filtro: boolean = false;
	public utilidades: Generales;
	public wait_export: boolean = false;
	public timeout = null;
	public activePopup: boolean = false;
	public tituloPopup: string = '';
	public msgPopup: string = '';
	public idEliminar: number = 0;

	@ViewChild(CategoriasdetalleComponent) categoriaDetalle: CategoriasdetalleComponent; //Componente hijo

	constructor(
		private _categoriasService: CategoriasServices
	){
		this.alerta = new Alerta('alertList');
		this.utilidades = new Generales();
	}

	private alertOut($ele, timer = 2000) {
		setTimeout(function () {
			$ele.setActive(false);
		}, timer);
	}

	private loadCategorias() {
		try {
			this.paginadorGrillaCategorias = new Paginador(this._categoriasService, this.alerta);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	onFiltro() {
		if (this.filtro) {
			this.filtro = false;
		} else {
			this.filtro = true;
		}
	}

	ngOnInit() {
		this.loadCategorias();
	}

	addCategoriaDetalle() {
		this.categoriaDetalle.openModalDetalleCategorias(0);
	}

	editCategoriaDetalle(id) {
		this.categoriaDetalle.openModalDetalleCategorias(id);
	}

	onValidDelete(id, cantidadProd) {
		this.idEliminar = 0;
		if (cantidadProd > 0) {
			this.idEliminar = id;
			this.activePopup = true;
			this.tituloPopup = '!Advertencia!';
			this.msgPopup = 'La marca que desea eliminar contiene productos asociados si la elimina los producto quedarán ';
			this.msgPopup += 'asociados a una marca anónima. ¿Realmente desea eliminar esta subcategoría?';
		} else {
			this.onDelete(id);
		}
	}

	onDelete(id) {
		try {
			this._categoriasService.delete(id).subscribe(
				response => {
					let data = response.data;
					this.alerta.setActive(true, data.code);
					this.alertOut(this.alerta);
					let pagActiva = this.paginadorGrillaCategorias.activePage;
					if (this.paginadorGrillaCategorias.data.length == 1 && pagActiva > 1) this.paginadorGrillaCategorias.activePage--;
					this.paginadorGrillaCategorias.loadData(this.paginadorGrillaCategorias.activePage);
				},
				err => {
					this.alerta.setActive(true, err.error.code);
					this.alertOut(this.alerta);
				}
			);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	onSearch(event) {
		try {
			let $this = this;
			clearTimeout($this.timeout);
			$this.timeout = setTimeout(function () {
				$this.buscarCategorias = event.target.value;
				$this.paginadorGrillaCategorias.activePage = 1;
				$this.paginadorGrillaCategorias.loadData($this.paginadorGrillaCategorias.activePage, $this.buscarCategorias, $this.ordenarCategoria);
			}, 500);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	onOrder() {
		try {
			this.paginadorGrillaCategorias.loadData(this.paginadorGrillaCategorias.activePage, this.buscarCategorias, this.ordenarCategoria);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	onClearFilter() {
		try {
			this.buscarCategorias = '';
			this.ordenarCategoria = 'A-Z';
			this.paginadorGrillaCategorias.activePage = 1;
			this.paginadorGrillaCategorias.loadData(this.paginadorGrillaCategorias.activePage, this.buscarCategorias, this.ordenarCategoria);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	upDateDataGrid(event): void {
		this.loadCategorias();
	}

	onExport(type) {
		try {
			this.wait_export = true;
			this._categoriasService.export(type, this.buscarCategorias, this.ordenarCategoria).subscribe(
				response => {
					var a = document.createElement("a");
					a.href = response.file;
					a.download = response.name;
					document.body.appendChild(a);
					a.click();
					a.remove();
					this.wait_export = false;
				},
				err => {
					this.wait_export = false;
					this.alerta.setActive(true, err.error.code);
					this.alertOut(this.alerta);
				}
			);
		} catch (err) {
			this.wait_export = false;
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	cancelarEliminar() {
		this.activePopup = false;
	}

	aceptoEliminar() {
		this.activePopup = false;
		this.onDelete(this.idEliminar);
	}
}