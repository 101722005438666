import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Alerta } from '../../utilidades/alerta.component';
import { Paginador } from '../../utilidades/paginador.component';
import { CreditoModel } from '../../../models/credito.model';
import { ProductoscreditoModel } from '../../../models/productoscredito.model';
import { MycurrencyPipe } from '../../../pipe/mycurrency.pipe';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'; 

import { CreditoDetalleServices } from '../../../services/creditodetalle.services';
import { ProductosServices } from '../../../services/productos.services';
import { CreditoServices } from '../../../services/credito.services';

//UTILIDADES
import { Modal } from '../../utilidades/modal.component';

import { AppDateAdapter, APP_DATE_FORMATS } from '../../../app-material/format-datepicker';


@Component({
  	selector: 'app-creditodetalle',
  	templateUrl: './creditodetalle.component.html',
  	styleUrls: ['./creditodetalle.component.scss'],
	providers: [
		CreditoDetalleServices, MycurrencyPipe, ProductosServices, CreditoServices,
		{ provide: DateAdapter, useClass: AppDateAdapter },
		{ provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
		{ provide: MAT_DATE_LOCALE, useValue: 'es-ES' }
	]
})
export class CreditodetalleComponent implements OnInit {
	public openFormCreditoDet: boolean = false;
	public alertaCreditosDetalle: Alerta;
	public alertaProductoCreditos: Alerta;
	public frmnuevocredito: FormGroup;
	public creditoDetalleModelo: CreditoModel;
	public productosCredito: ProductoscreditoModel;
	
	public idCredito: number = 0;
	public paginadoProductoCredito: any = '';
	public modalProductosCredito: any = '';
	public modalChildVisible = false; 
	public productoCredito: string = '';
	public searchProd: string = '';
	public frmagregarproducto: FormGroup;

	public listProducts = false;
	public loadingProducts = false;
	public productosCreditos = [];
	public productoAE = [];
	public idCliente:number = 0;

	
	private alertOut($ele, timer = 3000) {
		setTimeout(function () {
			$ele.setActive(false);
		}, timer);
	}

	private formartNum(num) {
		var result = num.toString().replace(/,/g, "");
		result = result.replace("$", "");
		return result;
	}

	private validarDecuento(e, text) {
		var regex_numeros = /^[0-9]+$/;

		if (text == '') return true;

		if (text.indexOf('$') != 0 && text.indexOf('$') != -1 && text.split("$").length > 1) return false;

		if (text.indexOf('%') != -1 && text.indexOf('%') != text.length - 1 && text.split("%").length > 1) return false;

		var num = text.replace('%', '');
		num = num.replace('$', '');
		num = num.replace(' ', '');

		if (!(regex_numeros).exec(num) && ((text.indexOf('$') != -1 && text.indexOf('$') != 0) || (text.indexOf('%') != -1 && text.indexOf('%') != 0)) && e.key != 'Shift') return false;

		if (text.indexOf('$') != -1 && text.indexOf('%') != -1) return false;

		if (!(regex_numeros).exec(num)) return false;

		return true;

	}

	@Output() modalChild = new EventEmitter();
	@Output() updateData = new EventEmitter();
	@ViewChild('btnAgregarProducto') btnAgregarProducto: ElementRef;

  	constructor(
			private _creditoDetalleService: CreditoDetalleServices,
			private _productosServices: ProductosServices,
			private _creditoService: CreditoServices,
			private formatcurrencypipe: MycurrencyPipe,
			private fb: FormBuilder,
			private renderer: Renderer2
	  ) { 
		this.alertaCreditosDetalle = new Alerta('alerta_creditosdetalle');
		this.alertaProductoCreditos = new Alerta('alerta_productoscreditos');
		this.creditoDetalleModelo = new CreditoModel(0, 0, new Date(), '', '');
		this.productosCredito = new ProductoscreditoModel(0, 0, 0, 0, '', '', 1, this.formartPrecio(0), '0');
		this.modalProductosCredito = new Modal(false, 'modal_close_credito');
	}

	ngOnInit() {
		this.frmnuevocredito = new FormGroup({
			fecha_concepto: new FormControl({ value: '', disabled: true }, Validators.required),
			concepto: new FormControl(''),
		});

		this.frmagregarproducto = this.fb.group({
			id_prod: [0],
			codigo_prod: [''],
			concepto_prod: ['', Validators.required],
			cantidad_prod: [0, [Validators.required, Validators.pattern(/^[1-9][0-9]*$/)]],
			precio_prod: [0, [Validators.required]],
			descuento_prod: ['']
		});
	}

	getAllCreditoDetalle(id) {
		try {
			let param = 'idconcepto=' + id;
			this.paginadoProductoCredito = new Paginador(this._creditoDetalleService, this.alertaCreditosDetalle, '', '', param);
		} catch(err) {
			this.alertaCreditosDetalle.setActive(true, 'danger');
			this.alertOut(this.alertaCreditosDetalle);	
		}
	}

  	openAddCreditoDetalle(obj) {
		this.openFormCreditoDet = true;			
		this.idCredito = obj.id;
		this.idCliente = obj.idCliente;
		this.creditoDetalleModelo = new CreditoModel(0, 0, new Date(), '', '');		
  	}

  	openEditCreditoDetalle(obj) {
		this.openFormCreditoDet = true;		
		this.idCredito = obj.id;	
		this.idCliente = obj.idCliente;	
		let newfecha = new Date(obj.fecha);
		let fecha = (newfecha.getMonth() + 1) + '/' + newfecha.getDate() + '/' + newfecha.getFullYear();
		this.creditoDetalleModelo = new CreditoModel(0, 0, new Date(fecha), obj.descripcion, '');
		this.getAllCreditoDetalle(obj.id);		
	}
				
	onEditProduct(id_prod, codigo, idConcepto, idCompra, concepto, precio, descuento, cantidad) {
		this.productosCredito.id_producto = id_prod;
		this.productosCredito.idconcepto = idConcepto;
		this.productosCredito.codigo = codigo;
		this.productosCredito.concepto = concepto;
		this.productosCredito.cantidad = cantidad;
		this.productosCredito.precio = this.formatcurrencypipe.transform(precio);
		this.productosCredito.descuento = descuento;
		this.productosCredito.idcompra = idCompra;
		this.searchProd = codigo;
		this.productoCredito = 'editar';
		this.modalProductosCredito.openModal();		

		let inputCodigo = this.frmagregarproducto.get('codigo_prod');
		inputCodigo.disable();

		//let inputConcepto = this.frmagregarproducto.get('concepto_prod');
		//inputConcepto.disable();

		//let inputPrecio = this.frmagregarproducto.get('precio_prod');
		//inputPrecio.disable();

	}
	  
	onAddProduct() {
		this.productoCredito = 'nuevo';
		this.modalProductosCredito.openModal();	
		this.onClearFormCredito();	

		let inputCodigo = this.frmagregarproducto.get('codigo_prod');
		inputCodigo.enable();

		//let inputConcepto = this.frmagregarproducto.get('concepto_prod');
		//inputConcepto.enable();

		//let inputPrecio = this.frmagregarproducto.get('precio_prod');
		//inputPrecio.enable();
	}

	closeModalProduct() {
		this.productoCredito = '';
		//this.modalProductosCredito = false;
	}

	closeModal() {
		this.modalChild.emit(false);
		this.openFormCreditoDet = false;	
		this.onClearForm();
	}

	onClearForm() {
		this.creditoDetalleModelo = new CreditoModel(0, 0, new Date(), '', '');
		this.idCredito = 0;
		this.paginadoProductoCredito = '';
	}

	onDelete(id) {
		try { 
			this._creditoDetalleService.delete(id).subscribe(
				response => {
					let data = response.data;
					this.alertaCreditosDetalle.setActive(true, data.code);
					this.alertOut(this.alertaCreditosDetalle);
					let pagActiva = this.paginadoProductoCredito.activePage;
					if (this.paginadoProductoCredito.data.length == 1 && pagActiva > 1) this.paginadoProductoCredito.activePage--;
					let param = 'idconcepto=' + data[0].idconcepto;
					this.paginadoProductoCredito.loadData(this.paginadoProductoCredito.activePage, '', '', param);
					if (!data[0].totalItems) {
						this.modalChild.emit(false);
						this.openFormCreditoDet = false;
					}
					this.updateData.emit({ 'reload': true, 'totalCredito': data[0].totalCredito, 'idCliente': data[0].idcliente});
				},
				err => {
					this.alertaCreditosDetalle.setActive(true, err.error.code);
					this.alertOut(this.alertaCreditosDetalle);
				}
			);
		} catch (err) {
			this.alertaCreditosDetalle.setActive(true, 'danger');
			this.alertOut(this.alertaCreditosDetalle);
		}
	}
	
	formartPrecio(precio) {
		return this.formatcurrencypipe.transform(this.formartNum(precio));
	}

	searchProduct(event) {
		try {
			if (this.searchProd != '') {
				this.listProducts = true;
				this.loadingProducts = true;
				this.productosCreditos = [];
				this._productosServices.find(this.searchProd).subscribe(
					response => {						
						this.loadingProducts = false;
						this.productosCreditos = response.data;
					},
					err => {
						this.loadingProducts = false;
						this.alertaProductoCreditos.setActive(true, 'danger');
						this.alertOut(this.alertaProductoCreditos);
					}
				);
			} else {
				this.listProducts = false;
				this.loadingProducts = false;
				this.productosCreditos = [];
			}
		} catch (err) {
			this.listProducts = false;
			this.loadingProducts = false;
			this.productosCreditos = [];
			this.alertaProductoCreditos.setActive(true, 'danger');
			this.alertOut(this.alertaProductoCreditos);
		}
	}

	agregarProducto(id_producto, id_costo, codigo, nombre, precio) {
		this.productosCredito.id_producto = id_producto;
		this.productosCredito.id_costo = id_costo;
		this.productosCredito.codigo = codigo;
		this.productosCredito.concepto = nombre;
		this.productosCredito.precio = this.formatcurrencypipe.transform(precio);
		this.searchProd = codigo;
		this.productosCreditos = [];
		this.loadingProducts = false;
		//let inputPrecio_prod = this.frmagregarproducto.get('precio_prod');
		//let inputConcepto_prod = this.frmagregarproducto.get('concepto_prod');
		//inputPrecio_prod.disable();
		//inputConcepto_prod.disable();
	}

	onClearFormCredito() {
		this.productosCredito = new ProductoscreditoModel(0, 0, 0, 0, '', '', 1, this.formartPrecio(0), '0');
		this.searchProd = '';
		//let inputPrecio_prod = this.frmagregarproducto.get('precio_prod');
		//let inputConcepto_prod = this.frmagregarproducto.get('concepto_prod');
		//inputPrecio_prod.enable();
		//inputConcepto_prod.enable();
	}	

	soloNumeros(e) {
		let control = this.frmagregarproducto.get('descuento_prod');
		let isNumber = this.validarDecuento(e, control.value);
		if (!isNumber) {
			this.renderer.setAttribute(this.btnAgregarProducto.nativeElement, "disabled", "true");
		} else {
			this.renderer.removeAttribute(this.btnAgregarProducto.nativeElement, "disabled");
		}
	}

	saveProduct() {		
		try {			
			let precio = this.formatcurrencypipe.parsePriceToNumber(this.productosCredito.precio);
			if (this.productoCredito == 'nuevo') {
				let producto = {
					'cantidad': this.productosCredito.cantidad,
					'codigo': this.productosCredito.codigo,
					'concepto': this.productosCredito.concepto,
					'descuento': this.productosCredito.descuento == '' ? 0 : this.productosCredito.descuento,
					'id_producto': this.productosCredito.id_producto,
					'id_costo': this.productosCredito.id_costo,
					'idCredito': this.idCredito,
					'conceptoCredito': this.creditoDetalleModelo.concepto,
					'precio': precio,
					'idCliente': this.idCliente
				};
				this._creditoDetalleService.add(producto).subscribe(
					response => {						
						this.alertaCreditosDetalle.setActive(true, response.data.code);
						this.alertOut(this.alertaCreditosDetalle);
						this.closeModalProduct();
						this.getAllCreditoDetalle(this.idCredito);
						this.updateData.emit({ 'reload': true, 'totalCredito': response.data[0].totalCredito, 'idCliente': response.data[0].idcliente });
					},
					err => {
						this.alertaProductoCreditos.setActive(true, err.error.code, err.error.message);
						this.alertOut(this.alertaProductoCreditos);
					}
				);
			} else if (this.productoCredito == 'editar') {	
				let producto = {
					'cantidad': this.productosCredito.cantidad,			
					'descuento': this.productosCredito.descuento == '' ? 0 : this.productosCredito.descuento,
					'id_producto': this.productosCredito.id_producto,
					'id_costo': this.productosCredito.id_costo,
					'id_credito': this.idCredito,
					'id_compra': this.productosCredito.idcompra,
					'id_cliente': this.idCliente
				};			
				this._creditoDetalleService.update(this.productosCredito.id_producto, producto).subscribe(
					response => {						
						this.alertaCreditosDetalle.setActive(true, response.data.code);
						this.alertOut(this.alertaCreditosDetalle);
						this.closeModalProduct();
						this.getAllCreditoDetalle(this.idCredito);
						this.updateData.emit({ 'reload': true, 'totalCredito': response.data[0].totalCredito, 'idCliente': response.data[0].idcliente });
					},
					err => {
						this.alertaProductoCreditos.setActive(true, err.error.code, err.error.message);
						this.alertOut(this.alertaProductoCreditos);
					}
				);
			}
			
		} catch (err) {
			this.alertaProductoCreditos.setActive(true, 'danger');
			this.alertOut(this.alertaProductoCreditos);
		}
	}

	upDateCredito() {
		try {
			let datosConcept = {				
				'concepto': this.creditoDetalleModelo.concepto
			};

			this._creditoService.update(this.idCredito, datosConcept).subscribe(
				response => {
					this.alertaCreditosDetalle.setActive(true, response.data.code);
					this.alertOut(this.alertaCreditosDetalle);
					this.updateData.emit({ 'reload': true, 'totalCredito': response.data[0].totalCredito, 'idCliente': response.data[0].id_cliente});					
				},
				err => {
					this.alertaCreditosDetalle.setActive(true, 'danger');
					this.alertOut(this.alertaCreditosDetalle);
				}
			);
		}  catch(err) {
			this.alertaCreditosDetalle.setActive(true, 'danger');
			this.alertOut(this.alertaCreditosDetalle);
		}
	}

	addProduct () {
		try {
			let precio = this.formatcurrencypipe.parsePriceToNumber(this.productosCredito.precio);
			let credito = {
				'cantidad': this.productosCredito.cantidad,
				'codigo': this.productosCredito.codigo,
				'concepto': this.productosCredito.concepto,
				'descuento': this.productosCredito.descuento == '' ? 0 : this.productosCredito.descuento,
				'id_producto': this.productosCredito.id_producto,
				'id_costo': this.productosCredito.id_costo,
				'idCredito': 0,
				'conceptoCredito': this.creditoDetalleModelo.concepto,
				'precio': precio,
				'idCliente': this.idCliente
			};

			this._creditoDetalleService.addCredito(credito).subscribe(
				response => {
					this.alertaCreditosDetalle.setActive(true, response.data.code);
					this.alertOut(this.alertaCreditosDetalle);
					this.closeModalProduct();
					this.getAllCreditoDetalle(response.data[0].idconcepto);					
					this.updateData.emit({ 'reload': true, 'totalCredito': response.data[0].totalCredito, 'idCliente': response.data[0].idcliente });
					this.idCredito = response.data[0].idconcepto;				
				},
				err => {
					this.alertaProductoCreditos.setActive(true, err.error.code, err.error.message);
					this.alertOut(this.alertaProductoCreditos);
				}
			);

		} catch (err) {
			this.alertaProductoCreditos.setActive(true, 'danger');
			this.alertOut(this.alertaProductoCreditos);
		}			
	}

}
