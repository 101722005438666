import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-editar',
  templateUrl: './editar.component.html',
  styleUrls: ['./editar.component.scss']
})
export class EditarProductoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
