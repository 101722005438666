import { Component } from '@angular/core';
import { SlideInOutAnimation } from '../animations';
import { AuthService } from '../components/auth/auth.service';
@Component({
  selector: 'app-home',
  templateUrl: './dashboard-layout.component.html',
  animations: [SlideInOutAnimation],
  host: {
    '(window:resize)': 'onResize($event)'
  },
  /* template: `
     <router-outlet></router-outlet>
   `,*/
  styles: []
})
export class DashboardLayoutComponent {

  title = 'app';
  animationState = 'out';
  public winHeight: number;
  public swMnu: boolean = true;
  public mnuStatus: boolean = false;
  public swOpenMenus: boolean = false;
  constructor(
    private authService: AuthService
  ) { }

  toggleShowDiv(divName: string) {
    if (divName === 'divA') {
      this.animationState = this.animationState === 'out' ? 'in' : 'out';
    }
  }

  onResize(event) {
    this.winHeight = event.target.innerHeight;
    if (event.target.innerWidth > 767 && this.animationState == 'out') {
      this.animationState = 'in';
    } else if (window.innerWidth <= 767 && this.animationState == 'in') {
      this.animationState = 'out';
    }
  }
  ngOnInit() {
    this.winHeight = window.innerHeight;
    if (window.innerWidth > 767 && this.animationState == 'out') {
      this.animationState = 'in';
    } else if (window.innerWidth <= 767 && this.animationState == 'in') {
      this.animationState = 'out';
    }
  }
  cerrarMenus() {
    if (window.innerWidth <= 767) {
      this.animationState = 'out';
    }
  }

  cerrarSession() {
    this.authService.logout();
  }

  onMenus() {    
    this.firtOpenMenu();
    if (this.mnuStatus) {
      this.mnuStatus = false;
    } else {
      this.mnuStatus = true;
    }
  }

  closeSideNav() {    
    if (this.mnuStatus && !this.swOpenMenus) {
      this.mnuStatus = false;
    }  
    this.swOpenMenus = false;
  }

  firtOpenMenu() {
    this.swOpenMenus = true;   
  }

}