export class ProveedorContactoModel {
    constructor(
        public idProveedorContacto: number,
        public nombres_contacto: string,
        public apellidos_contacto: string,        
        public telefono_contacto: string,
        public celular_contacto: string,
        public email_contacto: string,
        public direccion_contacto: string,
        public codigo: string,
        public fecha: string
    ) { }
}