import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';

//UTILIDADES
import { Alerta } from '../utilidades/alerta.component';
import { Paginador } from '../utilidades/paginador.component';
import { Modal } from '../utilidades/modal.component';
import { MycurrencyPipe } from '../../pipe/mycurrency.pipe';

//MODELS

//SERVICES
import { CreditoServices } from '../../services/credito.services';

//COMPONENTS
import { CreditodetalleComponent } from './creditodetalle/creditodetalle.component';
import { PagosComponent } from '../pagos/pagos.component';

@Component({
	selector: 'app-creditos',
	templateUrl: './creditos.component.html',
	styleUrls: ['./creditos.component.scss'],
	providers: [
		CreditoServices, MycurrencyPipe
	]
})
export class CreditosComponent implements OnInit {

	public idCliente: number = 0;
	public alertaCreditos: Alerta;
	public paginadorGCreditos: any = ''; //Variable que carga el paginador de la grilla de clientes
	public dataCreditos = [];
	public buscarConceptos: string = '';
	public ordenarCredito: string = 'mayor_monto';
	public ordenarConcepto: string = 'todos';
	public modalChilds: boolean = true;
	public disableControl: boolean = true;
	public wait_export: boolean = false;
	public timeout = null;

	@ViewChild(CreditodetalleComponent) creditoDetalle: CreditodetalleComponent; //Componente hijo
	@ViewChild(PagosComponent) pagoDetalle : PagosComponent; //Componente hijo

	@Input() _idCliente: number;
	@Input('_idCliente') set changeIdCliente(id: number) {		
		if (id) {
			this.idCliente = id;
			this.allCreditos();
			this.getCantCreditos();
		} else {
			this.idCliente = 0;
		}
	}

	@Output() modalChild = new EventEmitter();
	@Output() updateData = new EventEmitter();

	
	constructor(
		private _creditoServices: CreditoServices,
		private formatcurrencypipe: MycurrencyPipe,
		//private compClienteDetalle: ClientedetalleComponent
	) { 
		this.alertaCreditos = new Alerta('alerta_creditos');
	}

	private alertOut($ele, timer = 2000) {
		setTimeout(function () {
			$ele.setActive(false);
		}, timer);
	}

	private getCantCreditos() {
		try {
			this._creditoServices.getByPage(1, 10, '', 'asc', 'idCliente=' + this.idCliente).subscribe(response => {
				if (response.totalItems > 0) {
					this.disableControl = false;
				} else {
					this.disableControl = true;
				}
			},
			err => {
				this.alertaCreditos.setActive(true, err.error.code);
				this.alertOut(this.alertaCreditos);
			})
		} catch (err) {
			this.alertaCreditos.setActive(true, 'danger');
			this.alertOut(this.alertaCreditos);
		}
	}

	ngOnInit() {
	}

	allCreditos() {
		try {
			let param = 'idCliente=' + this.idCliente; 
			this.paginadorGCreditos = new Paginador(this._creditoServices, this.alertaCreditos, '', '', param);				
		} catch (err) {
			this.alertaCreditos.setActive(true, 'danger');
			this.alertOut(this.alertaCreditos);
		}
	}

	onDelete(id) {
		try {
			this._creditoServices.delete(id).subscribe(
				response => {
					let data = response.data;
					let pagActiva = this.paginadorGCreditos.activePage;
					let param = 'idCliente=' + this.idCliente;
					this.alertaCreditos.setActive(true, data.code);
					this.alertOut(this.alertaCreditos);					
					if (this.paginadorGCreditos.data.length == 1 && pagActiva > 1) this.paginadorGCreditos.activePage--;
					this.paginadorGCreditos.loadData(this.paginadorGCreditos.activePage, '', '', param);
					this.updateData.emit({ 'reload': true, 'totalCredito': response.totalCredito })
				},
				err => {
					this.alertaCreditos.setActive(true, err.error.code);
					this.alertOut(this.alertaCreditos);
				}
			);
		} catch (err) {
			this.alertaCreditos.setActive(true, 'danger');
			this.alertOut(this.alertaCreditos);
		}
	}

	onSearch(event) {
		try {
			this.buscarConceptos = event.target.value;
			this.paginadorGCreditos.activePage = 1;
			let param = 'idCliente=' + this.idCliente; 
			this.paginadorGCreditos.loadData(this.paginadorGCreditos.activePage, this.buscarConceptos, this.ordenarCredito, param);
		} catch (err) {
			this.alertaCreditos.setActive(true, 'danger');
			this.alertOut(this.alertaCreditos);
		}
	}

	onOrder() {
		try {
			let param = 'idCliente=' + this.idCliente; 
			this.paginadorGCreditos.loadData(this.paginadorGCreditos.activePage, this.buscarConceptos, this.ordenarCredito, param);
		} catch (err) {
			this.alertaCreditos.setActive(true, 'danger');
			this.alertOut(this.alertaCreditos);
		}
	}

	onOrderConcepto() {
		try {
			let param = 'concepto=' + this.ordenarConcepto;
			param += '&idCliente=' + this.idCliente; 
			this.paginadorGCreditos.loadData(this.paginadorGCreditos.activePage, this.buscarConceptos, this.ordenarCredito, param);
		} catch (err) {
			this.alertaCreditos.setActive(true, 'danger');
			this.alertOut(this.alertaCreditos);
		}
	}

	onExport(type) {
		try {
			this.wait_export = true;
			this._creditoServices.export(type, this.buscarConceptos, this.ordenarCredito, this.ordenarConcepto, this.idCliente).subscribe(
				response => {
					var a = document.createElement("a");
					a.href = response.file;
					a.download = response.name;
					document.body.appendChild(a);
					a.click();
					a.remove();
					this.wait_export = false;
				},
				err => {
					this.wait_export = false;
					this.alertaCreditos.setActive(true, err.error.code);
					this.alertOut(this.alertaCreditos);
				}
			);
		} catch (err) {
			this.wait_export = false;
			this.alertaCreditos.setActive(true, 'danger');
			this.alertOut(this.alertaCreditos);
		}
	}
	
	onClearFilter() {
		try {
			this.buscarConceptos = '';
			this.ordenarCredito = 'mayor_monto';
			this.paginadorGCreditos.activePage = 1;
			let param = 'idCliente=' + this.idCliente; 
			this.paginadorGCreditos.loadData(this.paginadorGCreditos.activePage, this.buscarConceptos, this.ordenarCredito, param);
		} catch (err) {
			this.alertaCreditos.setActive(true, 'danger');
			this.alertOut(this.alertaCreditos);
		}
	}

	onAddCredito(id, idCliente) {		
		this.creditoDetalle.openAddCreditoDetalle({ 'id': id, 'idCliente': idCliente});
		this.modalChild.emit(true);
	}

	onEditCredito(id, idCliente, descripcion, fecha) {
		this.creditoDetalle.openEditCreditoDetalle({ 'id': id, 'descripcion': descripcion, 'fecha': fecha, 'idCliente': idCliente});	
		this.modalChild.emit(true);	
		this.modalChilds = true;
	}

	onPay(id, idcliente, cancelado, costoInicial, descuento) {		
		cancelado = cancelado && cancelado > 0 ? cancelado : 0;
		let monto = costoInicial - descuento - cancelado;
		this.pagoDetalle.openEditPagoDetalle({ 'idCredito': id, 'idCliente': idcliente, 'monto': monto, 'costoInicial': costoInicial});
		this.modalChild.emit(true);
	}

	closeModal(event): void {
		this.modalChild.emit(event);
		this.modalChilds = false;	
	}
	
	reloadData(event): void {
		if(event.reload) {
			let param = 'idCliente=' + event.idCliente; 
			this.paginadorGCreditos.loadData(this.paginadorGCreditos.activePage, this.buscarConceptos, this.ordenarCredito, param);
			this.updateData.emit({ 'reload': true, 'totalCredito': event.totalCredito });
		}
	}
	
}
