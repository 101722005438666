import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validator} from '@angular/forms';

//UTILIDADES
import { Alerta } from '../../utilidades/alerta.component';
import { Loading } from '../../utilidades/loading.component';
import { Modal } from '../../utilidades/modal.component';

//MODELS
import { MarcaModel } from '../../../models/marca.model';

//SERVICES
import { MarcasServices } from '../../../services/marcas.services';

@Component({
	selector: 'app-marcasdetalle',
	templateUrl: './marcasdetalle.component.html',
	styleUrls: ['./marcasdetalle.component.scss'],
	providers: [
		MarcasServices
	]
})
export class MarcasdetalleComponent implements OnInit {
	//MODELS
	public marca: MarcaModel;

	//VARIABLES
	public alertDetMarca: Alerta;
	public loadingDet: any;
	public modalMarcaDetalle: any = '';
	public idMarca: number = 0;
	public modalChildVisible = false;
	//FORM
	public formMarca: FormGroup;

	@Output() updateData = new EventEmitter();
	@Input() _idMarca: number;
	@Input('_idMarca') set changeIdMarca(id: number) {
		this.loadingDet.setLoading(true);		
		if (id) {
			this.resetModels();
			this.idMarca = id;
			this.onEdit();			
		} else {
			this.resetModels();
			this.loadingDet.setLoading(false);			
		}
	}

	constructor(
		private _marcaService: MarcasServices,
		private fb: FormBuilder,
	) { 
		this.alertDetMarca = new Alerta('alert_det_marca');
		this.loadingDet = new Loading();
		this.modalMarcaDetalle = new Modal();

		this.formMarca = this.fb.group({
			nombre: ['']			
		});
	}

	private alertOut($ele, timer = 2000) {
		setTimeout(function () {
			$ele.setActive(false);
		}, timer);
	}

	ngOnInit() {
	}

	openModalEditMarcaDetalle() {
		this.modalMarcaDetalle.openModal();		
	}

	resetModels() {
		this.marca = new MarcaModel(0, '');
		this.idMarca = 0;
	}

	onAdd() {
		this.resetModels();
	}

	onEdit() {
		try {			
			this._marcaService.getMarca(this.idMarca).subscribe(
				response => {					
					let data = response.data[0];
					this.marca = new MarcaModel(data.idmarca, data.nombre);					
					this.loadingDet.setLoading(false);					
				},
				err => {
					this.alertDetMarca.setActive(true, err.error.code);
					this.alertOut(this.alertDetMarca);
				}
			);
		} catch (err) {
			this.alertDetMarca.setActive(true, 'danger');
			this.alertOut(this.alertDetMarca);
		}
	}

	onSave() {
		try {
			if (!this.idMarca) { //NUEVO
				this._marcaService.add(this.formMarca.value).subscribe(
					response => {
						this.alertDetMarca.setActive(true, 'success');
						this.alertOut(this.alertDetMarca);
						this.idMarca = response.data.idmarca;						
						this.updateData.emit({ 'reload': true, 'idMarca': this.idMarca });
					},
					err => {
						this.alertDetMarca.setActive(true, err.error.code);
						this.alertOut(this.alertDetMarca);
					}
				);
			} else {
				this._marcaService.update(this.marca.idMarca, this.marca).subscribe(
					response => {
						this.alertDetMarca.setActive(true, 'update');
						this.alertOut(this.alertDetMarca);
						var cel_1 = document.getElementsByClassName("marca_" + this.marca.idMarca).item(0).querySelector('.cel_1');
						cel_1.innerHTML = response.data.nombre;						
					},
					err => {
						this.alertDetMarca.setActive(true, err.error.code);
						this.alertOut(this.alertDetMarca);
					}
				);
			}
		} catch (err) {
			this.alertDetMarca.setActive(true, 'danger');
			this.alertOut(this.alertDetMarca);
		}		
	}

}
