import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

//UTILIDADES
import { Alerta } from '../../utilidades/alerta.component';
import { Loading } from '../../utilidades/loading.component';
import { Modal } from '../../utilidades/modal.component';
import { validationInput } from '../../utilidades/validation.models';

//MODELS
import { ProveedorContactoModel } from '../../../models/proveedorcontacto.model';

//SERVICES
import { ProveedorescontactoServices } from '../../../services/Proveedorescontacto.services';


@Component({
	selector: 'app-proveedorcontactodetalle',
	templateUrl: './proveedorcontactodetalle.component.html',
	styleUrls: ['./proveedorcontactodetalle.component.scss'],
	providers: [
		ProveedorescontactoServices
	]
})
export class ProveedorcontactodetalleComponent implements OnInit {
	
	//MODELS
	public proveedorContacto: ProveedorContactoModel;

	//VARIABLES
	public alertDetProveedorContacto: Alerta;
	public loadingDet: any;
	public openForm: boolean = false;
	public idProveedorContacto: number = 0;
	public idEmpresa: number = 0;
	public validationInputs: any = '';
	public timeout = null;
	public disableBtnSave: boolean = false;
	public codigo: any = '';
	public email: any = '';

	//FORM
	public formProveedorContacto: FormGroup;

	@Output() updateData = new EventEmitter();
	@Output() closeForm = new EventEmitter();
	@Input() _idProveedorContacto: number;
	@Input('_idProveedorContacto') set changeIdProveedor(id: number) {		
		this.idProveedorContacto = id;		
		if (id > 0) {
			this.loadingDet.setLoading(true);
			this.resetModels();
			this.onEdit(id);
		} else if (id == 0) {
			this.resetModels();
			this.loadingDet.setLoading(false);
		}
	}

	@Input('_ContactoNuevo') set ContactoNuevo(id: number) {
		if(id > 0) {
			this.idEmpresa = id;
		}		
	}

	constructor(
		private _proveedorContactoService: ProveedorescontactoServices,
		private fb: FormBuilder,
	) { 
		this.alertDetProveedorContacto = new Alerta('alert_det_proveedor');
		this.loadingDet = new Loading();	
		this.resetModels();		
	}

	private alertOut($ele, timer = 2000) {
		setTimeout(function () {
			$ele.setActive(false);
		}, timer);
	}

	ngOnInit() {
		this.validationInputs = new validationInput();
		this.formProveedorContacto = new FormGroup({
			nombres_contacto: new FormControl({ value:'' }, Validators.required),
			apellidos_contacto: new FormControl({ value: '' }, Validators.required),			
			telefono_contacto: new FormControl({ value: '' }),
			celular_contacto: new FormControl({ value:''}),
			email_contacto: new FormControl({ value: '' }, Validators.compose([
				Validators.pattern(this.validationInputs.email.pattern)
			])),
			direccion_contacto: new FormControl({ value: '' }),
			codigo: new FormControl({ value: '' }),
			id_proveedor: new FormControl({ value: '' })
		});
	}

	addContacto() {
		this.idProveedorContacto = 0;
		this.resetModels();			
		this.openForm = true;
		let inputCodigo = this.formProveedorContacto.get('codigo');
		inputCodigo.enable();
	}

	onEdit(id) {
		try {
			this.loadingDet.setLoading(true);
			this.idProveedorContacto = id;
			this._proveedorContactoService.getProveedorContacto(id).subscribe(
				response => {
					var data = response.data[0];					
					this.proveedorContacto = new ProveedorContactoModel(
						id, 
						data.nombres_contacto, 
						data.apellidos_contacto, 						
						data.telefono_contacto, 
						data.celular_contacto,
						data.email_contacto,
						data.direccion_contacto,
						data.codigo,
						data.fecha
					);
					this.codigo = data.codigo;
					this.email = data.email_contacto;	
					this.loadingDet.setLoading(false);
					let inputCodigo = this.formProveedorContacto.get('codigo');
					inputCodigo.disable();
				},
				err => {
					this.alertDetProveedorContacto.setActive(true, err.error.code);
					this.alertOut(this.alertDetProveedorContacto);
				}
			);
		} catch (err) {
			this.alertDetProveedorContacto.setActive(true, 'danger');
			this.alertOut(this.alertDetProveedorContacto);
		}
	}

	onCloseForm() {
		this.openForm = false; 
		this.closeForm.emit({'modal': false});
	}

	resetModels() {
		this.codigo ='';
		this.email = '';
		this.proveedorContacto = new ProveedorContactoModel(null, '', '', '', '', '', '', '', '');
	}

	onAdd() {
		this.idProveedorContacto = 0;
		this.resetModels();
	}

	onSave() {
		try {
			if (this.idProveedorContacto <= 0) { //NUEVO
				this._proveedorContactoService.add(this.idEmpresa, this.formProveedorContacto.value).subscribe(
					response => {
						this.alertDetProveedorContacto.setActive(true, 'success');
						this.alertOut(this.alertDetProveedorContacto);
						this.idProveedorContacto = response.data.id_contacto;
						this.updateData.emit({ 'reload': true });
					},
					err => {
						this.alertDetProveedorContacto.setActive(true, err.error.code);
						this.alertOut(this.alertDetProveedorContacto);
					}
				);
			} else {
				this._proveedorContactoService.update(this.idProveedorContacto, this.proveedorContacto).subscribe(
					response => {
						this.alertDetProveedorContacto.setActive(true, 'update');
						this.alertOut(this.alertDetProveedorContacto);						
						var cel_2 = document.getElementsByClassName("proveedor_cont_" + this.proveedorContacto.idProveedorContacto).item(0).querySelector('.cel_2');
						var cel_3 = document.getElementsByClassName("proveedor_cont_" + this.proveedorContacto.idProveedorContacto).item(0).querySelector('.cel_3');
						var cel_4 = document.getElementsByClassName("proveedor_cont_" + this.proveedorContacto.idProveedorContacto).item(0).querySelector('.cel_4');
						var cel_5 = document.getElementsByClassName("proveedor_cont_" + this.proveedorContacto.idProveedorContacto).item(0).querySelector('.cel_5');						
						cel_2.innerHTML = response.data.nombres_contacto + ' ' + response.data.apellidos_contacto;
						cel_3.innerHTML = response.data.email_contacto; 
						cel_4.innerHTML = response.data.telefono_contacto;
						cel_5.innerHTML = response.data.celular_contacto;						
					},
					err => {
						this.alertDetProveedorContacto.setActive(true, err.error.code);
						this.alertOut(this.alertDetProveedorContacto);
					}
				);
			}
		} catch (err) {
			this.alertDetProveedorContacto.setActive(true, 'danger');
			this.alertOut(this.alertDetProveedorContacto);
		}
	}

	isValidCode(event) {
		let $this = this;
		clearTimeout($this.timeout);
		$this.timeout = setTimeout(function () {
			let value = event.target.value;
			$this.validData(this.codigo, value, 'search_codigo', 'El código ya se encuentra registrado');
		}, 1000);
	}

	isValidMail(event) {
		let $this = this;
		clearTimeout($this.timeout);
		$this.timeout = setTimeout(function () {
			let value = event.target.value;
			$this.validData(this.codigo, value, 'search_email', 'El email ya se encuentra registrado');
		}, 1000);
	}

	validData(valueActual, nuevoValue, campo, msgError) {
		try {
			this.disableBtnSave = true;
			if (valueActual != nuevoValue && nuevoValue != '') {
				this._proveedorContactoService.search(nuevoValue, 'asc', campo).subscribe(response => {
					if (response.totalItems > 0) {
						this.alertDetProveedorContacto.setActive(true, 'danger', msgError);
						this.alertOut(this.alertDetProveedorContacto);
						this.disableBtnSave = true;
					} else {
						this.disableBtnSave = false;
					}
				},
					err => {
						this.alertDetProveedorContacto.setActive(true, err.error.code);
						this.alertOut(this.alertDetProveedorContacto);
					})
			} else {
				this.disableBtnSave = false;
			}
		} catch (err) {
			this.alertDetProveedorContacto.setActive(true, 'danger');
			this.alertOut(this.alertDetProveedorContacto);
		}
	}
}
