import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class EstadisticasServices {
  public url: string;
  public sessionUser = JSON.parse(localStorage.getItem('sessionuser'));
  public env = environment;
  constructor(
    public _http: HttpClient
  ) {
    this.url = this.env.dominio + 'api/statistics';
  }
  getStatistics(year): Observable<any> {
    if (this.sessionUser) {
      const param = '?year=' + year;
      const access_token = 'Bearer ' + this.sessionUser.access_token;
      const headers = new HttpHeaders().set('Content-Type', 'application/json')
        .set('Authorization', access_token);
      return this._http.get(this.url + param, { headers: headers });
    }
  }
}
