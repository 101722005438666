import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class VentasDetalleServices {
    public url: string;
    public sessionuser = JSON.parse(localStorage.getItem("sessionuser"));
    public env = environment;

    constructor(
        public _http: HttpClient
    ) {

        this.url = this.env.dominio + "api/salesDetail";
    }

    getByPage(page, limit, search = '', order = '', paramExtras = ''): Observable<any> {
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', access_token);

            let param = '?from=' + page;
            param += '&to=' + limit;
            if (search != '') param += '&search=' + search;
            if (order != '') param += '&order=' + order;
            if (paramExtras != '') param += '&' + paramExtras;

            return this._http.get(this.url + param, { headers: headers });
        }
    }
}