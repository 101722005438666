import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class UserServices {
    public url: string;
    public sessionuser = JSON.parse(localStorage.getItem("sessionuser"));
    public env = environment;
    constructor(
        public _http: HttpClient
    ) {

        this.url = this.env.dominio + "api/auth/login";
    }

    login(user): Observable<any> {
        let param = JSON.stringify(user);
        let headers = new HttpHeaders().set("Content-Type", 'application/json');
        return this._http.post(this.url, param, { headers: headers });
    }

    logout(): Observable<any> {  
        this.sessionuser = JSON.parse(localStorage.getItem("sessionuser"));   
        if (this.sessionuser) {   
            let url = this.env.dominio + "api/logout";
            let access_token = 'Bearer ' + this.sessionuser.access_token;
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', access_token);
            return this._http.get(url, { headers: headers });

        }
    }
}