import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Modal } from '../../utilidades/modal.component';

@Component({
	selector: 'app-devolucionesdetalle',
	templateUrl: './devolucionesdetalle.component.html',
	styleUrls: ['./devolucionesdetalle.component.scss']
})

export class DevolucionesdetalleComponent implements OnInit {
	public modalDevolucionesDetalle: any = '';
	public dataDetalle: any = '';
	public modalChildVisible = false;
	constructor() {
		this.modalDevolucionesDetalle = new Modal();
	}
	ngOnInit() {
	}

	openModalDevolucionesDetalle(data) {
		this.dataDetalle = data;
		this.modalDevolucionesDetalle.openModal();
	}
}
