import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

//UTILIDADES
import { Alerta } from '../../utilidades/alerta.component';
import { Loading } from '../../utilidades/loading.component';
import { Modal } from '../../utilidades/modal.component';
import { validationInput } from '../../utilidades/validation.models';
import { Generales } from '../../utilidades/generales.component';

//MODELS
import { CategoriaModel } from "../../../models/categoria.model";

//SERVICES
import { CategoriasServices } from "../../../services/categorias.services";
import { DepartamentosServices } from '../../../services/departamentos.services';

@Component({
	selector: 'app-categoriasdetalle',
	templateUrl: './categoriasdetalle.component.html',
	styleUrls: ['./categoriasdetalle.component.scss'],
	providers: [
		CategoriasServices, DepartamentosServices
	]
})
export class CategoriasdetalleComponent implements OnInit {
	//MODELS
	public categoria: CategoriaModel;

	//VARIABLES
	public alertDetCategoria: Alerta;
	public loadingDet: any;
	public modalCategoriaDetalle: any = '';
	public idCategoria: number = -1; // -1 Significa que no es un usuario nuevo ni en modo edicion
	public modalChildVisible = false;
	public validationInputs: any = '';
	public departamentos: any = [];
	public utilidades: Generales;


	//FORM
	public formCategoria: FormGroup;

	@Output() upDateGridCategorias = new EventEmitter();

	constructor(
		private _categoriaService: CategoriasServices,
		private _departamentoService: DepartamentosServices
	) { 
		this.alertDetCategoria = new Alerta('alert_det_egresos');
		this.loadingDet = new Loading();
		this.modalCategoriaDetalle = new Modal();
		this.validationInputs = new validationInput();

		this.formCategoria = new FormGroup({
			id_departamento: new FormControl({ value: '' }, Validators.required),
			nombre_categoria: new FormControl({ value: '' }, Validators.required)
		});
		this.utilidades = new Generales();
	}

	private alertOut($ele, timer = 2000) {
		setTimeout(function () {
			$ele.setActive(false);
		}, timer);
	}

	ngOnInit() {
	}

	openModalDetalleCategorias(id) {
		this.idCategoria = id;
		this.modalCategoriaDetalle.openModal();
		this.modalChildVisible = false;
		this.resetModels();
		this.loadDepartamentos();
		if (id > 0) { //EDITAR SUBCATEGORIA
			this.loadingDet.setLoading(true);
			this.onEdit();			
		}
	}

	resetModels() {
		this.categoria = new CategoriaModel(null, null, '');
		this.categoria.id_departamento = 0;
	}

	onAdd() {
		this.resetModels();
		this.idCategoria = 0;
	}

	loadDepartamentos() {
		try {
			this._departamentoService.getAll().subscribe(
				response => {
					this.departamentos = response.data;
				},
				err => {
					this.alertDetCategoria.setActive(true, err.error.code);
					this.alertOut(this.alertDetCategoria);
				}
			);
		} catch (err) {
			this.alertDetCategoria.setActive(true, 'danger');
			this.alertOut(this.alertDetCategoria);
		}
	}

	onEdit() {
		try {
			this._categoriaService.getCategoria(this.idCategoria).subscribe(
				response => {
					var data = response.data[0];
					this.categoria = new CategoriaModel(data.id_categoria, data.id_departamento, data.nombre_categoria);
					this.loadingDet.setLoading(false);
				},
				err => {
					this.alertDetCategoria.setActive(true, err.error.code);
					this.alertOut(this.alertDetCategoria);
				}
			);
		} catch (err) {
			this.alertDetCategoria.setActive(true, 'danger');
			this.alertOut(this.alertDetCategoria);
		}
	}

	onSave() {
		try {
			if (!this.idCategoria) { //NUEVO
				this._categoriaService.add(this.formCategoria.value).subscribe(
					response => {
						this.alertDetCategoria.setActive(true, 'success');
						this.alertOut(this.alertDetCategoria);
						this.idCategoria = response.data.id_categoria;
						this.upDateGridCategorias.emit({ 'id_categoria': this.idCategoria });
					},
					err => {
						this.alertDetCategoria.setActive(true, err.error.code);
						this.alertOut(this.alertDetCategoria);
					}
				);
			} else {
				this._categoriaService.update(this.idCategoria, this.categoria).subscribe(
					response => {
						this.alertDetCategoria.setActive(true, 'update');
						this.alertOut(this.alertDetCategoria);
						var cel_1 = document.getElementsByClassName("categoria_" + this.categoria.id_categoria).item(0).querySelector('.cel_1');
						if (cel_1) cel_1.innerHTML = this.utilidades.limitarStrings(response.data.nombre_categoria);
					},
					err => {
						this.alertDetCategoria.setActive(true, err.error.code);
						this.alertOut(this.alertDetCategoria);
					}
				);
			}
		} catch (err) {
			this.alertDetCategoria.setActive(true, 'danger');
			this.alertOut(this.alertDetCategoria);
		}
	}

	openEditCategoriaDetalle() {
		this.modalCategoriaDetalle.openModal();
	}

	showModal(event): void {
		this.modalChildVisible = event.modal;
	}
}
