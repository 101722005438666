import { Directive, HostListener, ElementRef, OnInit } from '@angular/core';

@Directive({
    selector: '[descuento]'
})

export class DescuentoDirective implements OnInit {
    private el: any;

    constructor(){

    }

    ngOnInit() {

    }

    @HostListener("focus", ["$event.target.value", "$event"])
    onFocus(value, event) {
    }

    @HostListener("blur", ["$event.target.value"])
    onBlur(value) {
    }

    @HostListener('keypress', ['$event'])
    onKeyUp(event) {
        let e = <KeyboardEvent>event;
        let input = <HTMLInputElement>event.srcElement;
        let text = input.value;
        let peso = text.indexOf("$");
        let porc = text.indexOf("%");
        let length = text.length;
        let caracter = String.fromCharCode(e.keyCode);

        if (
            ((peso > -1 || caracter == '$') && (porc > -1 || caracter == '%')) ||
            ((porc > -1 && caracter == '%') ||
            (peso > -1 && caracter == '$'))  ||
            (peso > -1 && peso != 0) ||
            (porc > -1 && porc != length)
        ) {
            e.preventDefault();
        }


        if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
            // Allow: Ctrl+A
            (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
            // Allow: Ctrl+C
            (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
            // Allow: Ctrl+V
            (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
            // Allow: Ctrl+X
            (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
            // Allow: home, end, left, right
            (e.keyCode >= 35 && e.keyCode <= 39) ||
            (e.shiftKey && e.keyCode == 52) ||
            (e.shiftKey && e.keyCode == 53) ) {
            // let it happen, don't do anything
            return;
        }
        // Solo numeros
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }

    }
}
