export class Loading {

    public active: boolean = false;

    constructor(active = false) {
        this.active = active;
    }

    public getLoading() {        
        let html = '';
        html = '<div class="lds-ring"><div></div><div></div><div></div><div></div></div>'                            
        return html;
    }

    public setLoading(active) {
        this.active =  active;
    }

}