import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

//UTILIDADES
import { Alerta } from '../../utilidades/alerta.component';
import { Loading } from '../../utilidades/loading.component';
import { Modal } from '../../utilidades/modal.component';
import { validationInput } from '../../utilidades/validation.models';
import { Generales } from '../../utilidades/generales.component';

//MODELS
import { DepartamentoModel } from "../../../models/departamento.model";

//SERVICES
import { DepartamentosServices } from '../../../services/departamentos.services';

@Component({
	selector: 'app-departamentosdetalle',
	templateUrl: './departamentosdetalle.component.html',
	styleUrls: ['./departamentosdetalle.component.scss'],
	providers: [
		DepartamentosServices
	]
})
export class DepartamentosdetalleComponent implements OnInit {
	//MODELS
	public departamento: DepartamentoModel;

	//VARIABLES
	public alertDetDepartamento: Alerta;
	public loadingDet: any;
	public modalDepartamentoDetalle: any = '';
	public idDepartamento: number = -1; // -1 Significa que no es un usuario nuevo ni en modo edicion
	public modalChildVisible = false;
	public validationInputs: any = '';
	public departamentos: any = '';
	public utilidades: Generales;

	//FORM
	public formDepartamento: FormGroup;

	@Output() upDateGridDepartamentos = new EventEmitter();

	constructor(
		private _departamentoService: DepartamentosServices
	) { 
		this.alertDetDepartamento = new Alerta('alert_det_egresos');
		this.loadingDet = new Loading();
		this.modalDepartamentoDetalle = new Modal();
		this.validationInputs = new validationInput();

		this.formDepartamento = new FormGroup({			
			nombre_departamento: new FormControl({ value: '' }, Validators.required)
		});
		this.utilidades = new Generales();
	}

	private alertOut($ele, timer = 2000) {
		setTimeout(function () {
			$ele.setActive(false);
		}, timer);
	}

	ngOnInit() {
	}

	openModalDetalleDepartamento(id) {
		this.idDepartamento = id;
		this.modalDepartamentoDetalle.openModal();
		this.modalChildVisible = false;
		this.resetModels();
		this.loadDepartamentos();
		if (id > 0) { //EDITAR SUBCATEGORIA
			this.loadingDet.setLoading(true);
			this.onEdit();
		}
	}

	resetModels() {
		this.departamentos = '';
		this.departamento = new DepartamentoModel(null, '');
	}

	onAdd() {
		this.resetModels();
		this.idDepartamento = 0;
	}

	loadDepartamentos() {
		try {
			this._departamentoService.getAll().subscribe(
				response => {
					this.departamentos = response.data;
				},
				err => {
					this.alertDetDepartamento.setActive(true, err.error.code);
					this.alertOut(this.alertDetDepartamento);
				}
			);
		} catch (err) {
			this.alertDetDepartamento.setActive(true, 'danger');
			this.alertOut(this.alertDetDepartamento);
		}
	}

	onEdit() {
		try {
			this._departamentoService.getDepartamento(this.idDepartamento).subscribe(
				response => {
					var data = response.data[0];
					this.departamento = new DepartamentoModel(data.id_departamento, data.nombre_departamento);
					this.loadingDet.setLoading(false);
				},
				err => {
					this.alertDetDepartamento.setActive(true, err.error.code);
					this.alertOut(this.alertDetDepartamento);
				}
			);
		} catch (err) {
			this.alertDetDepartamento.setActive(true, 'danger');
			this.alertOut(this.alertDetDepartamento);
		}
	}

	onSave() {
		try {
			if (!this.idDepartamento) { //NUEVO
				this._departamentoService.add(this.formDepartamento.value).subscribe(
					response => {
						this.alertDetDepartamento.setActive(true, 'success');
						this.alertOut(this.alertDetDepartamento);
						this.idDepartamento = response.data.id_departamento;
						this.upDateGridDepartamentos.emit({ 'id_departamento': this.idDepartamento });
					},
					err => {
						this.alertDetDepartamento.setActive(true, err.error.code);
						this.alertOut(this.alertDetDepartamento);
					}
				);
			} else {
				this._departamentoService.update(this.idDepartamento, this.departamento).subscribe(
					response => {
						this.alertDetDepartamento.setActive(true, 'update');
						this.alertOut(this.alertDetDepartamento);
						var cel_1 = document.getElementsByClassName("departamento_" + this.departamento.id_departamento).item(0).querySelector('.cel_1');
						if (cel_1) cel_1.innerHTML = this.utilidades.limitarStrings(response.data.nombre_departamento);
					},
					err => {
						this.alertDetDepartamento.setActive(true, err.error.code);
						this.alertOut(this.alertDetDepartamento);
					}
				);
			}
		} catch (err) {
			this.alertDetDepartamento.setActive(true, 'danger');
			this.alertOut(this.alertDetDepartamento);
		}
	}

	openEditCategoriaDetalle() {
		this.modalDepartamentoDetalle.openModal();
	}

	showModal(event): void {
		this.modalChildVisible = event.modal;
	}

}
