import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class ProductosServices {
    public url: string;
    public sessionuser = JSON.parse(localStorage.getItem('sessionuser'));
    public env = environment;
    constructor(
        public _http: HttpClient
    ) {
        this.url = this.env.dominio + 'api/products';
    }

    getByPage(page, limit, search = '', sort = '', paramExtras = ''): Observable<any> {
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', access_token);

            let param = '?page=' + page;
            if (limit != '') param += '&limit=' + limit;
            if (sort != '') param += '&sort=' + sort;
            if (paramExtras != '') param += '&' + paramExtras;

            let url = this.url;
            if (search != '') {
                url += '/find';
                param += '&name_product=' + search;
                param += '&sku_product=' + search;
                param += '&code_product=' + search;
            }

            return this._http.get(url + param, { headers: headers });
        }
    }

    delete(id): Observable<any> {
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', access_token);
            return this._http.delete(this.url + '/' + id, { headers: headers });
        }
    }

    export(type, search = '', sort = '', page = 1, limit = ''): Observable<any> {
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;
            let param = {
                'page' : page,
                'type': type,                
                'sort': sort,
                'limit': limit,
                'name_product': search,
                'sku_product': search,
                'code_product': search,
                'fields': 'stock_min,stock_max'
            };

            let headers = new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', access_token);
            return this._http.post(this.url + '/export', param, { headers: headers });
        }
    }

    add(values, imagen): Observable<any> {        
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;

            const formData = new FormData();
            formData.append('image', imagen);

            for (const prop in values) {
                formData.append(prop, values[prop]);
            }
            
            formData.append('fields', 'codebar_product,code_product');
            
            const headers = new HttpHeaders().set('Authorization', access_token);
            headers.append('Content-Type', 'multipart/form-data');            
            headers.append('Accept', 'application/json');       
                
            return this._http.post(this.url, formData, { headers: headers });

        }
    }

    update(id, values): Observable<any> {
        if (this.sessionuser) {

            let access_token = 'Bearer ' + this.sessionuser.access_token;

            delete values['code_product'];
            delete values['image'];
            delete values['sku'];

            const headers = new HttpHeaders().set('Authorization', access_token);
            headers.append('Content-Type', 'multipart/form-data');
            headers.append('Accept', 'application/json');       

            return this._http.put(this.url + '/' + id, values, { headers: headers })
        }
    }

    imagenDelete(id, imageOld, imageNew): Observable<any> {
        if (this.sessionuser) {

            let access_token = 'Bearer ' + this.sessionuser.access_token;

            const formData = new FormData();
            formData.append('image', imageNew);
            formData.append('image_old', imageOld);
    
            const headers = new HttpHeaders().set('Authorization', access_token);
            headers.append('Content-Type', 'multipart/form-data');
            headers.append('Accept', 'application/json');

            return this._http.post(this.url + '/imagen/' + id, formData, { headers: headers })
        }
    }

    find(search, paramExtras = ''): Observable<any> {
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', access_token);

            let param = '';            
            let url = this.url;

            if (search != '') {
                url += '/find?';
                param += 'name_product=' + search;
                param += '&sku_product=' + search;
                param += '&code_product=' + search;
                param += '&product_id=' + search;
            }
            if (paramExtras != '') param += '&' + paramExtras;
            
            return this._http.get(url + param, { headers: headers });
        }
    }

    show(id, paramExtras = ''): Observable<any> {
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', access_token);

            let param = '';
            let url = this.url + '/' + id;

            if (paramExtras != '') param += '?' + paramExtras;

            return this._http.get(url + param, { headers: headers });
        }
    }

    findBySku(sku, paramExtras = ''): Observable<any> {
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', access_token);

            let param = '';
            let url = this.url;

            url += '/findbysku/' + sku;            
            
            if (paramExtras != '') param = '?' + paramExtras;

            return this._http.get(url + param, { headers: headers });
        }
    }
    
}