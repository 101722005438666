export class Modal {
    
    public statusModal: boolean = false;
    public idModal: string = 'modal_close';

    constructor(statusModal = false, idModal = 'modal_close') {
        this.statusModal = statusModal;
        this.idModal = idModal;
    }

    public openModal() {
        this.statusModal = true;
    }

    public closeModal(event) {

        this.statusModal = false;
        /*let id = typeof event == 'string' ? event : event.toElement;

        if(typeof event.toElement.id != undefined && event.toElement.id) {
            if (id == this.idModal) {
                this.statusModal = false;
            }
        }*/
    }
}
