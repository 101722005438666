export class ProductoModel {
    constructor(
        public id_producto: number,
        public codigo: string,
        public sku: string,
        public nombre: string,
        public fecha_compra: Date,
        public id_subcategoria: number,
        public cantidad: number = 0,
        public imagen: any = 'assets/img/image-not-found.png',
        public codebar: string = '', 
        public inversion: any,
        public precio_venta: any,
        public cantidad_minima: number,
        public descuento: any,
        public cantidad_indefinida: boolean = false,
       // public agotado: boolean = false,
        public id_unidad: number,
        public precio_con_descuento: any,
        public precio_sin_descuento: any,
        public vendidos:number,
        public nombre_unidad: string,
        public id_marca: number,
        public total_vendido: number = 0,
        public total_inversion: number = 0,
        public total_ganancia: number = 0,
        public medida: string = '',
        public cantidad_maxima: number = 0,
        public proveedores = []
    ) { }
}