import { Component, OnInit } from '@angular/core';

//MODELS
import { ProductoModel } from '../../../models/producto.model';

@Component({
  selector: 'app-agregar',
  templateUrl: './agregar.component.html',
  styleUrls: ['./agregar.component.scss']
})
export class AgregarProductoComponent implements OnInit {

  //MODELS
  public producto: ProductoModel;

  constructor() { }

  ngOnInit() {
    this.producto = new ProductoModel(
      null,
      '',
      '',
      '',
      new Date,
      null,
      null,
      null,
      '',
      '',
      '',
      null,
      null,
      false,
      null,
      null,
      null,
      null,
      '',
      null,
    );
  }

}
