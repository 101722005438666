import { Component, OnInit } from '@angular/core';

//UTILIDADES
import { Alerta } from '../utilidades/alerta.component';
import { Paginador } from '../utilidades/paginador.component';
import { MycurrencyPipe } from '../../pipe/mycurrency.pipe';

//SERVICES
import { AlertasServices } from '../../services/alertas.services';

@Component({
	selector: 'app-alertas',
	templateUrl: './alertas.component.html',
	styleUrls: ['./alertas.component.scss'],
	providers: [
		AlertasServices, MycurrencyPipe
	]

})
export class AlertasComponent implements OnInit {

	public alerta: Alerta;
	public filtro: boolean = false;
	public buscarAlertas: string = '';
	public paginadorGrillaAlertas: any = '';
	public ordenarAlertas: string = 'mas_reciente';
	

	constructor(
		private _alertasService: AlertasServices,
		private formatcurrencypipe: MycurrencyPipe
	) { 
		this.alerta = new Alerta('alertas');
	}

	private alertOut($ele, timer = 2000) {
		setTimeout(function () {
			$ele.setActive(false);
		}, timer);
	}

	ngOnInit() {
		this.loadVentas();
	}

	loadVentas() {
		try {
			this.paginadorGrillaAlertas = new Paginador(this._alertasService, this.alerta);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	public onFiltro() {
		if (this.filtro) {
			this.filtro = false;
		} else {
			this.filtro = true;
		}
	}

	onSearch(event) {
		try {
			this.buscarAlertas = event.target.value;
			this.paginadorGrillaAlertas.activePage = 1;
			this.paginadorGrillaAlertas.loadData(this.paginadorGrillaAlertas.activePage, this.buscarAlertas, this.ordenarAlertas);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	onOrder() {
		try {
			this.paginadorGrillaAlertas.loadData(this.paginadorGrillaAlertas.activePage, this.buscarAlertas, this.ordenarAlertas);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	onClearFilter() {
		try {
			this.buscarAlertas = '';
			this.ordenarAlertas = 'mas_reciente';
			this.paginadorGrillaAlertas.activePage = 1;
			this.paginadorGrillaAlertas.loadData(this.paginadorGrillaAlertas.activePage, this.buscarAlertas, this.ordenarAlertas);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

}
