import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatTabsModule, MatDatepickerModule, MatNativeDateModule, MatInputModule  } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
/*import { AppMaterialModule } from './app-material/app-material.module'; */
import { routing, appRoutingProviders } from './app-routing';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';

import { AuthGuard } from './components/auth/auth.guard';
import { AuthService } from './components/auth/auth.service';

import { CajaComponent } from './components/caja/caja.component';
import { ProductosComponent } from './components/productos/productos.component';
import { CategoriasComponent } from './components/categorias/categorias.component';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { EstadisticasComponent } from './components/estadisticas/estadisticas.component';
import { AlertasComponent } from './components/alertas/alertas.component';
import { VentasComponent } from './components/ventas/ventas.component';
import { DevolucionesComponent } from './components/devoluciones/devoluciones.component';
import { ClientesComponent } from './components/clientes/clientes.component';
import { ExportarComponent } from './components/exportar/exportar.component';
import { DepartamentosComponent } from './components/departamentos/departamentos.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout.component';
import { LoginLayoutComponent } from './layouts/login-layout.component';

import { UserServices } from './services/user.services';
import { DepartamentosServices } from './services/departamentos.services';
import { SubcategoriasComponent } from './components/subcategorias/subcategorias.component';
import { MycurrencyPipe } from './pipe/mycurrency.pipe';
import { MycurrencyDirective } from './directive/mycurrency.directive';
import {  DescuentoDirective } from './directive/descuento.directive';

//import { AlertsModule } from 'angular-alert-module';
import { ClientedetalleComponent } from './components/clientes/clientedetalle/clientedetalle.component';
import { CreditosComponent } from './components/creditos/creditos.component';
import { CreditodetalleComponent } from './components/creditos/creditodetalle/creditodetalle.component';
import { PagosComponent } from './components/pagos/pagos.component';
import { DevolucionesdetalleComponent } from './components/devoluciones/devolucionesdetalle/devolucionesdetalle.component';
import { VentadetalleComponent } from './components/ventas/ventadetalle/ventadetalle.component';
import { MarcasComponent } from './components/marcas/marcas.component';
import { MarcasdetalleComponent } from './components/marcas/marcasdetalle/marcasdetalle.component';
import { ProveedoresComponent } from './components/proveedores/proveedores.component';
import { ProveedoresdetalleComponent } from './components/proveedores/proveedoresdetalle/proveedoresdetalle.component';
import { ProveedorcontactosComponent } from './components/proveedorcontactos/proveedorcontactos.component';
import { ProveedorcontactodetalleComponent } from './components/proveedorcontactos/proveedorcontactodetalle/proveedorcontactodetalle.component';
import { EgresosComponent } from './components/egresos/egresos.component';
import { EgresosdetalleComponent } from './components/egresos/egresosdetalle/egresosdetalle.component';
import { SubcategoriasdetalleComponent } from './components/subcategorias/subcategoriasdetalle/subcategoriasdetalle.component';
import { CategoriasdetalleComponent } from './components/categorias/categoriasdetalle/categoriasdetalle.component';
import { DepartamentosdetalleComponent } from './components/departamentos/departamentosdetalle/departamentosdetalle.component';
import { ProductosdetalleComponent } from './components/productos/productosdetalle/productosdetalle.component';
import { ConfiguracionesComponent } from './components/configuraciones/configuraciones.component';
import { ConfiguracionCreditoComponent } from './components/configuraciones/configuracion-credito/configuracion-credito.component';
import { HighlightDirective } from './highlight.directive';
import { AgregarProductoComponent } from './components/productos/agregar/agregar.component';
import { EditarProductoComponent } from './components/productos/editar/editar.component';
import { CurrencyMaskModule  } from "ng2-currency-mask";

/*
export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'right',
  allowNegative: false,
  decimal: '',
  precision: 0,
  prefix: '$',
  suffix: '',
  thousands: '.'
};*/

@NgModule({
  declarations: [
    AppComponent,
    CajaComponent,
    ProductosComponent,
    CategoriasComponent,
    UsuariosComponent,
    EstadisticasComponent,
    AlertasComponent,
    VentasComponent,
    DevolucionesComponent,
    ClientesComponent,
    ExportarComponent,
    DepartamentosComponent,
    LoginComponent,
    LoginLayoutComponent,
    DashboardLayoutComponent,
    SubcategoriasComponent,
    MycurrencyPipe,
    MycurrencyDirective,
    DescuentoDirective,
    ClientedetalleComponent,
    CreditosComponent,
    CreditodetalleComponent,
    PagosComponent,
    DevolucionesdetalleComponent,
    VentadetalleComponent,
    MarcasComponent,
    MarcasdetalleComponent,
    ProveedoresComponent,
    ProveedoresdetalleComponent,
    ProveedorcontactosComponent,
    ProveedorcontactodetalleComponent,
    EgresosComponent,
    EgresosdetalleComponent,
    SubcategoriasdetalleComponent,
    CategoriasdetalleComponent,
    DepartamentosdetalleComponent,
    ProductosdetalleComponent,
    ConfiguracionesComponent,
    ConfiguracionCreditoComponent,
    HighlightDirective,    
    AgregarProductoComponent,
    EditarProductoComponent    
  ],
  imports: [
    BrowserModule,
    routing,
    HttpClientModule,
    FormsModule,
    MatTabsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    CurrencyMaskModule
    //AppMaterialModule
    // Specify your library as an import
    //AlertsModule.forRoot()
  ],
  exports: [
    MatDatepickerModule
  ],
  providers: [
    appRoutingProviders,
    AuthService, 
    AuthGuard,
    UserServices,
    DepartamentosServices
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
