import { Component, OnInit, ViewChild } from '@angular/core';

//UTILIDADES
import { Alerta } from '../utilidades/alerta.component';
import { Paginador } from '../utilidades/paginador.component';
import { MycurrencyPipe } from '../../pipe/mycurrency.pipe';

//SERVICES
import { VentasServices } from '../../services/ventas.services';

//COMPONENT
import { VentadetalleComponent } from './ventadetalle/ventadetalle.component';


@Component({
	selector: 'app-ventas',
	templateUrl: './ventas.component.html',
	styleUrls: ['./ventas.component.scss'],
	providers: [
		VentasServices, MycurrencyPipe
	]
})
export class VentasComponent implements OnInit {

	public alerta: Alerta;
	public paginadorGrillaDev: any = '';
	public buscarCompras: string = '';
	public ordenarCompras: string = 'mas_reciente';
	public id: number = 0;
	public toDate: string = '';
	public fromDate: string = '';
	public filteToDate;
	public filtro: boolean = false;
	public wait_export = false;
	public total_purchase = 0;
	public total_sale = 0;
	public total_revenue = 0;

	@ViewChild(VentadetalleComponent) ventaDetalle: VentadetalleComponent; //Componente hijo
	@ViewChild('fechaFin') fechaFin: any;

	constructor(
		private _ventasService: VentasServices,
		private formatcurrencypipe: MycurrencyPipe
	) { 
		this.alerta = new Alerta('alertList');
	}

	private alertOut($ele, timer = 2000) {
		setTimeout(function () {
			$ele.setActive(false);
		}, timer);
	}

	private zeroPadded(val) {
		if (val >= 10)
			return val;
		else
			return '0' + val.toString();
	}

	ngOnInit() {
		this.loadVentas();
	}

	loadVentas() {
		try {
			this.paginadorGrillaDev = new Paginador(this._ventasService, this.alerta);
			this.getTotals();
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	public onFiltro() {
		if (this.filtro) {
			this.filtro = false;
		} else {
			this.filtro = true;
		}
	}

	onSearch(event) {
		try {
			this.buscarCompras = event.target.value;
			this.paginadorGrillaDev.activePage = 1;
			let param = `fromDate=${this.fromDate}&toDate=${this.toDate}`;
			this.paginadorGrillaDev.loadData(this.paginadorGrillaDev.activePage, this.buscarCompras, this.ordenarCompras, param);
			this.getTotals();
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	onOrder() {
		try {
			let param = `fromDate=${this.fromDate}&toDate=${this.toDate}`;
			this.paginadorGrillaDev.loadData(this.paginadorGrillaDev.activePage, this.buscarCompras, this.ordenarCompras, param);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	onClearFilter() {
		try {
			this.fromDate = '';
			this.toDate = '';
			this.buscarCompras = '';
			this.ordenarCompras = 'mas_reciente';
			this.paginadorGrillaDev.activePage = 1;
			this.paginadorGrillaDev.loadData(this.paginadorGrillaDev.activePage, this.buscarCompras, this.ordenarCompras);
			this.getTotals();
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	openFormEditClienteDetalle(id) {
		this.id = id;
		this.ventaDetalle.openModalEditCompraDetalle();
	}


	getDateInicio(fecha) {
		try {
			this.fromDate = '';
			let date = new Date(fecha);
			this.fromDate = date.getFullYear() + "-" + this.zeroPadded(date.getMonth() + 1) + "-" + this.zeroPadded(date.getDate()) + "T" + this.zeroPadded(date.getHours()) + ":" + this.zeroPadded(date.getMinutes()) + ":" + this.zeroPadded(date.getSeconds());
			let param = `fromDate=${this.fromDate}&toDate=${this.toDate}`;
			this.paginadorGrillaDev.loadData(1, this.buscarCompras, this.ordenarCompras, param);
			this.getTotals();
			if (this.fromDate != '') {
				this.fechaFin.disabled = false;
				this.filteToDate = (d: Date): boolean => {
					const daySeletec = date;
					return daySeletec.getTime() <= d.getTime();
				}
			}
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	getDateFin(fecha) {
		try {
			let date = new Date(fecha);
			this.toDate = date.getFullYear() + "-" + this.zeroPadded(date.getMonth() + 1) + "-" + this.zeroPadded(date.getDate()) + "T" + this.zeroPadded(date.getHours()) + ":" + this.zeroPadded(date.getMinutes()) + ":" + this.zeroPadded(date.getSeconds());
			let param = `fromDate=${this.fromDate}&toDate=${this.toDate}`;
			this.paginadorGrillaDev.loadData(1, this.buscarCompras, this.ordenarCompras, param);
			this.getTotals();
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	reloadData(event): void {
		this.loadVentas();
	}

	onExport() {
		try {
			this.wait_export = true;
			this._ventasService.export(this.buscarCompras, this.ordenarCompras, this.fromDate, this.toDate).subscribe(
				response => {
					const a = document.createElement('a');
					a.href = response.file;
					a.download = response.name;
					document.body.appendChild(a);
					a.click();
					a.remove();
					this.wait_export = false;
				},
				err => {
					this.wait_export = false;
					this.alerta.setActive(true, err.error.code);
					this.alertOut(this.alerta);
				}
			);
		} catch (err) {
			this.wait_export = false;
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	getTotals() {
		try {
			this._ventasService.totals(this.buscarCompras, this.fromDate, this.toDate).subscribe(
				response => {
					this.total_purchase = response[0].total_purchase;
					this.total_sale = response[0].total_sale;
					this.total_revenue = response[0].total_revenue;
				},
			)
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	public formatoPrecio(precio) {
		return this.formatcurrencypipe.transform(this.formartNum(precio));
	}

	formartNum(num) {
		let result = num.toString().replace(/,/g, '');
		result = result.replace('$', '');
		return result;
	}

}
