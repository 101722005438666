export class EgresoModel {
    constructor(
        public idEgreso: number,
        public fecha: Date,        
        public descripcion: string,
        public costo: any,
        public prestamista: string,
        public estado: string,
        public observacion: string        
    ) { }
}