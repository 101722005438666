import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Alerta} from '../utilidades/alerta.component';
import {Alertapopup} from '../utilidades/alertapopup.component';
import {Paginator} from '../utilidades/paginator.component';
import {MycurrencyPipe} from '../../pipe/mycurrency.pipe';
import {ProductosServices} from '../../services/productos.services';
import {ClientesServices} from '../../services/clientes.services';
import {VentasServices} from '../../services/ventas.services';
import {PersonaModel} from '../../models/persona.model';

@Component({
  selector: 'app-caja',
  templateUrl: './caja.component.html',
  styleUrls: ['./caja.component.scss'],
  providers: [ProductosServices, MycurrencyPipe, ClientesServices, VentasServices]
})
export class CajaComponent implements OnInit {
  public paginadorProductos: any = '';
  public alertaProductos: Alerta;
  public alertaCliente: Alerta;
  public alertaCaja: Alerta;
  public alertaPopup: Alertapopup;
  public buscarProductos: '';
  public carroCompra: any = Array();
  public abona: any = '$0';
  public debe: any = '$0';
  public subtotal: any = '$0';
  public descuento: any = '$0';
  public efectivo: any = '$0';
  public cambio: any = '$0';
  public total: any = '$0';
  public searchClient = '';
  public idCliente = 0;
  public ticket = false;
  public codigo = '';
  public fecha_hora = '';
  public credito: any = '';
  public totalArticulos = 0;

  public codigo_tk: any;
  public fecha_hora_tk: any;
  public abona_tk: any;
  public credito_tk: any;
  public descuento_tk: any = 0;
  public total_tk: any = 0;
  public subtotal_tk: any = 0;
  public cambio_tk: any = 0;
  public efectivo_tk: any = 0;
  public totalArticulos_tk: any = 0;
  public changeCoins: any = 0;
  public productos_tk: any;
  public nombres_tk: string;
  public apellidos_tk: string;
  public persona: PersonaModel;
  public searchsw = false;

  public loadAgregar = false;
  public loadPagar = false;
  public timeoutas = null;

  public tranModal = false;
  public tranStep1 = false;
  public tranStep2 = false;
  public tranStep3 = false;


  public loadCliente = false;
  @ViewChild('searchAvanzada') searchAvanzadaElement: ElementRef;
  @ViewChild('tableProductos') tableProductos: ElementRef;
  @ViewChild('efectivoInput') efectivoElement: ElementRef;
  @ViewChild('stepFinish') stepFinish: ElementRef;


  public dateSale = new Date();

  constructor(
    private _productosServices: ProductosServices,
    private _clienteServices: ClientesServices,
    private formatcurrencypipe: MycurrencyPipe,
    private _ventasServices: VentasServices
  ) {
    this.alertaProductos = new Alerta('alerta_productos');
    this.alertaCliente = new Alerta('alerta_cliente');
    this.alertaCaja = new Alerta('alerta_caja');
    this.persona = new PersonaModel(null, '', '', '', '', null, '', '', '', true);
    this.dateSale = new Date();
  }

  private alertOut($ele, timer = 2000) {
    setTimeout(function () {
      $ele.setActive(false);
    }, timer);
  }

  public formatoPrecio(precio) {
    return this.formatcurrencypipe.transform(this.formartNum(precio));
  }

  public parseNumberToPrice(precio) {
    return this.formatcurrencypipe.parseNumberToPrice(precio);
  }

  public parsePriceToNumber(precio) {
    return this.formatcurrencypipe.parsePriceToNumber(precio);
  }

  formartNum(num) {
    let result = num.toString().replace(/,/g, '');
    result = result.replace('$', '');
    return result;
  }

  ngOnInit() {
    this.alertaPopup = new Alertapopup('alerta_pop_productos');
    const json = JSON.parse(localStorage.getItem('cart'));
    this.productos();
    if (json) {
      this.carroCompra = json;
      this.calcularTotales(this.carroCompra);
    }
  }

  productos() {
    try {
      this.paginadorProductos = new Paginator(this._productosServices, this.alertaProductos, '', '', 'fields=cost_id,indefinite_amount', 5);
    } catch (err) {
      this.alertaProductos.setActive(true, 'danger');
      this.alertOut(this.alertaProductos);
    }
  }

  onAdvancedSearch(event) {
    try {
      const $this = this;
      clearTimeout($this.timeoutas);

      $this.timeoutas = setTimeout(function () {
        $this.paginadorProductos.activePage = 1;
        $this.paginadorProductos.loadData($this.paginadorProductos.activePage, event.target.value, '', '');
      }, 500);

    } catch (err) {
      this.alertaProductos.setActive(true, 'danger');
      this.alertOut(this.alertaProductos);
    }
  }

  onClearSearch() {
    this.productos();
    this.buscarProductos = '';
  }

  addProductsAvanzada(producto) {
    const $inputCantidad = <HTMLInputElement>document.getElementsByClassName('cantidad_' + producto.product_id)[0];
    const cantidadSolicitada = parseInt($inputCantidad.value, 0);
    let subtotal: number;
    subtotal = producto.best_price * cantidadSolicitada;

    const data = {
      'id': producto.product_id,
      'nombre': producto.name_product,
      'cantidadSolicitada': cantidadSolicitada,
      'descuento': producto.discount_product * cantidadSolicitada,
      'precio': producto.best_price,
      'precio_sin_descuento': producto.price_regular,
      'subtotal': subtotal,
      'cantidadStock': producto.quantity_product,
      'cantidad_indefinida': producto.indefinite_amount,
      'id_costo': producto.cost_id
    };
    this.addCart(data);
  }

  addCart(data) {
    const cantidadCarro = this.carroCompra.filter(task => task.id === data.id)
      .map(dt => dt.cantidad)
      .reduce((a, b) => parseInt(a, 0) + parseInt(b, 0), 0);

    const nuevaCantidad = data.cantidadStock - data.cantidadSolicitada - cantidadCarro;

    if(this.carroCompra.length === 0) {
      this.dateSale = new Date();
    }

    if (nuevaCantidad >= 0 || data.cantidad_indefinida === 1) {
      if (data.cantidadSolicitada > 0) {
        this.carroCompra.push({
          'id': data.id,
          'nombre': data.nombre,
          'cantidad': data.cantidadSolicitada,
          'descuento': data.descuento,
          'precio_venta': data.precio,
          'precio_sin_descuento': data.precio_sin_descuento,
          'subtotal': data.subtotal,
          'id_costo': data.id_costo
        });

        localStorage.setItem('cart', JSON.stringify(this.carroCompra));
        this.calcularTotales(this.carroCompra);

        this.alertaProductos.setActive(true, 'info', 'Producto agregado a la lista con éxito');
        this.alertOut(this.alertaProductos);

      } else if (data.cantidadSolicitada === 0) {
        this.alertaProductos.setActive(true, 'info', 'La cantidad de productos no puede ser 0');
        this.alertOut(this.alertaProductos);
      }
    } else {
      const ct = data.cantidadStock - cantidadCarro;
      const msgc = ct > 1 ? ct + ' productos.' : ct + ' producto.';
      this.alertaProductos.setActive(true, 'info', 'La cantidad solicitada no esta disponible, hay un máximo de: ' + msgc);
      this.alertOut(this.alertaProductos);
    }
  }

  calcularTotales(data) {
    const subtotal = data.reduce(function (prev, cur) {
      return prev + (cur.precio_sin_descuento * cur.cantidad);
    }, 0);

    const descuento = data.reduce(function (prev, cur) {
      return prev + cur.descuento;
    }, 0);

    const total = subtotal - descuento;
    this.subtotal = this.formatoPrecio(subtotal);
    this.descuento = this.formatoPrecio(descuento);
    this.total = this.formatoPrecio(total);
    this.changeMoney();
  }

  getTotal() {
    const subtotal = this.carroCompra.map(obj => obj.subtotal);
    return subtotal.reduce(function (total, valor) {
      return total + valor;
    });
  }

  onPay() {
    const efectivo = parseFloat(this.formartNum(this.efectivo));
    const abona = parseFloat(this.formartNum(this.abona));
    const debe = parseFloat(this.formartNum(this.debe));
    const total = this.getTotal();

    if (efectivo === 0) {
      this.alertaPopup.openAlert('complete_form_cliente', 'Efectivo no puede ser $0');
      return false;
    }

    if (debe > 0 && this.persona.nombres === '' && this.persona.apellidos === '' && this.persona.numerodocumento === '') {
      this.alertaPopup.openAlert('complete_form_cliente');
    } else if (debe < 0) {
      this.alertaPopup.openAlert('complete_form_cliente', 'Por favor verifique los datos de la transacción');
    } else {
      if (efectivo === 0 && abona > 0 && debe <= total) {
        this.alertaPopup.openAlert('complete_form_cliente', 'Efectivo no puede ser 0');
      } else if (abona > efectivo && efectivo > 0 && debe <= total) {
        this.alertaPopup.openAlert('complete_form_cliente', 'El efectivo no puede ser menor a Abono');
      } else {
        this.loadPagar = true;
        const data = {
          'apellidos': this.persona.apellidos,
          'celular': this.persona.celular,
          'codigo': this.persona.codigo,
          'email': this.persona.email,
          'estado': this.persona.estado,
          'iddocumento': this.persona.iddocumento,
          'idpersona': this.persona.idpersona,
          'idcliente': this.idCliente,
          'nombres': this.persona.nombres,
          'numerodocumento': this.persona.numerodocumento,
          'telefono': this.persona.telefono,
          'efectivo': this.efectivo,
          'abona': this.abona,
          'productos': this.carroCompra,
          'date_sale' : this.dateSale
        };
        this._ventasServices.add(data).subscribe(
          response => {
            this.ticket = true;
            this.nombres_tk = this.persona.nombres;
            this.apellidos_tk = this.persona.apellidos;
            this.codigo_tk = response.data[0].codigo_compra;
            this.fecha_hora_tk = response.data[0].fecha_hora;
            this.abona_tk = response.data[0].abono;
            this.credito_tk = response.data[0].credito;
            this.descuento_tk = response.data[0].descuento;
            this.total_tk = response.data[0].total;
            this.subtotal_tk = response.data[0].subtotal;
            this.cambio_tk = response.data[0].cambio;
            this.efectivo_tk = response.data[0].efectivo;
            this.totalArticulos_tk = response.data[0].totalArticulos;
            this.productos_tk = this.carroCompra;
            this.onClearCaja();
            this.loadPagar = false;
            this.alertaPopup.openAlert('complete_form_cliente', 'El pago se ha realizado con exito');
          },
          err => {
            this.alertaCaja.setActive(true, err.error.code, err.error.message);
            this.alertOut(this.alertaCaja);
            this.loadPagar = false;
          }
        );
      }
    }
  }

  onClearCaja() {
    this.tranStep1 = false;
    this.tranStep2 = false;
    this.tranStep3 = false;
    this.ticket = true;
    this.onCancel();
    this.persona = new PersonaModel(null, '', '', '', '', null, '', '', '', true);
    this.carroCompra = [];
    this.searchClient = '';
  }

  onCancel() {
    this.efectivo = '$0';
    this.abona = '$0';
    this.carroCompra = [];
    this.subtotal = '$0';
    this.efectivo = '$0';
    this.cambio = '$0';
    this.total = '$0';
    this.idCliente = 0;
    this.codigo = '';
    this.fecha_hora = '';
    this.credito = '';
    this.totalArticulos = 0;
    localStorage.setItem('cart', JSON.stringify(this.carroCompra));
  }

  onDeleteProduct(index) {
    this.carroCompra.splice(index, 1);
    localStorage.setItem('cart', JSON.stringify(this.carroCompra));
    this.calcularTotales(this.carroCompra);
  }

  closeTicket() {
    this.ticket = false;
    this.tranModal = false;
  }

  print() {
    window.print();
  }

  moreProducts(id) {
    const $inputQuantity = <HTMLInputElement>document.getElementsByClassName('cantidad_' + id)[0];
    let quantity = parseInt($inputQuantity.value, 0);
    quantity = quantity + 1;
    $inputQuantity.value = quantity.toString();
  }

  lessProducts(id) {
    const $inputQuantity = <HTMLInputElement>document.getElementsByClassName('cantidad_' + id)[0];
    let quantity = parseInt($inputQuantity.value, 0);
    if (quantity > 1) {
      quantity = quantity - 1;
      $inputQuantity.value = quantity.toString();
    }
  }

  changeMoney() {
   this.changeCoins = 0;
    const changeC = this.efectivo - this.formatcurrencypipe.parsePriceToNumber(this.total);
    if (changeC >= 0) {
      this.changeCoins = changeC;
    }
  }

}
