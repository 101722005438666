import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class VentasServices {
    public url: string;
    public sessionuser = JSON.parse(localStorage.getItem("sessionuser"));
    public env = environment;
    constructor(
        public _http: HttpClient
    ) {

        this.url = this.env.dominio + "api/sales";
    }
    
    getByPage(page, limit, search = '', order = '', paramExtras = ''): Observable<any> {
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', access_token);

            let param = '?from=' + page;
            param += '&to=' + limit;
            if (search != '') param += '&search=' + search;
            if (order != '') param += '&order=' + order;
            if (paramExtras != '') param += '&' + paramExtras;

            return this._http.get(this.url + param, { headers: headers });
        }
    }

    getClienteCompraId(id, page = 1, limit = 10): Observable<any> {
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;

            let param = '?idventa=' + id;
            param += '&from=' + page;
            param += '&to=' + limit;

            let headers = new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', access_token);
            return this._http.get(this.url + param, { headers: headers });
        }
    }

    add(values): Observable<any> {
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;
            let param = values;

            let headers = new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', access_token);
            return this._http.post(this.url, param, { headers: headers });
        }
    }

    export(search = '', sort = '', fromDate = '', toDate = ''): Observable<any> {
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;
            let param = {
                'toDate': toDate,
                'fromDate': fromDate,
                'sort': sort,
                'search': search,
            };

            let headers = new HttpHeaders().set('Content-Type', 'application/json')
              .set('Authorization', access_token);
            return this._http.post(this.url + '/export', param, { headers: headers });
        }
    }

    totals(search = '', fromDate = '', toDate = ''): Observable<any> {
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;
            let param = {
                'toDate': toDate,
                'fromDate': fromDate,
                'search': search,
            };
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
              .set('Authorization', access_token);
            return this._http.post(this.url + '/totals', param, { headers: headers });
        }
    }
}
