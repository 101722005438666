export class Generales  {
    limitarStrings(value, limite = 20) {
        let val = value;
        if(val.length > limite) {
            val = value.slice(0, limite); 
            val += '...';
        }
        return val;
    }   
    
    formartFecha(fecha) {
        let newfecha = new Date(fecha.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));    
        let day = newfecha.getDate() < 10 ? '0' + newfecha.getDate() : newfecha.getDate();
        let mes = (newfecha.getMonth() + 1) < 10 ? '0' + (newfecha.getMonth() + 1) : (newfecha.getMonth() + 1);

        fecha = day + '-' + mes + '-' + newfecha.getFullYear();
        return fecha;
    }

    numericOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.key || event.keyCode;  // keyCode is deprecated but needed for some browsers
        return !(charCode === 101 || charCode === 69 || charCode === 45 || charCode === 43);
    }
    
}