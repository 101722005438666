import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

//UTILIDADES
import { Alerta } from '../../utilidades/alerta.component';
import { Loading } from '../../utilidades/loading.component';
import { Modal } from '../../utilidades/modal.component';
import { validationInput } from '../../utilidades/validation.models';

//MODELS
import { ProveedorModel } from '../../../models/proveedor.model';

//SERVICES
import { ProveedoresServices } from '../../../services/proveedores.services';

//COMPONENT
import { ProveedorcontactosComponent } from '../../proveedorcontactos/proveedorcontactos.component';

@Component({
	selector: 'app-proveedoresdetalle',
	templateUrl: './proveedoresdetalle.component.html',
	styleUrls: ['./proveedoresdetalle.component.scss'],
	providers: [
		ProveedoresServices
	]
})
export class ProveedoresdetalleComponent implements OnInit {
	//MODELS
	public proveedor: ProveedorModel;
	
	//VARIABLES
	public alertDetProveedor: Alerta;
	public loadingDet: any;
	public modalProveedorDetalle: any = '';
	public idProveedor: number = -1; // -1 Significa que no es un usuario nuevo ni en modo edicion
	public modalChildVisible = false; 
	public validationInputs: any = '';
	public nit: any = '';
	public disableBtnSave: boolean = false;
	public timeout = null;
	//FORM
	public formProveedor: FormGroup;

	@Output() upDateGridProveedor = new EventEmitter();

	constructor(
		private _proveedorService: ProveedoresServices,
		private fb: FormBuilder,
	) { 
		this.alertDetProveedor = new Alerta('alert_det_proveedor');
		this.loadingDet = new Loading();
		this.modalProveedorDetalle = new Modal();
		this.validationInputs = new validationInput();
		this.formProveedor = this.fb.group({
			nit: ['', Validators.required],
			razon_social: ['', Validators.required],
			direccion: ['', Validators.required],			
			email: new FormControl('', Validators.compose([
				Validators.pattern(this.validationInputs.email.pattern)				
			])),
			telefono: ['', 
				Validators.required				
			],
			celular: [''],
			sitio_web: ['', Validators.compose([
				Validators.pattern(this.validationInputs.sitioWeb.pattern)
			])],
			coordenadas: ['', Validators.compose([
				Validators.pattern(this.validationInputs.coordenadas.pattern)
			])],
			observacion: ['']
		});		
	}

	private alertOut($ele, timer = 2000) {
		setTimeout(function () {
			$ele.setActive(false);
		}, timer);
	}

	ngOnInit() {}

	openModalDetalleProveedor(id) {
		this.idProveedor = id;
		this.modalProveedorDetalle.openModal();
		this.modalChildVisible = false;
		this.resetModels();
		if (id > 0) { //EDITAR PROVEEDOR
			this.loadingDet.setLoading(true);
			this.onEdit();
		}		
	}

	resetModels() {				
		this.proveedor = new ProveedorModel(0, '', '', '', '', '', '', '', '', '');		
	}

	onAdd() {
		this.resetModels();
		this.idProveedor = 0;
	}

	onEdit() {
		try {						
			this._proveedorService.getProveedor(this.idProveedor).subscribe(
				response => {				
					var data = response.data[0];
					this.nit = data.nit;
					this.proveedor = new ProveedorModel(data.id_proveedor, data.nit, data.razon_social, data.direccion, data.email, data.telefono, data.celular, data.sitio_web, data.observacion, data.coordenadas);					
					this.loadingDet.setLoading(false);
				},
				err => {
					this.alertDetProveedor.setActive(true, err.error.code);
					this.alertOut(this.alertDetProveedor);
				}
			);
		} catch (err) {
			this.alertDetProveedor.setActive(true, 'danger');
			this.alertOut(this.alertDetProveedor);
		}
	}

	onSave() {
		try {
			if (!this.idProveedor) { //NUEVO
				this._proveedorService.add(this.formProveedor.value).subscribe(
					response => {
						this.alertDetProveedor.setActive(true, 'success');
						this.alertOut(this.alertDetProveedor);
						this.idProveedor = response.data.id_proveedor;
						this.upDateGridProveedor.emit({'id_proveedor': this.idProveedor});
					},
					err => {
						this.alertDetProveedor.setActive(true, err.error.code);
						this.alertOut(this.alertDetProveedor);
					}
				);
			} else {
				this._proveedorService.update(this.idProveedor, this.proveedor).subscribe(
					response => {
						this.alertDetProveedor.setActive(true, 'update');
						this.alertOut(this.alertDetProveedor);
						var cel_1 = document.getElementsByClassName("proveedor_" + this.idProveedor).item(0).querySelector('.cel_1');
						var cel_2 = document.getElementsByClassName("proveedor_" + this.idProveedor).item(0).querySelector('.cel_2');
						var cel_3 = document.getElementsByClassName("proveedor_" + this.idProveedor).item(0).querySelector('.cel_3');
						var cel_4 = document.getElementsByClassName("proveedor_" + this.idProveedor).item(0).querySelector('.cel_4');
						var cel_5 = document.getElementsByClassName("proveedor_" + this.idProveedor).item(0).querySelector('.cel_5');
						var cel_6 = document.getElementsByClassName("proveedor_" + this.idProveedor).item(0).querySelector('.cel_6');						
						cel_1.innerHTML = response.data.nit;
						cel_2.innerHTML = response.data.razon_social;
						cel_3.innerHTML = response.data.direccion;
						cel_4.innerHTML = response.data.email;
						cel_5.innerHTML = response.data.telefono;
						cel_6.innerHTML = response.data.celular;
						this.nit = response.data.nit;
					},
					err => {
						this.alertDetProveedor.setActive(true, err.error.code);
						this.alertOut(this.alertDetProveedor);
					}
				);
			}
		} catch (err) {
			this.alertDetProveedor.setActive(true, 'danger');
			this.alertOut(this.alertDetProveedor);
		}
	}

	isValidNit(event) {
		let $this = this;
		clearTimeout($this.timeout);
		$this.timeout = setTimeout(function () {
			let value = event.target.value;
			$this.validData(this.codigo, value, 'search_codigo', 'El código ya se encuentra registrado');
		}, 1000);
	}

	isValidEmail(event) {
		let $this = this;
		clearTimeout($this.timeout);
		$this.timeout = setTimeout(function () {
			let value = event.target.value;
			$this.validData(this.codigo, value, 'search_email', 'El email ya se encuentra registrado');
		}, 1000);
	}

	validData(valueActual, nuevoValue, campo, msgError) {
		try {
			this.disableBtnSave = true;
			if (valueActual != nuevoValue && nuevoValue != '') {
				this._proveedorService.search(nuevoValue, 'asc', campo).subscribe(response => {
					if (response.totalItems > 0) {
						this.alertDetProveedor.setActive(true, 'danger', msgError);
						this.alertOut(this.alertDetProveedor);
						this.disableBtnSave = true;
					} else {
						this.disableBtnSave = false;
					}
				},
					err => {
						this.alertDetProveedor.setActive(true, err.error.code);
						this.alertOut(this.alertDetProveedor);
					})
			} else {
				this.disableBtnSave = false;
			}
		} catch (err) {
			this.alertDetProveedor.setActive(true, 'danger');
			this.alertOut(this.alertDetProveedor);
		}
	}

	openEditProveedorDetalle() {
		this.modalProveedorDetalle.openModal();				
	}

	showModal(event): void {
		this.modalChildVisible = event.modal;
	}

}
