import { Component, OnInit, EventEmitter, Output, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

//UTILIDADES
import { Alerta } from '../utilidades/alerta.component';

//MODELS
import { PagosModel } from '../../models/pagos.model';

//SERVICES
import { CreditoServices } from '../../services/credito.services';

import { MycurrencyPipe } from '../../pipe/mycurrency.pipe';

@Component({
  	selector: 'app-pagos',
  	templateUrl: './pagos.component.html',
  	styleUrls: ['./pagos.component.scss'],
	providers: [MycurrencyPipe, CreditoServices]
})
export class PagosComponent implements OnInit {

	@Output() modalChild = new EventEmitter();
	@ViewChild('btnPagar') btnpPagar: ElementRef;
	@Output() updateData = new EventEmitter();
	
	public alerta: Alerta;
	public openFormPagos: boolean = false;
	public restante: any = 0;

	//FORM
	public formPagos: FormGroup;

	public pagosModel: PagosModel;
	
	
	constructor(
		private fb: FormBuilder,
		private formatcurrencypipe: MycurrencyPipe,
		private renderer: Renderer2,
		private _creditoServices: CreditoServices,
	) { 
		this.alerta = new Alerta('alertList');

		this.formPagos = this.fb.group({
			saldo: [''],
			restante: [''],
			valor: [0, [Validators.required, Validators.pattern(/^\$[1-9]/)]]			
		});

		this.formPagos = new FormGroup({
			saldo: new FormControl({ 'value': '', disabled: true }),
			restante: new FormControl({ 'value': '', disabled: true }),
			valor: new FormControl({ 'value': 0 }, Validators.compose([ 
				Validators.required,
				Validators.pattern(/^\$[1-9]/)]))

			/*codigo: new FormControl({ 'value': '', disabled: true }),
			iddocumento: new FormControl({ 'value': '' }, Validators.required),// ['', Validators.required],
			numerodocumento: new FormControl({ 'value': '' }, Validators.required), //['', Validators.required],
			nombres: new FormControl({ 'value': '' }, Validators.required), //['', Validators.required],
			apellidos: new FormControl({ 'value': '' }, Validators.required), //['', Validators.required],
			telefono: new FormControl({ 'value': '' }), //[''],
			celular: new FormControl({ 'value': '' }), //[''],
			email: new FormControl({ 'value': '' }), //['']*/
		});
	}

	private formartNum(num) {
		var result = num.toString().replace(/,/g, "");
		result = result.replace("$", "");
		return result;
	}

	private alertOut($ele, timer = 2000) {
		setTimeout(function () {
			$ele.setActive(false);
		}, timer);
	}

	ngOnInit() {
		this.pagosModel = new PagosModel(0, 0, '', '', '');
	}

	formartPrecio(precio) {
		return this.formatcurrencypipe.transform(this.formartNum(precio));
	}

	closeModalPagos() {
		this.modalChild.emit(false);
		this.openFormPagos = false;
	}

	openEditPagoDetalle(obj) {		
		this.openFormPagos = true;
		this.pagosModel = new PagosModel(obj.idCliente, obj.idCredito, this.formartPrecio(0), this.formartPrecio(obj.monto), this.formartPrecio(obj.costoInicial));
		this.restante = this.formartPrecio(obj.monto);
	}

	onSave() {
		try {
			let param = {
				'idCredito' : this.pagosModel.idCredito,
				'idCliente' : this.pagosModel.idCliente,
				'valorPago' : this.pagosModel.valor
			}
			this._creditoServices.pay(param).subscribe(
				response => {
					this.alerta.setActive(true, response.data.code);
					this.alertOut(this.alerta);						
					this.pagosModel.valor = this.formartPrecio(0);
					this.renderer.setAttribute(this.btnpPagar.nativeElement, "disabled", "true");
					this.updateData.emit({ 'reload': true, 'totalCredito': response.data[0].totalCredito, 'idCliente': this.pagosModel.idCliente });
					this.modalChild.emit(false);
					this.openFormPagos = false;
				},
				err => {
					this.alerta.setActive(true, err.error.code);
					this.alertOut(this.alerta);	
				}
			);
		} catch (err) {
			this.alerta.setActive(true, 'danger');
			this.alertOut(this.alerta);
		}
	}

	keyValor(event: any) {		
		let saldo = this.formartNum(this.pagosModel.saldo);
		let valorPagar = this.formartNum(this.pagosModel.valor)
		saldo = saldo.replace('.', '');
		saldo = saldo.replace(',', '.');
		saldo = parseFloat(saldo);
		valorPagar = valorPagar.replace('.', '');
		valorPagar = valorPagar.replace(',', '.');				
		valorPagar = parseFloat(valorPagar);

		let total = saldo - valorPagar;
		total = total < 0 ? 0 : total;

		if(isNaN(total)) {
			this.restante = this.formartPrecio(saldo);
			this.renderer.setAttribute(this.btnpPagar.nativeElement, "disabled", "true");
		} else {
			this.restante = this.formartPrecio(total);
		}
		if (valorPagar > 0) {
			this.renderer.removeAttribute(this.btnpPagar.nativeElement, "disabled");
		} else {
			this.renderer.setAttribute(this.btnpPagar.nativeElement, "disabled", "true");
		}
	}
}
