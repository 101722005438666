import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class CreditoServices {
    public url: string;
    public sessionuser = JSON.parse(localStorage.getItem("sessionuser"));
    public env = environment;
    constructor(
        public _http: HttpClient
    ) {

        this.url = this.env.dominio + "api/debts";
    }

    add(values): Observable<any> {
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;
            let param = values;

            let headers = new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', access_token);
            return this._http.post(this.url, param, { headers: headers });
        }
    }
    
    getAll(): Observable<any> {
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', access_token);
            return this._http.get(this.url, { headers: headers });
        }
    }

    getByAll(id): Observable<any> {
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;
            let param = '?idcliente=' + id;
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', access_token);
            return this._http.get(this.url + param, { headers: headers });
        }
    }

    delete(id): Observable<any> {
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', access_token);
            return this._http.delete(this.url + '/' + id, { headers: headers });
        }
    }

    update(id, param): Observable<any> {
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', access_token);
            return this._http.put(this.url + '/' + id, param, { headers: headers });
        }
    }

    getByPage(page, limit, search = '', order = '', paramExtras = ''): Observable<any> {
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', access_token);

            let param = '?from=' + page;
            param += '&to=' + limit;
            if (search != '') param += '&search=' + search;
            if (order != '') param += '&order=' + order;
            if(paramExtras != '') param += '&' + paramExtras;

            return this._http.get(this.url + param, { headers: headers });
        }
    }

    export(type, s = '', order = '', concepto = '', idCliente = null): Observable<any> {
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;
            let param = {
                'type': type,
                'search': s,
                'order': order,
                'concepto': concepto,
                'idCliente': ''
            };
            if (idCliente != '') {
                param.idCliente = idCliente;
            }
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', access_token);
            return this._http.post(this.url + '/export', param, { headers: headers });
        }
    }

    pay(param = {}): Observable<any> {
        let access_token = 'Bearer ' + this.sessionuser.access_token;
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
            .set('Authorization', access_token);
        return this._http.post(this.url + '/pay', param, { headers: headers });
    }
}