import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class ConfiguracionServices {
    public url: string;
    public sessionuser = JSON.parse(localStorage.getItem("sessionuser"));
    public env = environment;
    constructor(
        public _http: HttpClient
    ) {

        this.url = this.env.dominio + "api/configuration";
    }

    getConfiguration(): Observable<any> {
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', access_token);
            return this._http.get(this.url, { headers: headers });
        }
    }

}