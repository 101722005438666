import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {FormGroup, Validators, FormControl} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {ActivatedRoute} from '@angular/router';
import {zip} from 'rxjs';

import {Alerta} from '../../utilidades/alerta.component';
import {Loading} from '../../utilidades/loading.component';
import {Modal} from '../../utilidades/modal.component';
import {validationInput} from '../../utilidades/validation.models';
import {MycurrencyPipe} from '../../../pipe/mycurrency.pipe';
import {Generales} from '../../utilidades/generales.component';
import {AppDateAdapter, APP_DATE_FORMATS} from '../../../app-material/format-datepicker';


import {ProductoModel} from '../../../models/producto.model';


import {ProductosServices} from '../../../services/productos.services';
import {SubcategoriasServices} from '../../../services/subcategorias.services';
import {UnidadesmedidaServices} from '../../../services/unidadesmedida.services';
import {MarcasServices} from '../../../services/marcas.services';
import {ProveedoresServices} from '../../../services/proveedores.services';
import {ProductoProveedoresServices} from '../../../services/productoproveedores.services';
import {ConfiguracionServices} from '../../../services/configuracion.services';

@Component({
    selector: 'app-productosdetalle',
    templateUrl: './productosdetalle.component.html',
    styleUrls: ['./productosdetalle.component.scss'],
    providers: [
        ProductosServices,
        MycurrencyPipe,
        SubcategoriasServices,
        UnidadesmedidaServices,
        MarcasServices,
        ProveedoresServices,
        ProductoProveedoresServices,
        ConfiguracionServices,
        {provide: DateAdapter, useClass: AppDateAdapter},
        {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
        {provide: MAT_DATE_LOCALE, useValue: 'es-ES'}
    ]
})
export class ProductosdetalleComponent implements OnInit {
    public producto: ProductoModel;
    public alertDetProducto: Alerta;
    public loadingDet: any;
    public modalProductoDetalle: any = '';
    public idProducto = 0;
    public validationInputs: any = '';
    public subcategorias: any = '';
    public unidades: any = '';
    public marcas: any = '';
    public proveedores = [];
    public proveedoresSelect: any = [];
    public utilidades: Generales;
    public isReadOnly: boolean;
    public timeout = null;
    public imagenPreview = null;
    public imagen = '';
    public codeBar = null;
    public code = null;
    public dataConfig = null;
    public formProducto: FormGroup;

    @ViewChild('inputFile', {read: ElementRef}) inputFile: ElementRef;
    @ViewChild('sku_product') skuElement: ElementRef;

    constructor(
        private _productoService: ProductosServices,
        private _subcategoriaService: SubcategoriasServices,
        private _unidadService: UnidadesmedidaServices,
        private _marcaService: MarcasServices,
        private _proveedoresService: ProveedoresServices,
        private _productoProveedoresServices: ProductoProveedoresServices,
        private _configuracionService: ConfiguracionServices,
        private formatcurrencypipe: MycurrencyPipe,
        private _route: ActivatedRoute
    ) {
        this.alertDetProducto = new Alerta('alert_det_productos');
        this.loadingDet = new Loading();
        this.modalProductoDetalle = new Modal();
        this.validationInputs = new validationInput();
        this.utilidades = new Generales();
        this.idProducto = Number(this._route.snapshot.paramMap.get('id'));
    }

    private alertOut($ele, timer = 3000) {
        setTimeout(function () {
            $ele.setActive(false);
        }, timer);
    }

    ngOnInit() {

        this.formProducto = new FormGroup({
            code_product: new FormControl(''),
            sku_product: new FormControl(''),
            name_product: new FormControl('', Validators.required),
            date_purchase: new FormControl(new Date(), Validators.required),
            quantity_product: new FormControl(0, Validators.required),
            price_purchase: new FormControl(0, [Validators.required]),
            price_regular: new FormControl(0, [Validators.required]),
            stock_min: new FormControl(0),
            image: new FormControl(null),
            subcategory_id: new FormControl('0', [Validators.required, Validators.pattern('^[1-9][0-9]*$')]),
            discount_product: new FormControl(0),
            indefinite_amount: new FormControl(false),
            sold_out_product: new FormControl(false),
            online_product: new FormControl(false),
            measurement_id: new FormControl('0', [Validators.required, Validators.pattern('^[1-9][0-9]*$')]),
            brand_id: new FormControl('0', [Validators.required, Validators.pattern('^[1-9][0-9]*$')]),
            stock_max: new FormControl(0, Validators.required),
            providers: new FormControl(''),
            image_old: new FormControl('')
        });
        this.formProducto.controls['sku_product'].enable();

        this.imagenPreview = 'assets/img/image-not-found.png';

        this.loadData();

        try {
            this.dataConfig = JSON.parse(localStorage.getItem('config'));
            if (!this.dataConfig) {
                this._configuracionService.getConfiguration().subscribe(
                    response => {
                        this.dataConfig = JSON.stringify(response.data);
                        localStorage.setItem('config', this.dataConfig);
                    },
                    err => {
                        this.alertDetProducto.setActive(true, err.error.code);
                        this.alertOut(this.alertDetProducto);
                    }
                );
            }
        } catch (err) {
            this.alertDetProducto.setActive(true, 'danger');
            this.alertOut(this.alertDetProducto);
        }
    }

    loadData() {
        try {
            this.loadingDet.setLoading(true);
            if (this.idProducto && this.idProducto > 0) {
                this.loadDataEditProduct();
            } else {
                this.loadDataNewProduct();
            }
        } catch (err) {
            this.alertDetProducto.setActive(true, 'danger');
            this.alertOut(this.alertDetProducto);
        }
    }

    loadDataNewProduct() {
        zip(
            this._subcategoriaService.getAll(),
            this._unidadService.getAll(),
            this._marcaService.getAll(),
            this._proveedoresService.getAll()
        ).subscribe(([subcategorias, unidades, marcas, proveedores]) => {
            this.loadSubCategorias(subcategorias.data);
            this.loadUnidades(unidades.data);
            this.loadMarcas(marcas.data);
            this.loadProveedores(proveedores.data);
            this.loadingDet.setLoading(false);
        });
    }

    loadDataEditProduct() {
        let fields = 'date_purchase,';
        fields += 'price_regular,';
        fields += 'price_purchase,';
        fields += 'stock_min,';
        fields += 'stock_max,';
        fields += 'codebar_product,';
        fields += 'code_product,';
        fields += 'subcategory_id,';
        fields += 'measurement_id,';
        fields += 'brand_id,';
        fields += 'indefinite_amount,';
        fields += 'online_product,';
        fields += 'ids_providers';
        zip(
            this._subcategoriaService.getAll(),
            this._unidadService.getAll(),
            this._marcaService.getAll(),
            this._productoService.show(this.idProducto, 'fields=' + fields),
            this._proveedoresService.getAll()
        ).subscribe(([subcategorias, unidades, marcas, productos, proveedores]) => {
            this.loadSubCategorias(subcategorias.data);
            this.loadUnidades(unidades.data);
            this.loadMarcas(marcas.data);
            this.loadProducto(productos.data[0]);
            this.loadProveedores(proveedores.data);
            this.loadingDet.setLoading(false);
        });
    }

    loadProducto(data) {
        this.formProducto.get('sku_product').setValue(data.sku_product);
        this.formProducto.get('name_product').setValue(data.name_product);
        this.formProducto.get('date_purchase').setValue(this.formatcurrencypipe.parseStrintToDate(data.date_purchase));
        this.formProducto.get('quantity_product').setValue(data.quantity_product);
        this.formProducto.get('price_purchase').setValue(data.price_purchase);
        this.formProducto.get('price_regular').setValue(data.price_regular);
        this.formProducto.get('stock_min').setValue(data.stock_min);
        this.formProducto.get('image').setValue(data.image_product);
        this.imagen = '';
        this.codeBar = data.codebar_product;
        this.code = data.code_product;
        this.imagenPreview = data.image_product;
        const indefinite_amount = parseInt(data.indefinite_amount) !== 0 ? true : false;
        const sold_out_product = parseInt(data.sold_out_product) !== 0 ? true : false;
        const online_product = parseInt(data.online_product) !== 0 ? true : false;
        this.formProducto.get('image_old').setValue(data.name_image_product);
        this.formProducto.get('subcategory_id').setValue(data.subcategory_id);
        this.formProducto.get('discount_product').setValue(data.discount_product);
        this.formProducto.get('indefinite_amount').setValue(indefinite_amount);
        this.formProducto.get('sold_out_product').setValue(sold_out_product);
        this.formProducto.get('online_product').setValue(online_product);
        this.formProducto.get('measurement_id').setValue(data.measurement_id);
        this.formProducto.get('brand_id').setValue(data.brand_id);
        this.formProducto.get('stock_max').setValue(data.stock_max);
        this.formProducto.controls['sku_product'].disable();

        data.ids_providers.forEach(element => {
            this.proveedoresSelect.push(element);
        });

        this.formProducto.get('providers').setValue(JSON.stringify(this.proveedoresSelect));

        if (data.indefinite_amount === 1) {
            this.isReadOnly = true;
        }
    }

    loadSubCategorias(data) {
        this.subcategorias = data;
    }

    loadUnidades(data) {
        this.unidades = data;
    }

    loadMarcas(data) {
        this.marcas = data;
    }

    loadProveedores(data) {
        this.proveedores = data;
    }

    onChangeCantidadIndefinida() {
        const isChecked = this.formProducto.get('indefinite_amount').value;
        this.formProducto.get('quantity_product').setValue(0);
        if (isChecked) {
            this.isReadOnly = true;
        } else {
            this.isReadOnly = false;
        }
    }

    uploadImage(event) {
        if (event.target.files.length > 0) {
            const files = event.target.files;
            const file = files[0];
            const sizeMaxUpload = this.dataConfig.upload_max_filesize / 1024;

            if (files && file) {
                if (file.size > 0) {
                    let size = file.size / 1024 / 1024;
                    size = Math.ceil(size);
                    if (size > sizeMaxUpload) {
                        this.alertDetProducto.setActive(true, 'danger', 'La imagen no puede ser mayor de ' + sizeMaxUpload + 'MB.');
                        this.alertOut(this.alertDetProducto);
                        this.inputFile.nativeElement.value = '';
                    } else {
                        this.imagen = event.target.files[0];
                        const reader = new FileReader();
                        reader.onload = this._handleReaderLoaded.bind(this);
                        reader.readAsBinaryString(file);
                    }
                }
            }
        }
    }

    _handleReaderLoaded(readerEvt) {
        const binaryString = readerEvt.target.result;
        this.imagenPreview = 'data:image/jpeg;base64,' + btoa(binaryString);
    }

    onSave() {
        try {
            if (!this.idProducto) {
                this.loadingDet.setLoading(true);
                const formValue = this.formProducto.value;
                formValue['date_purchase'] = this.formatcurrencypipe.transformaDateForSave(formValue['date_purchase']);
                this._productoService.add(formValue, this.imagen).subscribe(
                    response => {
                        this.codeBar = response.data[0].codebar_product;
                        this.code = response.data[0].code_product;
                        this.formProducto.get('sku_product').setValue(response.data[0].sku_product);
                        this.formProducto.controls['sku_product'].disable();
                        this.alertDetProducto.setActive(true, 'success');
                        this.alertOut(this.alertDetProducto);
                        this.idProducto = response.data.id_producto;
                        this.loadingDet.setLoading(false);
                    },
                    err => {
                        this.loadingDet.setLoading(false);
                        let faild_validation = [];
                        if (typeof err.error.faild_validation !== 'undefined') {
                            faild_validation = err.error.faild_validation;
                        }

                        this.alertDetProducto.setActive(true, err.error.code, '', faild_validation);
                        this.alertOut(this.alertDetProducto);
                    }
                );
            } else {
                this.loadingDet.setLoading(true);
                const imageOld = this.formProducto.get('image_old').value;
                const imageNew = this.imagen;
                const formValue = this.formProducto.value;
                formValue['date_purchase'] = this.formatcurrencypipe.transformaDateForSave(formValue['date_purchase']);
                zip(
                    this._productoService.update(this.idProducto, this.formProducto.value),
                    this._productoService.imagenDelete(this.idProducto, imageOld, imageNew)
                ).subscribe(([product, imagen]) => {
                        this.loadingDet.setLoading(false);
                        this.alertDetProducto.setActive(true, 'update');
                        this.alertOut(this.alertDetProducto);
                        this.imagen = '';
                        this.inputFile.nativeElement.value = '';
                        this.formProducto.get('image_old').setValue(imagen.image);
                    },
                    err => {
                        this.loadingDet.setLoading(false);
                        let faild_validation = [];
                        if (typeof err.error.faild_validation !== 'undefined') {
                            faild_validation = err.error.faild_validation;
                        }
                        this.alertDetProducto.setActive(true, err.error.code, '', faild_validation);
                        this.alertOut(this.alertDetProducto);
                    });
            }
        } catch (err) {
            this.loadingDet.setLoading(false);
            this.alertDetProducto.setActive(true, 'danger');
            this.alertOut(this.alertDetProducto);
        }
    }

    selectProvider(event) {
        const isChecked = event.target.checked;
        const value = event.target.value;
        if (isChecked) {
            this.proveedoresSelect.push(value);
        } else {
            const index = this.proveedoresSelect.findIndex(obj => obj === value);
            this.proveedoresSelect = [
                ...this.proveedoresSelect.slice(0, index),
                ...this.proveedoresSelect.slice(index + 1)
            ];
        }
        this.formProducto.get('providers').setValue(JSON.stringify(this.proveedoresSelect));
    }

    isChecked(id) {
        if (this.proveedoresSelect.indexOf(id) !== -1) {
            return true;
        } else {
            return false;
        }
    }

    onSearchProvider(event) {
        try {
            const $this = this;
            clearTimeout($this.timeout);
            $this.timeout = setTimeout(function () {
                $this._productoProveedoresServices.search(event.target.value, $this.idProducto).subscribe(
                    response => {
                        $this.proveedores = response.data[0].proveedores;
                    },
                    err => {
                        $this.alertDetProducto.setActive(true, err.error.code);
                        $this.alertOut($this.alertDetProducto);
                    }
                );
            });
        } catch (err) {
            this.alertDetProducto.setActive(true, 'danger');
            this.alertOut(this.alertDetProducto);
        }
    }

    errorHandlerCodeBar(event) {
        this.codeBar = '';
    }

}
