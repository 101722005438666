/*{
    "valid": [{
        "email": {
            "pattern": {               
                "message": "El email no es válido" 
            }
        }
    }]
}
*/
export class validationInput {
    constructor(
        public email: any = {
            "pattern": /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i,            
            "minLength": 5,
            "maxLength": 150
        },
        public telefono: any = {
            "pattern": /^\d+$/
        },
        public celular: any = {

        },
        public coordenadas: any = {
            "pattern": /^(\-?\d+(\.\d+)?),\s*(\-?\d+(\.\d+)?)$/
        },
        public sitioWeb: any = {
            "pattern": /^(ht|f)tp(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(:(0-9)*)*(\/?)( [a-zA-Z0-9\-\.\?\,\'\/\\\+&%\$#_]*)?$/
        },
        public soloText: any = {

        },
        public soloNumero: any = {
            "pattern": /^[0-9][0-9]*$/
        },
        public soloPrecio: any = {
            "pattern": /(?!(0))[0-9.]+(,[0-9]{2})?$/
        },
        public mayorCero: any = {
            "pattern": /^[1-9][0-9]*$/
        },
    ) { }
}