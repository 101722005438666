import { ModuleWithProviders} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AlertasComponent } from './components/alertas/alertas.component';
import { CajaComponent } from './components/caja/caja.component';
import { CategoriasComponent } from './components/categorias/categorias.component';
import { ClientesComponent } from './components/clientes/clientes.component';
import { DevolucionesComponent } from './components/devoluciones/devoluciones.component';
import { EstadisticasComponent } from './components/estadisticas/estadisticas.component';
import { ExportarComponent } from './components/exportar/exportar.component';
import { ProductosComponent } from './components/productos/productos.component';
import { AgregarProductoComponent } from './components/productos/agregar/agregar.component';
import { EditarProductoComponent } from './components/productos/editar/editar.component';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { VentasComponent } from './components/ventas/ventas.component';
import { DepartamentosComponent } from './components/departamentos/departamentos.component';
import { SubcategoriasComponent } from './components/subcategorias/subcategorias.component';
import { MarcasComponent } from './components/marcas/marcas.component';
import { ProveedoresComponent } from './components/proveedores/proveedores.component';
import { EgresosComponent } from './components/egresos/egresos.component';
import { AuthGuard } from './components/auth/auth.guard';
import { LoginComponent } from './components/login/login.component';
import { ConfiguracionesComponent }  from './components/configuraciones/configuraciones.component';
import { ConfiguracionCreditoComponent } from './components/configuraciones/configuracion-credito/configuracion-credito.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout.component';
import { LoginLayoutComponent } from './layouts/login-layout.component';

const appRoutes: Routes = [	
	{
		path: '',
		component: DashboardLayoutComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: '',
				redirectTo: 'caja', 
				pathMatch: 'prefix'
				//component: CajaComponent
			},
			{ path: 'caja', component: CajaComponent },
			{ path: 'categorias', component: CategoriasComponent },
			{ path: 'clientes', component: ClientesComponent },
			{ path: 'devoluciones', component: DevolucionesComponent },
			{ path: 'estadisticas', component: EstadisticasComponent },
			{ path: 'exportar', component: ExportarComponent },
			{ path: 'departamentos', component: DepartamentosComponent },
			{ path: 'productos/agregar', component: AgregarProductoComponent },
			{ path: 'productos/editar/:id', component: EditarProductoComponent },
			{
				path: 'productos', component: ProductosComponent,
				//children: [				
					//{ path: '', redirectTo: 'agregar-productos', pathMatch: 'prefix' },
					//{ path: 'agregar-productos', component: AgregarProductosComponent },
					//{ path: 'ver-productos', component: VerProductosComponent },
				//]
			},
			{ path: 'usuarios', component: UsuariosComponent },
			{ path: 'ventas', component: VentasComponent },
			{ path: 'subcategorias', component: SubcategoriasComponent },	
			{ path: 'marcas', component: MarcasComponent },
			{ path: 'proveedores', component: ProveedoresComponent },
			{ path: 'egresos', component: EgresosComponent },
			{ path: 'alertas', component: AlertasComponent },		
			{ 
				path: 'configuraciones', component: ConfiguracionesComponent,
				children: [
					{ path: '', redirectTo: 'credito', pathMatch: 'prefix' },
					{ path: 'credito', component: ConfiguracionCreditoComponent}	
				]
			}
		]
	},
	{
		path: '',
		component: LoginLayoutComponent,
		children: [
			{
				path: 'login',
				component: LoginComponent
			}
		]
	},
	{ path: '**', redirectTo: '' }
];

export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);