import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class ProveedorescontactoServices {
    public url: string;
    public sessionuser = JSON.parse(localStorage.getItem("sessionuser"));
    public env = environment;
    constructor(
        public _http: HttpClient
    ) {
        this.url = this.env.dominio + "api/providercontacts";
    }

    add(id, values): Observable<any> {
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;
            let param = values;
            param.id_proveedor = id;
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', access_token);
            return this._http.post(this.url, param, { headers: headers });
        }
    }

    getByPage(page, limit, search = '', order = '', paramExtras = ''): Observable<any> {
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', access_token);

            let param = '?from=' + page;
            param += '&to=' + limit;
            if (search != '') param += '&search=' + search;
            if (order != '') param += '&order=' + order;
            if (paramExtras != '') param += '&' + paramExtras;

            return this._http.get(this.url + param, { headers: headers });
        }
    }

    delete(id): Observable<any> {
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', access_token);
            return this._http.delete(this.url + '/' + id, { headers: headers });
        }
    }

    search(s, order = 'asc', action = ''): Observable<any> {
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;

            let param = '?search=' + s;
            param += '&order=' + order;
            if (action != '') param += '&action=' + action;

            let headers = new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', access_token);
            return this._http.get(this.url + param, { headers: headers });
        }
    }

    update(id, proveedor): Observable<any> {
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;
            let param = {
                'apellidos_contacto': proveedor.apellidos_contacto,
                'celular_contacto': proveedor.celular_contacto,                
                'direccion_contacto': proveedor.direccion_contacto,
                'email_contacto': proveedor.email_contacto,
                'nombres_contacto': proveedor.nombres_contacto,
                'id_documento_contacto': proveedor.id_documento_contacto,
                'numero_documento_contacto': proveedor.numero_documento_contacto,
                'telefono_contacto': proveedor.telefono_contacto
            };
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', access_token);
            return this._http.put(this.url + '/' + id, param, { headers: headers });
        }
    }

    getProveedorContacto(s): Observable<any> {
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;

            let param = '?search=' + s;
            param += '&action=editar';

            let headers = new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', access_token);
            return this._http.get(this.url + param, { headers: headers });
        }
    }

    export(type, s = '', order = ''): Observable<any> {
        if (this.sessionuser) {
            let access_token = 'Bearer ' + this.sessionuser.access_token;
            let param = {
                'type': type,
                'search': s,
                'order': order
            };
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', access_token);
            return this._http.post(this.url + '/export', param, { headers: headers });
        }
    }

}