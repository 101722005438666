import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

//UTILIDADES
import { Alerta } from '../../utilidades/alerta.component';
import { Loading } from '../../utilidades/loading.component';
import { Modal } from '../../utilidades/modal.component';
import { validationInput } from '../../utilidades/validation.models';
import { Generales } from '../../utilidades/generales.component';


//MODELS
import { SubcategoriasModel } from '../../../models/subcategorias.model';

//SERVICES
import { SubcategoriasServices } from '../../../services/subcategorias.services';
import { CategoriasServices } from '../../../services/categorias.services';


@Component({
	selector: 'app-subcategoriasdetalle',
	templateUrl: './subcategoriasdetalle.component.html',
	styleUrls: ['./subcategoriasdetalle.component.scss'],
	providers: [
		SubcategoriasServices, CategoriasServices
	]
})
export class SubcategoriasdetalleComponent implements OnInit {
	//MODELS
	public subcategoria: SubcategoriasModel;

	//VARIABLES	
	public alertDetSubcategoria: Alerta;
	public loadingDet: any;
	public modalSubcategoriaDetalle: any = '';
	public idSubcategoria: number = -1; // -1 Significa que no es un usuario nuevo ni en modo edicion
	public modalChildVisible = false;
	public validationInputs: any = '';
	public utilidades: Generales;
	public categorias: any = '';

	//FORM
	public formSubcategorias: FormGroup;

	@Output() upDateGridSubcategorias = new EventEmitter();

	constructor(
		private _subcategoriaService: SubcategoriasServices,
		private _categoriaService: CategoriasServices
	) {
		this.alertDetSubcategoria = new Alerta('alert_det_subcategoria');
		this.loadingDet = new Loading();
		this.modalSubcategoriaDetalle = new Modal();
		this.validationInputs = new validationInput();

		this.formSubcategorias = new FormGroup({
			id_categoria: new FormControl({ value: '' }, Validators.required),
			nombre_subcategoria: new FormControl({ value: '' }, Validators.required)
		});
		this.utilidades = new Generales();
	}

	private alertOut($ele, timer = 2000) {
		setTimeout(function () {
			$ele.setActive(false);
		}, timer);
	}

	ngOnInit() {
	}

	openModalDetalleSubcategorias(id) {
		this.idSubcategoria = id;
		this.modalSubcategoriaDetalle.openModal();
		this.modalChildVisible = false;
		this.resetModels();
		this.loadCategorias();
		if (id > 0) { //EDITAR SUBCATEGORIA
			this.loadingDet.setLoading(true);
			this.onEdit();			
		}
	}

	resetModels() {
		this.subcategoria = new SubcategoriasModel(null, null, '');
	}

	onAdd() {
		this.resetModels();
		this.idSubcategoria = 0;
	}

	loadCategorias() {
		try {
			this._categoriaService.getAll().subscribe(
                response => {
                    this.categorias = response.data;
                },
                err => {     
					this.alertDetSubcategoria.setActive(true, err.error.code);
					this.alertOut(this.alertDetSubcategoria);                    
                }
			);
		} catch (err) {
			this.alertDetSubcategoria.setActive(true, 'danger');
			this.alertOut(this.alertDetSubcategoria);
		}
	}

	onEdit() {
		try {
			this._subcategoriaService.getSubcategoria(this.idSubcategoria).subscribe(
				response => {
					var data = response.data[0];					
					this.subcategoria = new SubcategoriasModel(data.id_subcategoria, data.id_categoria, data.nombre_subcategoria);
					this.loadingDet.setLoading(false);
				},
				err => {
					this.alertDetSubcategoria.setActive(true, err.error.code);
					this.alertOut(this.alertDetSubcategoria);
				}
			);
		} catch (err) {
			this.alertDetSubcategoria.setActive(true, 'danger');
			this.alertOut(this.alertDetSubcategoria);
		}
	}

	onSave() {
		try {
			if (!this.idSubcategoria) { //NUEVO
				this._subcategoriaService.add(this.formSubcategorias.value).subscribe(
					response => {
						this.alertDetSubcategoria.setActive(true, 'success');
						this.alertOut(this.alertDetSubcategoria);
						this.idSubcategoria = response.data.id_subcategoria;
						this.upDateGridSubcategorias.emit({ 'id_subcategoria': this.idSubcategoria });
					},
					err => {
						this.alertDetSubcategoria.setActive(true, err.error.code);
						this.alertOut(this.alertDetSubcategoria);
					}
				);
			} else {
				this._subcategoriaService.update(this.idSubcategoria, this.subcategoria).subscribe(
					response => {
						this.alertDetSubcategoria.setActive(true, 'update');
						this.alertOut(this.alertDetSubcategoria);
						var cel_1 = document.getElementsByClassName("subcategoria_" + this.idSubcategoria).item(0).querySelector('.cel_1');					
						if (cel_1) cel_1.innerHTML = this.utilidades.limitarStrings(response.data.nombre_subcategoria);						
					},
					err => {
						this.alertDetSubcategoria.setActive(true, err.error.code);
						this.alertOut(this.alertDetSubcategoria);
					}
				);
			}
		} catch (err) {
			this.alertDetSubcategoria.setActive(true, 'danger');
			this.alertOut(this.alertDetSubcategoria);
		}
	}

	openEditSubcategoriaDetalle() {
		this.modalSubcategoriaDetalle.openModal();
	}

	showModal(event): void {
		this.modalChildVisible = event.modal;
	}

}
